import React, {useCallback, useContext, useEffect, useState} from "react";
import { useCompanyStatus2 } from "../../../core/auth";
import { api } from "../../../api";
import { useAsyncAction } from "../../../api/Async";
import { newMonitorEventName, newMonitorEvents } from "../../AddMonitor";
import Button from "@mui/material/Button";
import {Checkbox, Switch, TextField, Typography, FormControlLabel} from "@mui/material";
import Grid from "@mui/material/Grid";
import { BasicDetails } from "../../monitorList/editMonitor/BasicDetails";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { validateURL } from "../../monitorList/inlineAddMonitor/AddHttpMonitor";
import Chip from "@mui/material/Chip";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import Tooltip from "@mui/material/Tooltip";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import DnsIcon from "@mui/icons-material/Dns";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LockIcon from "@mui/icons-material/Lock";
import HttpIcon from "@mui/icons-material/Http";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import { CheckIntervalSlider } from "./CheckIntervalSlider";
import { autoFixURL } from "../../setup/HttpSetup";
import {grey, red} from "@mui/material/colors";
import Alert from '@mui/material/Alert';
import {FreeTrialModalContext} from "../freeTrial/FreeTrialModal";
import {Note} from "../../Note";
import { OAuthConfig } from "./OAuthConfig";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      paddingTop: theme.spacing(1),
    },
    chipWrapper: {
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
      maxWidth: 475,
    },
  })
);

export function AddMonitorGRPC(props: { onDone(): void }) {
  const [url, setUrl] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [pollRateMs, setPollRateMs] = useState<number>(0);
  const status = useCompanyStatus2();
  const styles = useStyles();
  const [tlsEnabled, setTlsEnabled] = useState(false);
  const [tlsInsecure, setTlsInsecure] = useState(false);
  const [oauth, setOAuth] = useState<any>(null);

  const performanceMs = 30 * 1000;
  useEffect(() => {
    setPollRateMs((status?.maxPollRateInSeconds || 30) * 1000);
  }, [status]);

  const submit = useAsyncAction(async () => {
    const newMonitor = await api.addSource({
      kind: "grpc",
      url: url,
      grpcParams: {
        tlsEnabled: tlsEnabled,
        tlsInsecure: tlsInsecure,
        serviceName: serviceName,
        oAuth: oauth,
      },
      notifyResponseTimeGreaterThanMs: performanceMs,
      checkIntervalMs: pollRateMs,
    });

    newMonitorEvents.emit(newMonitorEventName, newMonitor);
    props.onDone();
    setUrl("");
    setServiceName("");
    setTlsEnabled(true);
    setTlsInsecure(false);
    setOAuth(null);
  }, [url, serviceName, tlsEnabled, performanceMs, pollRateMs, tlsInsecure, oauth]);

  const valid = validateURL(url);
  const formSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!valid) {
        console.warn("form not valid");
        return;
      }

      submit.callback(null);
    },
    [submit, valid]
  );

  const freeTrialCtx = useContext(FreeTrialModalContext);

  if (submit.loading) {
    return submit.LoadingElement;
  }

  if(!status?.grpcSupport) {
    return (
        <Alert severity="error" style={{maxWidth: 500}}>
          <div>Uh oh! Your plan doesn't support GRPC. Please upgrade to a paid plan to add a GRPC monitor</div>
          <div style={{height: 8}} />
          <Button variant="outlined" onClick={freeTrialCtx.show}>
              Upgrade
          </Button>
        </Alert>
    )
  }

  return (
    <form onSubmit={formSubmit}>
      <Grid
        container
        direction="column"
        spacing={2}
        alignItems="flex-end"
        className={styles.wrapper}
      >
        <Grid item style={{ width: "100%" }}>
          <TextField label="Host:Port"
                    value={url}
                    onChange={e => setUrl(e.target.value)}
                     InputLabelProps={{shrink: true}} placeholder="grpc.example.com:8080" fullWidth />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <TextField label="Service Name (optional)"
                    value={serviceName}
                    placeholder="GRPC Service"
                    onChange={e => setServiceName(e.target.value)}
                    InputLabelProps={{shrink: true}} fullWidth />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <Grid container spacing={1}>
            {!oauth && <Grid item>
              <Button size="small" color="primary" variant="outlined" onClick={() => {
                setOAuth({});

                // trigger fake resize event to get the popover to reposition correctly
                // timeout is related to expected transition time
                setTimeout(() => window.dispatchEvent(new Event("resize")), 300);
              }}>Setup OAuth</Button>
            </Grid>}
            {!tlsEnabled && <Grid item>
              <Button size="small" color="primary" variant="outlined" onClick={() => setTlsEnabled(true)}>Setup TLS</Button>
            </Grid>}
          </Grid>
        </Grid>

        {tlsEnabled && <Grid item style={{width: "100%"}}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs>
              <FormControlLabel
                  control={
                    <Switch checked={tlsInsecure} onChange={(e, checked) => setTlsInsecure(checked)}/>
                  }
                  label="Allow self-signed TLS certificates"
              />
            </Grid>
            <Grid item>
              <Button size="small" color="secondary" variant="outlined" onClick={() => setTlsEnabled(false)}>Remove
                TLS</Button>
            </Grid>
          </Grid>
        </Grid>}

        {oauth && <Grid item style={{width: "100%", maxWidth: 500}}>
          <OAuthConfig value={oauth} onChange={setOAuth} />
        </Grid>}

        <Grid item style={{ maxWidth: 500, width: "100%" }}>
          <Alert severity="info">
            <Typography>
              We'll be calling the built-in grpc health check endpoint <span style={{
                padding: "2px 4px", borderRadius: 2,
                backgroundColor: grey["200"],
                color: red["900"]}}>/grpc.health.v1.Health/Check</span>
              You can read more about health checks at <a href="https://github.com/grpc/grpc/blob/master/doc/health-checking.md" target="_blank">github.com/grpc/.../health-checking.md</a>
            </Typography>
          </Alert>
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <CheckIntervalSlider
            pollRateMs={pollRateMs}
            onChange={setPollRateMs}
          />
        </Grid>
        <Grid item>
          <div className={styles.chipWrapper}>
            <Tooltip
              title={
                "Will notify for requests that take longer than " +
                performanceMs +
                "ms"
              }
            >
              <Chip
                variant="outlined"
                size="small"
                icon={<TrendingUpIcon />}
                label={performanceMs + "ms"}
              />
            </Tooltip>
            <Chip
              variant="outlined"
              size="small"
              icon={<DnsIcon />}
              label="DNS Tracking"
            />
            <Chip
              variant="outlined"
              size="small"
              icon={<LocalHospitalIcon />}
              label="Diagnostics setup"
            />
            <Chip
              variant="outlined"
              size="small"
              icon={<LockIcon />}
              label="SSL Verification"
            />
            <Chip
              variant="outlined"
              size="small"
              icon={<ReceiptIcon />}
              label="Transcript Recording"
            />
            <Tooltip title="All tests will include the User-Agent header 'statuslist.app Service uptime bot'">
              <Chip
                variant="outlined"
                size="small"
                icon={<FingerprintIcon />}
                label="statuslist.app Service uptime bot"
              />
            </Tooltip>
          </div>
        </Grid>
        <Grid item>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item></Grid>
            <Grid item>
              <Grid container spacing={1}>
                {submit.error && (
                  <Grid item>
                    <Typography style={{ maxWidth: "300px" }} color="error">
                      {submit.error}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submit.callback}
                  >
                    Add
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={props.onDone} color="primary">
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
