import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import React from "react";
import { successGreen, warningYellow } from "../colors";
import { grey } from "@mui/material/colors";
import { ColoredTooltip } from "../ColoredTooltip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TimingEvent } from "../../../api/Types";
import ordinal from "ordinal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ok: {
      color: successGreen,
    },
    warn: {
      color: warningYellow,
    },
    none: {
      color: grey["500"],
    },
    icon: {
      fontSize: 18,
    },
  })
);

export function ComparisonIcon(props: TimingEvent) {
  const styles = useStyles();

  if (props.comparisonPercentile > 80) {
    return (
      <ColoredTooltip
        status="ok"
        label={<TooltipContent {...props} suffix="Nicely done! 🏆" />}
      >
        <div className={styles.ok + " " + styles.icon} key={"ok"}>
          <CheckCircleIcon fontSize="inherit" color="inherit" />
        </div>
      </ColoredTooltip>
    );
  }

  if (props.comparisonPercentile > 40) {
    return (
      <ColoredTooltip
        status="none"
        label={
          <TooltipContent
            {...props}
            suffix={"Not bad, not great, just plain average."}
          />
        }
      >
        <div className={styles.none + " " + styles.icon} key={"none"}>
          <CheckCircleIcon fontSize="inherit" color="inherit" />
        </div>
      </ColoredTooltip>
    );
  }

  return (
    <ColoredTooltip
      status="none"
      label={
        <TooltipContent
          {...props}
          suffix={
            "That's not great. This might be something that can be improved"
          }
        />
      }
    >
      <div className={styles.warn + " " + styles.icon} key={"warn"}>
        <WarningIcon fontSize="inherit" color="inherit" />
      </div>
    </ColoredTooltip>
  );
}

function TooltipContent(props: TimingEvent & { suffix: string }) {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="body2">
          You're {props.name} duration is in the{" "}
          {ordinal(props.comparisonPercentile)}&nbsp; percentile when compared
          with other monitors on our platform.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">{props.suffix}</Typography>
      </Grid>
    </Grid>
  );
}
