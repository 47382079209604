import React, { useState } from "react";
import {
  MenuItem,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useAsyncAction } from "../../api/Async";
import { api } from "../../api";
import { useSnackbar } from "notistack";

export function SetPassword(props: { id: number; onCloseParentMenu(): void }) {
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const updater = useAsyncAction(async () => {
    await api.setPersonPassword({
      id: props.id,
      password: password,
    });

    setShow(false);
    props.onCloseParentMenu();
    enqueueSnackbar("Password updated");
  }, [props.id, password, enqueueSnackbar]);

  return (
    <React.Fragment>
      <MenuItem
        onClick={() => {
          setPassword("");
          setShow(true);
        }}
      >
        Set Password
      </MenuItem>
      <Dialog
        open={show}
        onClose={() => {
          setShow(false);
          props.onCloseParentMenu();
        }}
      >
        <DialogTitle>Set Password</DialogTitle>
        <DialogContent>
          <TextField
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          {updater.LoadingElement}
          <Button
            color="primary"
            disabled={updater.loading}
            onClick={() => {
              setShow(false);
              props.onCloseParentMenu();
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={updater.loading}
            onClick={() => updater.callback(null)}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
