import React from "react";
import { MonitorDNS } from "./debugView/MonitorDNS";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { MonitorTiming } from "./debugView/MonitorTiming";
import { Grid, Typography, Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import { MonitorHttpResponse } from "./debugView/MonitorHttpResponse";
import { MonitorLiveCheckDetails } from "./debugView/MonitorLiveCheckDetails";
import { MonitorExpandedContainer } from "./MonitorExpandedContainer";
import CircularProgress from "@mui/material/CircularProgress";
import { api } from "../../api";
import { useAsync } from "../../api/Async";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    separator: {
      borderLeft: "1px solid " + grey["200"],
      marginLeft: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    hSpacer: {
      height: theme.spacing(2),
    },
    loading: {
      textAlign: "center",
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  })
);

export function MonitorDebugView(props: { id: number; showDns(): void }) {
  const styles = useStyles();
  const result = useAsync(() => api.diagnosticCheck({ endpoint: props.id }));

  if (result.error && !result.loading) {
    return (
      <div className={styles.loading}>
        <Typography color="error">{result.error}</Typography>
        <Button onClick={result.reload}>Try again</Button>
      </div>
    );
  }

  const loadingWithoutResult = result.loading && !result.result;
  if (loadingWithoutResult || !result.result) {
    return (
      <div className={styles.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <MonitorExpandedContainer>
      <Grid container>
        <Grid item xs={3}>
          <MonitorDNS {...result.result} showDns={props.showDns} />
        </Grid>
        <Grid item className={styles.separator} />
        <Grid item xs>
          <MonitorTiming {...result.result} />
        </Grid>
      </Grid>

      <div className={styles.hSpacer} />
      <MonitorHttpResponse {...result.result} />
      <div className={styles.hSpacer} />
      <MonitorLiveCheckDetails
        onReload={result.reload}
        loading={result.loading}
      />
    </MonitorExpandedContainer>
  );
}
