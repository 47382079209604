import { Theme } from "@mui/material";
import {makeStyles, createStyles} from '@mui/styles';
import { grey } from "@mui/material/colors";
import React from "react";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 16,
      fontWeight: 500,
      color: grey["700"],
      marginBottom: 3,
    },
  })
);

export function Title(props: { children: string }) {
  const styles = useStyle();
  return <div className={styles.title}>{props.children}</div>;
}
