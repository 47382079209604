import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import {CardContent, Dialog, useTheme } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { grey } from "@mui/material/colors";
import {api} from "../../api";
import { useAsyncAction } from "../../api/Async";
import { useSnackbar } from "notistack";

export function Questions() {
    const theme = useTheme();
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("")
    const snack = useSnackbar();

    const action = useAsyncAction(async (input) => {
        await api.sendSupportMessage(input)
        setShow(false);
        snack.enqueueSnackbar({
            message: "Message sent!",
            variant: "success",
        })
    }, []);

    return (
        <div style={{position: "fixed", bottom: 24, right: 16, zIndex: 1400}}>
            <IconButton
                onClick={() => setShow(true)}
                style={{
                    color: "white",
                    backgroundColor: theme.palette.primary.dark,
                    borderRadius: "50%",
                }}
                size="large"
            >
                <ChatBubbleIcon color="inherit" />
            </IconButton>
            {show && <Dialog open>
                <CardHeader
                    title="Support"
                    subheader={<div style={{color: grey["900"]}}>
                        A real human will read and respond to the message you send us.
                        If you have any questions, feedback, or just want to chat, we're here for you.
                        We typically respond within a few hours.
                    </div>}
                    action={
                        <IconButton size="small" onClick={() => setShow(false)}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    <TextField fullWidth multiline label={"Message"} variant={"outlined"} minRows={4} value={message} onChange={e => setMessage(e.target.value)} />
                    <div style={{height: 8}} />

                    {action.LoadingElement}
                    <Button variant="contained" color="primary" onClick={async () => {
                        action.callback({
                            message: message,
                        })

                    }}>
                        Send
                    </Button>
                </CardContent>
            </Dialog>}
        </div>
    )
}