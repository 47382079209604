import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import green from "@mui/material/colors/green";
import { Person } from "../../api/Types";
import React, { useEffect, useState } from "react";
import { useAsyncAction } from "../../api/Async";
import { api } from "../../api";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import { Grid, Tooltip } from "@mui/material";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useSaved } from "../Saved";
import red from "@mui/material/colors/red";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { TeamMemberSubMenu } from "./TeamMemberSubMenu";
import { useSnackbar } from "notistack";
import {TeamHook} from "./TeamEditor";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconInActive: {
      fontSize: 18,
      paddingTop: 5,
      color: red["500"],
    },
    iconOk: {
      fontSize: 18,
      paddingTop: 5,
      color: green["500"],
      opacity: 0.5,
    },
  })
);

export function TeamRow(props: {
  person: Person;
  isAdd?: boolean;
  reloadList(): void;
  canRemove: boolean;
  teamHook?: TeamHook;
  bigAddButton?: boolean;
}) {
  const styles = useStyles();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const saved = useSaved({ timeout: 5000 });
  const triggerSaved = saved.trigger;
  const person = props.person;

  const reloadList = props.reloadList;
  const addPerson = useAsyncAction(
    async (input: { firstName: string; lastName: string; email: string, phone: string; }) => {
      if(input.email && input.email.indexOf('@') === -1) throw new Error("Invalid email address");
      await api.addPerson(input);
      reloadList();
    },
    [reloadList]
  );

  if(props.teamHook) {
    props.teamHook.onSubmit = () => {
      if (!email) return Promise.resolve();

      return addPerson.callback({
        firstName,
        lastName,
        email,
        phone,
      });
    }
  }

  useEffect(() => {
    setEmail(person.email);
    setLastName(person.lastName);
    setFirstName(person.firstName);
    setPhone(person.phone);
  }, [person]);

  const { enqueueSnackbar } = useSnackbar();

  const personId = props.person.id;
  const setupEmail = useAsyncAction(async () => {
    await api.sendSetupEmail({ id: personId });
    enqueueSnackbar("Email sent");
  }, [personId, enqueueSnackbar]);

  const saver = useAsyncAction(async () => {
    if (props.isAdd) return;
    if (
      props.person.email === email &&
      props.person.phone === phone &&
      props.person.firstName === firstName &&
      props.person.lastName === lastName
    )
      return;

    await api.updatePerson({
      id: props.person.id,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      archived: false,
    });

    reloadList();
    triggerSaved();
  }, [props.isAdd, firstName, lastName, email, phone, props.person, triggerSaved]);

  const archiving = useAsyncAction(async () => {
    if (props.isAdd) return;

    await api.updatePerson(
      Object.assign(props.person, {
        archived: true,
      })
    );

    reloadList();
  }, [props.isAdd, props.person, reloadList]);

  const alertOk = person.email ? person.emailVerified : !!person.phone;
  const formError = (email && email.indexOf("@") === -1) || (!phone && !email) || (phone && !phone.replace(/[^0-9]+/g, "").match(/^[0-9]{10,11}$/));

  return (
    <TableRow>
      <TableCell padding="checkbox" style={{ paddingLeft: 15 }}>
        <TextField
          style={{ minWidth: 100 }}
          fullWidth
          onBlur={saver.callback}
          placeholder={props.isAdd ? "Start typing to add..." : "(blank)"}
          variant="standard"
          InputProps={{ disableUnderline: true }}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </TableCell>
      <TableCell padding="checkbox">
        <TextField
          style={{ minWidth: "auto" }}
          fullWidth
          onBlur={saver.callback}
          placeholder="(blank)"
          variant="standard"
          InputProps={{ disableUnderline: true }}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </TableCell>
      <TableCell padding="checkbox">
        <TextField
          style={{ minWidth: 170 }}
          fullWidth
          onBlur={saver.callback}
          placeholder="name@work.com"
          variant="standard"
          InputProps={{ disableUnderline: true }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </TableCell>
      <TableCell padding="checkbox">
        <TextField
          style={{ minWidth: 170 }}
          fullWidth
          onBlur={saver.callback}
          placeholder="+1-222-222-2222"
          variant="standard"
          InputProps={{ disableUnderline: true }}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </TableCell>
      <TableCell padding="checkbox">
        {!props.isAdd && (
          <Grid
            container
            justifyContent="flex-end"
            spacing={1}
            wrap="nowrap"
            alignItems="center"
          >
            <Grid item>
              <Tooltip
                title={
                  alertOk
                    ? "Alerting good to go"
                    : "This person's email isn't verified, so we can't send them alerts. Check your inbox or spam folder for a welcome email. Otherwise you can re-send it from the menu"
                }
              >
                <div
                  className={
                    alertOk ? styles.iconOk : styles.iconInActive
                  }
                >
                  {alertOk ? (
                    <NotificationsIcon color="inherit" fontSize="inherit" />
                  ) : (
                    <NotificationsOffIcon color="inherit" fontSize="inherit" />
                  )}
                </div>
              </Tooltip>
            </Grid>
            {!!person.email && <Grid item>
              <Tooltip
                title={
                  person.passwordIsSetup
                    ? "Password all set!"
                    : "This person doesn't have a password setup"
                }
              >
                <div
                  className={
                    person.passwordIsSetup ? styles.iconOk : styles.iconInActive
                  }
                >
                  <VpnKeyIcon color="inherit" fontSize="inherit" />
                </div>
              </Tooltip>
            </Grid>}
          </Grid>
        )}
      </TableCell>
      <TableCell padding="checkbox" style={{ paddingRight: 5 }}>
        <Grid container justifyContent="flex-end" alignItems="center">
          {saved.show && <Grid item>Saved</Grid>}
          {archiving.LoadingElement && (
            <Grid item>{archiving.LoadingElement}</Grid>
          )}
          {saver.LoadingElement && <Grid item>{saver.LoadingElement}</Grid>}
          {addPerson.LoadingElement && (
            <Grid item>{addPerson.LoadingElement}</Grid>
          )}
          {setupEmail.LoadingElement && (
            <Grid item>{setupEmail.LoadingElement}</Grid>
          )}
          <Grid item>
            {props.isAdd ? props.bigAddButton ? (
                <Button
                    disabled={!!formError}
                    size="small"
                    onClick={() =>
                        addPerson.callback({
                          firstName: firstName,
                          lastName: lastName,
                          email: email,
                          phone: phone,
                        })
                    }
                    variant="contained"
                    color="primary"
                    startIcon={<AddCircleOutlineIcon />}
                >
                  Create
                </Button>
            ) : (
                <IconButton
                disabled={!!formError}
                size="small"
                onClick={() =>
                  addPerson.callback({
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phone,
                  })
                }
              >
                <AddCircleOutlineIcon />
              </IconButton>
            ) : (
              <TeamMemberSubMenu
                id={props.person.id}
                hasEmail={!!props.person.email}
                sendSetupEmail={() => setupEmail.callback(null)}
                remove={() => archiving.callback(null)}
                canRemove={props.canRemove}
              />
            )}
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
}
