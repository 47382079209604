import {Divider, Grid, ListItem, TextField} from "@mui/material";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import * as React from "react";
import {useState} from "react";
import Button from "@mui/material/Button";
import {api, fixPath} from "../../api";
import {useTheme} from "@mui/styles";

export function RegisterLifetimePage() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string|null>(null);
    const theme = useTheme()

    return (
        <Dialog open={true} maxWidth="sm" fullWidth>
            <Grid container spacing={2}>
                <Grid item xs={5} style={{display: "flex"}}>
                    <div style={{
                        padding: 16, display: "flex", flexDirection: 'column', flex: 1,
                        backgroundColor: theme.palette.primary.light,
                        color: "white",
                    }}>
                        <List>
                            <ListItem>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <img src="/logo192.png" style={{height: 40}}/>
                                    </Grid>
                                    <Grid item style={{fontSize: "1rem", fontWeight: "600"}}>
                                        <div>Status List</div>
                                        <div style={{fontSize: "0.7rem"}}>Uptime & Status Pages</div>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        </List>
                        <div style={{flex: 1}}/>
                        <div style={{paddingLeft: 16}}>
                            <Typography variant="body2" style={{}}>
                                How do you comfort a sad network? <br/><br/>
                                You give it a packet of data and promise an uptime story.
                            </Typography>
                        </div>
                        <div style={{flex: 1}}/>
                        <div style={{paddingLeft: 16}}>
                            <img src={"/stars.png"} alt={"stars"} style={{height: 18}}/>
                            <Typography variant="body2" style={{}}>
                                Join 1000+ companies who trust us to monitor their sites.
                            </Typography>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={7}>
                    <div style={{padding: 16}}>
                        <Typography variant="h5" style={{fontWeight: "600"}}>
                            Let's get Monitoring!
                        </Typography>
                        <div style={{height: 16}}/>
                        <form
                            onSubmit={async (event) => {
                                event.preventDefault();
                                setLoading(true);
                                setError(null);

                                try {
                                    await api.register({
                                        email: email,
                                        password: password,
                                    });

                                    // @ts-ignore
                                    window.location = fixPath("/api/subscription/lifetime");
                                } catch (e: any) {
                                    setError(e.toString())
                                } finally {
                                    setLoading(false);
                                }
                            }}
                        >

                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        label="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        variant="filled"
                                        name="username"
                                        type="email"
                                        required
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        label="Create a password"
                                        type="password"
                                        name="password"
                                        variant="filled"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                        required
                                    />
                                </Grid><Grid item>
                                <TextField
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    label="Confirm password"
                                    type="password"
                                    name="confirm-password"
                                    variant="filled"
                                    onChange={(e) => setPassword2(e.target.value)}
                                    value={password2}
                                    required
                                />
                            </Grid>
                                {error && (
                                    <Grid item>
                                        <Typography color="error">{error}</Typography>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={
                                                    loading ||
                                                    !email ||
                                                    !password ||
                                                    password !== password2
                                                }
                                            >
                                                Register
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Grid>
            </Grid>
        </Dialog>
    );
}