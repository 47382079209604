import React, { PropsWithChildren } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),

      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    borderBottom: {
      borderBottom: "1px solid " + grey["100"],
    },
  })
);

export function MonitorExpandedContainer(
  props: PropsWithChildren<{ noBorderBottom?: boolean }>
) {
  const styles = useStyles();
  return (
    <div
      className={
        props.noBorderBottom
          ? styles.container
          : styles.container + " " + styles.borderBottom
      }
    >
      {props.children}
    </div>
  );
}
