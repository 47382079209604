import { Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import React from "react";
import { useElementChangeEvent } from "./useElementChangeEvent";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {},
    url: {
      minWidth: 400,
    },
  })
);

const standardSelectStyle = {
  minWidth: "50px",
};
const filledSelectStyle = {
  minWidth: "100px",
};

export function BasicDetails(props: {
  method: string;
  url: string;
  filled?: boolean;
  onMethodChange(method: string): void;
  onUrlChange(url: string): void;
}) {
  const styles = useStyles();
  const methodChanged = useElementChangeEvent(props.onMethodChange);
  const urlChanged = useElementChangeEvent(props.onUrlChange);
  const variant = props.filled ? "filled" : "standard";
  return (
    <Grid container spacing={2}>
      <Grid item>
        {/*
          // @ts-ignore */}
        <TextField
          className={styles.select}
          select
          label="Method"
          value={props.method}
          onChange={methodChanged}
          variant={variant}
          style={props.filled ? filledSelectStyle : standardSelectStyle}
        >
          <MenuItem selected value="GET">
            GET
          </MenuItem>
          <MenuItem value="PUT">PUT</MenuItem>
          <MenuItem value="POST">POST</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs>
        {/*
          // @ts-ignore */}
        <TextField
          label="https://..."
          variant={variant}
          value={props.url}
          className={styles.url}
          onChange={urlChanged}
          type="text"
          fullWidth
        />
      </Grid>
    </Grid>
  );
}
