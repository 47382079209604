import { Grid, IconButton, Tooltip } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { Title } from "./Title";
import HistoryIcon from "@mui/icons-material/History";
import { CheckResult } from "../../../api/Types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      borderCollapse: "collapse",
      "& td": {
        verticalAlign: "top",
      },
      "& td:first-child": {
        paddingRight: theme.spacing(0.5),
      },
    },
    textOverflow: {
      whitespace: "normal",
    },
  })
);

export function MonitorDNS(props: CheckResult & { showDns(): void }) {
  const styles = useStyles();

  return (
    <Grid container direction="column" spacing={0}>
      <Grid item>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Title>DNS</Title>
          </Grid>
          <Grid item>
            <Tooltip title="View DNS History">
              <IconButton size="small" onClick={props.showDns}>
                <HistoryIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Typography variant="body2" color="textSecondary">
          {props.dnsDomain} resolved to
        </Typography>
        <table className={styles.table}>
          <tbody>
            <tr>
              <td>
                <Typography variant="body2" color="textSecondary">
                  IPv4:
                </Typography>
              </td>
              <td>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={styles.textOverflow}
                >
                  {(props.dnsIPv4ResolvesTo || []).join(", ") || "(none)"}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="body2" color="textSecondary">
                  IPv6:
                </Typography>
              </td>
              <td>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={styles.textOverflow}
                >
                  {(props.dnsIPv6ResolvesTo || [])
                    .join(", ")
                    .replace(/:/g, ":\u200B") || "(none)"}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
}
