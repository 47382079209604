import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { dangerRed, missingGrey, successGreen, warningYellow } from "./colors";
import { MonitorDetail } from "./index";
import { useTimeBasedRefresher } from "../TimeRefresher";
import { useCompanyStatus2 } from "../../core/auth";
import { CollapsedBodyHint } from "./CollapsedBodyHint";
import { MonitorControls } from "./MonitorControls";
import { ExpandMode } from "./Monitor";
import { CompanyStatus } from "../../api/Types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerPadding: {
      padding: theme.spacing(2),
    },
    grey: {
      color: missingGrey,
    },
    typeIcon: {
      fontSize: 28,
    },
    wrapper: {
      flexWrap: "nowrap",
      marginTop: -15,
    },
    textOverflow: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    rightText: {
      display: "block",
      textAlign: "right",
    },
    warningIcon: {
      color: warningYellow,
      fontSize: 28,
    },
    dangerIcon: {
      color: dangerRed,
      fontSize: 28,
    },
    cancelIcon: {
      color: missingGrey,
      fontSize: 28,
    },
    checkmarkIcon: {
      color: successGreen,
      fontSize: 28,
    },
    clickableLink: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    details: {
      paddingRight: 0,
    },
  })
);

export function MonitorCollapsedBody(
  props: MonitorDetail & {
    showDns(): void;
    showIncidents(): void;
    onExpand(mode: ExpandMode): void;
    onUpdate(detail: MonitorDetail): void;
    hasActiveIncident: boolean;
    dnsChanged: boolean;
    expiredTrial: boolean;
    forcePaused: boolean;
    throttled: boolean;
  }
) {
  const styles = useStyles();
  useTimeBasedRefresher();
  const status = useCompanyStatus2();

  if (status === null) return null;

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      className={styles.wrapper}
    >
      <Grid item>
        <CollapsedBodyHint
          {...props}
          showIncidents={props.showIncidents}
          showDns={props.showDns}
          requestedCheckIntervalMs={props.requestedCheckIntervalMs}
        />
      </Grid>
      <Grid item xs>
        <div className={styles.details}>
          <Typography
            className={styles.grey + " " + styles.rightText}
            variant="caption"
          >
            Last checked {moment(props.lastCheckedAt).fromNow()}
          </Typography>
        </div>
      </Grid>
      <Grid item>
        <MonitorControls
          {...props}
          onUpdate={props.onUpdate}
          expanded={false}
          onExpand={props.onExpand}
        />
      </Grid>
    </Grid>
  );
}

export function isForcePaused(
  monitor: { paused: boolean },
  status: CompanyStatus,
  activeMonitorCount: number
) {
  return monitor.paused && status.monitorCountLimit <= activeMonitorCount;
}
