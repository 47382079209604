import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface State {
  open: boolean;
  title: string;
  content: string;
}

var instance: ConfirmDialog;

export function Confirm(params: {
  title: string;
  content: string;
}): Promise<void> {
  return instance.show(params);
}

export class ConfirmDialog extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      open: false,
      title: "",
      content: "",
    };

    instance = this;
  }

  resolve: () => void = () => {};
  reject: () => void = () => {};

  show(props: { title: string; content: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;

      this.setState({
        open: true,
        title: props.title,
        content: props.content,
      });
    });
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            {this.state.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{this.state.content}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ open: false });
                this.reject();
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.setState({ open: false });
                this.resolve();
              }}
              color="primary"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
