import React, { useCallback, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { SlackIntegration } from "../slack/SlackIntegration";
import Dialog from "@mui/material/Dialog";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      height: 20,
      padding: 5,
        backgroundColor: "white",
        borderRadius: "50%",
    },
  })
);

export function Slack() {
  const style = useStyles();
  const [show, setShow] = useState(false);
  const onShow = useCallback(() => setShow(true), []);
  const onHide = useCallback(() => setShow(false), []);

  return (
    <React.Fragment>
      <IconButton size="small" onClick={onShow}>
        <img className={style.img} src="/slack.svg" alt="slack" />
      </IconButton>
      <Dialog open={show} onClose={onHide}>
        <SlackIntegration onClose={onHide} />
      </Dialog>
    </React.Fragment>
  );
}
