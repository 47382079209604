import * as React from "react";
import { Card, Theme } from "@mui/material";
import {makeStyles, createStyles} from '@mui/styles';
import CardContent from "@mui/material/CardContent";
import { api } from "../../api";
import { useAsync } from "../../api/Async";
import CircularProgress from "@mui/material/CircularProgress";
import { SlackDetails } from "./SlackDetails";
import { useCallback, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      minWidth: "400px",
    },
    spacer: {
      height: theme.spacing(1),
    },
    content: {
      paddingTop: 0,
    },
  })
);

export function SlackIntegration(props: { onClose(): void }) {
  const style = useStyle();
  const { loading, result, reload } = useAsync(() => api.getSlackStatus());
  const [didJustSetup, setDidJustSetup] = useState(false);

  const doReload = useCallback(
    (action: "setup" | "disable") => {
      if (action === "setup") setDidJustSetup(true);
      else setDidJustSetup(false);
      reload();
    },
    [reload, setDidJustSetup]
  );

  return (
    <Card className={style.card}>
      <CardHeader
        action={
          <IconButton size="small" onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        }
        title="Slack"
      />
      <CardContent className={style.content}>
        <div className={style.spacer} />
        {loading && <CircularProgress />}
        {result !== null && (
          <SlackDetails
            {...result}
            onReload={doReload}
            didJustSetup={didJustSetup}
          />
        )}
      </CardContent>
    </Card>
  );
}
