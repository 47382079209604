import React, { useState } from "react";
import { Dialog } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useAsyncAction } from "nate-react-api-helpers";
import { api } from "../api";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";

export function ResetPassword2(props: {}) {
  const [password, setPassword] = useState("");
  const params = useParams<{ token: string }>();
  const reset = useAsyncAction(
    async (input: { token: string; newPassword: string }) => {
      await api.resetPassword2(input);
      // @ts-ignore
      window.location = "/";
    },
    []
  );

  return (
    <Dialog open={true}>
        <form onSubmit={e => {
            e.preventDefault()

            reset.callback({
                token: params.token,
                newPassword: password,
            });
        }}>
      <div
        style={{
          backgroundColor: "#0366d6",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 15,
        }}
      >
        <img alt="logo" src="/logo-white.png" style={{ width: 30 }} />
      </div>
      <DialogTitle>Finish Password Reset</DialogTitle>
      <DialogContent>
          <div style={{height: 10}} />
        <TextField
          label="Choose a Password"
          InputLabelProps={{ shrink: true }}
          style={{ width: 300 }}
          fullWidth
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>{reset.LoadingElement}</Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
            >
              Complete & Login
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
        </form>
    </Dialog>
  );
}
