import React, { useState } from "react";
import { Dialog } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useAsyncAction } from "nate-react-api-helpers";
import { api } from "../api";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";

export function ResetPassword(props: {}) {
  const [password, setPassword] = useState("");
  const params = useParams<{ code: string }>();
  const reset = useAsyncAction(
    async (input: { code: string; password: string }) => {
      await api.resetPassword(input);
      // @ts-ignore
      window.location = "/";
    },
    []
  );

  return (
    <Dialog open={true}>
      <div
        style={{
          backgroundColor: "#0366d6",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 15,
        }}
      >
        <img alt="logo" src="/logo-white.png" style={{ width: 30 }} />
      </div>
      <DialogTitle>Finish Email Confirmation</DialogTitle>
        <DialogContent>
            <div style={{height: 10}}/>
            <TextField
                label="Set a Password"
                InputLabelProps={{shrink: true}}
                fullWidth
                type="password"
                onChange={(e) => setPassword(e.target.value)}
            />
        </DialogContent>
        <DialogActions>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>{reset.LoadingElement}</Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                reset.callback({
                  code: params.code,
                  password: password,
                });
              }}
            >
              Complete & Login
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
