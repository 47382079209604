import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Typography } from "@mui/material";
import * as React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff",
    },
    title: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.2),
    },
  })
);

export function Header(props: { title: string; cols: number }) {
  const styles = useStyles();
  return (
    <TableRow>
      <TableCell colSpan={props.cols} className={styles.header}>
        <Typography variant="body1" color="inherit" className={styles.title}>
          {props.title}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
