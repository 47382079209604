import React from "react";
import InputLabel from "@mui/material/InputLabel";
import { TranscriptDisplay } from "../../monitorList/TranscriptDisplay";
import { RedisParams } from "../../../api/Types";

export function AddRedisMonitor(props: {
  darkMode?: boolean;
  params: RedisParams;
}) {
  const { enablePing, enableQuery } = props.params;

  let transcript = "";
  if (enablePing) {
    transcript += "> PING\nPONG";
  }

  if (enableQuery) {
    if (transcript !== "") transcript += "\n\n";
    transcript += '> RANDOMKEY\n"my-test-key"';
  }

  return (
    <React.Fragment>
      <InputLabel shrink>Here's the query we'll run</InputLabel>
      <TranscriptDisplay darkMode={props.darkMode} content={transcript} />
    </React.Fragment>
  );
}
