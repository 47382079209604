import { Theme } from "@mui/material";
import {makeStyles, createStyles} from '@mui/styles';
import { grey } from "@mui/material/colors";
import React, { useMemo } from "react";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    transcript: {
      backgroundColor: grey["100"],
      color: grey["800"],
      borderRadius: 5,
      fontFamily: "'Roboto Mono', monospace",
      fontSize: 12,
      whiteSpace: "pre-wrap",
      padding: theme.spacing(1),
      maxHeight: 200,
      overflow: "auto",
      marginTop: 3,
    },
    darkMode: {
      backgroundColor: grey["300"],
    },
  })
);

export function TranscriptDisplay(props: {
  darkMode?: boolean;
  content: string;
  maxHeight?: number;
}) {
  const styles = useStyle();
  const style = useMemo(
    () => (props.maxHeight ? { maxHeight: props.maxHeight } : {}),
    [props]
  );
  return (
    <div
      style={style}
      className={
        props.darkMode
          ? styles.transcript + " " + styles.darkMode
          : styles.transcript
      }
    >
      {props.content}
    </div>
  );
}
