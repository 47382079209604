import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { RegisterInput } from "./RegisterInput";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { Grid, ListItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import { useTheme } from "@mui/styles";

interface Props {
  onShowLogin(): void;
}

export function Register(props: Props) {
    const theme = useTheme();
return (
  <Dialog open={true} maxWidth="sm" fullWidth>
    <Grid container spacing={1}>
        <Grid item xs={5} style={{display: "flex"}}>
            <div style={{
                padding: 16, display: "flex", flexDirection: 'column', flex: 1,
                backgroundColor: theme.palette.primary.light,
                color: "white",
            }}>
                <List>
                    <ListItem>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <img src="/logo192.png" style={{height: 40}}/>
                            </Grid>
                            <Grid item style={{fontSize: "1rem", fontWeight: "600"}}>
                                <div>Status List</div>
                                <div style={{fontSize: "0.7rem"}}>Uptime & Status Pages</div>
                            </Grid>
                        </Grid>
                    </ListItem>
                </List>
                <div style={{flex: 1}}/>
                <div style={{paddingLeft: 16}}>
                    <Typography variant="body2" style={{}}>
                        How do you comfort a sad network? <br/><br/>
                        You give it a packet of data and promise an uptime story.
                    </Typography>
                </div>
                <div style={{flex: 1}}/>
                <div style={{paddingLeft: 16}}>
                    <img src={"/stars.png"} alt={"stars"} style={{height: 18}}/>
                    <Typography variant="body2" style={{}}>
                        Join 1000+ companies who trust us to monitor their sites.
                    </Typography>
                </div>
            </div>
        </Grid>
        <Grid item xs={7}>
            <div style={{padding: 16}}>
                <Typography variant="h5" style={{fontWeight: "600"}}>
                    Let's get Monitoring!
                </Typography>
                <div style={{height: 16}}/>
                <RegisterInput onShowLogin={props.onShowLogin}/>
            </div>
        </Grid>
    </Grid>
  </Dialog>
);
}
