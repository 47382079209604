import { useCompanyStatus2 } from "../../../core/auth";
import React from "react";
import CardContent from "@mui/material/CardContent";
import DialogContentText from "@mui/material/DialogContentText";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { grey, red } from "@mui/material/colors";
import { successGreen } from "../../monitorList/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      border: "2px solid #F5A623",
      backgroundColor: "rgba(245,166,35,0.18)",
      borderRadius: 5,
    },
    button: {
      backgroundColor: red["900"],
      color: "#fff",

      "&:hover": {
        backgroundColor: red["900"],
      },
    },
    paymentSeparator: {
      height: theme.spacing(2),
    },
    noTopPadding: {
      paddingTop: 0,
    },
    paymentSubTitle: {
      fontWeight: "normal",
      marginBottom: 4,
    },
    greenCard: {
      backgroundColor: successGreen,
      color: "white",
      padding: theme.spacing(2),
      minWidth: 300,
    },
    priceWrapper: {
      backgroundColor: grey["100"],
      marginTop: 8,
    },
    price: {
      width: "100%",
      textAlign: "center",
      fontSize: 18,
      color: grey["900"],
    },
    paymentList: {
      paddingBottom: 0,
    },
    herokuHowToImage: {
      borderTop: "1px solid " + grey["200"],
    },
  })
);

export function UpgradeModalHeroku() {
  const styles = useStyles();
  const status = useCompanyStatus2();
  if (status === null) return null;

  return (
    <React.Fragment>
      <CardContent className={styles.noTopPadding}>
        <DialogContentText>
          You can upgrade your addon right in the Heroku Dashboard. <br />
          Head over to{" "}
          <a
            href={`https://dashboard.heroku.com/apps/${status.herokuAppName}/resources`}
          >{`dashboard.heroku.com/apps/${status.herokuAppName}/resources`}</a>{" "}
          and modify the Status List addon.
        </DialogContentText>
      </CardContent>
      <img
        src="/heroku-upgrade-instructions.png"
        alt="how to upgrade"
        className={styles.herokuHowToImage}
      />
    </React.Fragment>
  );
}
