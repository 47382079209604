import React, { useCallback, useEffect, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    runningCheck: {
      paddingRight: theme.spacing(1),
    },
    runningCheckWrapper: {
      display: "flex",
      alignItems: "center",
    },
  })
);

export function MonitorLiveCheckDetails(props: {
  onReload(): void;
  loading: boolean;
}) {
  const styles = useStyles();
  const [isActive, setIsActive] = useState(true);
  const toggleActive = useCallback(() => setIsActive(!isActive), [isActive]);
  const { loading } = props;

  const [counter, setCounter] = useState(10);
  useEffect(() => {
    if (loading) return;
    setCounter(10);
  }, [loading]);

  useEffect(() => {
    if (!isActive) return;
    if (props.loading) return;

    if (counter === 0) {
      props.onReload();
      return;
    }

    const interval = setTimeout(() => setCounter(counter - 1), 1000);
    return () => clearTimeout(interval);
  }, [counter, isActive, props]);

  const isChecking = props.loading;
  const nextCheckAt = counter + " sec";

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            {isActive ? (
              <Tooltip key="pause" title={"Pause live checks"}>
                <IconButton size="small" onClick={toggleActive}>
                  <PauseCircleFilledIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip key="play" title={"Continue live checks"}>
                <IconButton size="small" onClick={toggleActive}>
                  <PlayCircleFilledIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          <Grid item>
            {isChecking ? (
              <div className={styles.runningCheckWrapper}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={styles.runningCheck}
                >
                  Running check...
                </Typography>
                <CircularProgress size={15} />
              </div>
            ) : isActive ? (
              <Typography variant="body2" color="textSecondary">
                Will check again in {nextCheckAt}
              </Typography>
            ) : (
              <Typography variant="body2" color="textSecondary">
                Press play to resume live checks
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
