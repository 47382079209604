import React, { useState } from "react";
import { MonitorDetail } from "../index";
import { Title } from "../debugView/Title";
import { Grid } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { grey, red } from "@mui/material/colors";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { MonitorExpandedContainer } from "../MonitorExpandedContainer";
import Button from "@mui/material/Button";
import { useElementChangeEvent } from "./useElementChangeEvent";
import { DeleteMonitor } from "./DeleteMonitor";
import CircularProgress from "@mui/material/CircularProgress";
import { AsyncAction } from "../../../api/Async";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    trendingIcon: {
      color: red["900"],
      fontSize: 18,
    },
    queryBuilderIcon: {
      fontSize: 18,
    },
    responseTime: {
      width: 170,
      minWidth: 170,
    },
    separator: {
      height: theme.spacing(4),
    },
    separatorSmall: {
      height: theme.spacing(2),
    },
    title: {
      color: grey["700"],
    },
  })
);

export interface Header {
  name: string;
  value: string;
  sensitive: boolean;
}

export interface CommonInput {
  common: {
    maxResponseTimeMs: string;
    checkIntervalMs: string;
  };
}

export function EditMonitorCommon(
  props: MonitorDetail & {
    editorParts: (JSX.Element|any)[];
    saver: AsyncAction<any, any>;
    onSave(opts: { checkIntervalMs: string; maxResponseTimeMs: string }): void;
    onCancel(): void;
    onRemove(id: number): void;
  }
) {
  const styles = useStyles();

  const [maxResponseTimeMs, setMaxResponseTimeMs] = useState(
    (props.maxResponseTimeMs || 1000).toString()
  );
  const responseTimeChanged = useElementChangeEvent(setMaxResponseTimeMs);

  const [checkIntervalMs, setCheckIntervalMs] = useState(
    (props.checkIntervalMs || 1000).toString()
  );
  const checkIntervalChanged = useElementChangeEvent((e) =>
    setCheckIntervalMs((parseInt(e, 10) * 1000).toString())
  );

  return (
    <MonitorExpandedContainer>
      {props.editorParts.filter(e => e).map((e, index) => (
        <React.Fragment key={index}>
          {e}
          <div className={styles.separator} />
        </React.Fragment>
      ))}

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Title>Performance</Title>
                </Grid>
                <Grid item className={styles.trendingIcon}>
                  <TrendingUpIcon color="inherit" fontSize="inherit" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                You can get notifications when your endpoint runs too slow. Set
                a max response time and if your endpoint goes over that, you’ll
                get a performance notification.
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                variant="filled"
                label="Max Response Time"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">ms</InputAdornment>
                  ),
                }}
                className={styles.responseTime}
                onChange={responseTimeChanged}
                value={maxResponseTimeMs}
                type="number"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Title>Check Tuning</Title>
                </Grid>
                <Grid item className={styles.queryBuilderIcon}>
                  <QueryBuilderIcon color="inherit" fontSize="inherit" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                You can adjust how often we check your endpoint. A lower
                interval allows us to check more often and tell you about
                downtime sooner.
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                variant="filled"
                label="Check Interval"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">sec</InputAdornment>
                  ),
                }}
                inputProps={{
                  min: 15,
                }}
                className={styles.responseTime}
                onChange={checkIntervalChanged}
                value={parseInt(checkIntervalMs, 10) / 1000}
                type="number"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div className={styles.separator} />

      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            {props.saver.loading && (
              <Grid item>
                <CircularProgress size={25} />
              </Grid>
            )}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={props.saver.loading}
                onClick={() =>
                  props.onSave({
                    checkIntervalMs,
                    maxResponseTimeMs,
                  })
                }
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button color="primary" onClick={props.onCancel}>
                Cancel
              </Button>
            </Grid>
            {props.saver.error && (
              <Grid item>
                <Typography color="error">{props.saver.error}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <DeleteMonitor id={props.id} onRemoved={props.onRemove} />
        </Grid>
      </Grid>

      <div className={styles.separatorSmall} />
    </MonitorExpandedContainer>
  );
}
