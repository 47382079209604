import { CompanyStatusProvider } from "./core/auth";
import { CrushAnimationProvider } from "./components/CrushAnimation";
import { FreeTrialModalProvider } from "./components/header/freeTrial/FreeTrialModal";
import { HerokuBar } from "./components/header/HerokuBar";
import { Body } from "./components/Body";
import { ConfirmDialog } from "./components/Confirm";
import { OnboardingPopups } from "./components/onboardingPopups";
import { Header } from "./components/header";
import { Login } from "./components/Login";
import { Footer } from "./components/Footer";
import { SnackbarProvider } from "notistack";
import React, {PropsWithChildren, useEffect, useRef} from "react";
import { Spacer } from "./components/monitorList";
import {notAuthorizedResponse, useAuthenticated} from "nate-react-api-helpers";
import {FreeTrial} from "./components/header/FreeTrial";
import {DigitalOceanBar} from "./components/header/DigitalOceanBar";
import { Questions } from "./components/questions/Questions";

export function InternalDashboard(props: PropsWithChildren<{
  hideFreeTrial?: boolean
}>) {
  const { setAuthenticated } = useAuthenticated();

  useEffect(() => {
    // assume is authenticated until known otherwise
    setAuthenticated(true);

    const sub = notAuthorizedResponse.subscribe(() => setAuthenticated(false))
    return () => sub.cancel()
  }, [setAuthenticated]);

  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
      <CompanyStatusProvider>
        <CrushAnimationProvider>
          <FreeTrialModalProvider>
            <HerokuBar />
            <DigitalOceanBar />
            <Header />
            <Body>
              {props.hideFreeTrial ? null : <FreeTrial key="free-trial" />}
              <Spacer />
              <ConfirmDialog />
              <OnboardingPopups />
              {props.children}
              <Login />
              <Questions />
            </Body>
            <Footer />
          </FreeTrialModalProvider>
        </CrushAnimationProvider>
      </CompanyStatusProvider>
    </SnackbarProvider>
  );
}
