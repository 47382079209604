import React, {useContext, useEffect, useState} from "react";
import { Card, Grid } from "@mui/material";
import { EventEmitter } from "./EventEmitter";
import { Person } from "../api/Types";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import { useCompanyStatus2 } from "../core/auth";
import { yellow } from "@mui/material/colors";
import { Header } from "./notificationSettings/Header";
import { TeamEditor } from "./team/TeamEditor";
import {FreeTrialModalContext} from "./header/freeTrial/FreeTrialModal";
import { ClickableLink } from "./monitorList/CollapsedBodyHint";

export function useTeamMemberCount() {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const sub = teamListLoaded.subscribeAndFireLast((list) => {
      setCount(list.length);
    });

    return () => sub.cancel();
  }, []);

  return count;
}

export const teamListLoaded = new EventEmitter<Person[]>();

export function Team() {
  const [list, setList] = useState<Person[]>([]);
  useEffect(() => {
    const sub = teamListLoaded.subscribeAndFireLast(setList);
    return () => sub.cancel();
  }, []);

  const trialContext = useContext(FreeTrialModalContext);

  const status = useCompanyStatus2();
  if (status === null) return null;
  const canAdd = list.filter(v => v.email || v.phone || v.firstName || v.lastName).length < status.teamMemberCountLimit;

  return (
    <Grid container direction="column">
      <Grid item>
        <Card>
          <Table size="small">
            <TableHead>
              <Header key="instructions" cols={6} title="Recipients" />
              <TableRow key="instructions">
                <TableCell colSpan={2}>
                  <Typography variant="caption">
                    Select and edit text to make changes
                  </Typography>
                </TableCell>
                {!canAdd ? (
                  <TableCell
                    colSpan={4}
                    style={{ textAlign: "right", color: yellow["900"] }}
                  >
                    <ClickableLink onClick={trialContext.show}>Upgrade your plan</ClickableLink> to add more team members
                  </TableCell>
                ) : (
                  <TableCell colSpan={4} />
                )}
              </TableRow>
            </TableHead>
          </Table>
          <TeamEditor />
        </Card>
      </Grid>
    </Grid>
  );
}
