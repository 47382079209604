import Grid from "@mui/material/Grid";
import * as React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { missingGrey, successGreen, warningYellow } from "./colors";
import { ExpandMode } from "./Monitor";
import { useCallback } from "react";
import { IconButton } from "@mui/material";
import { useCompanyStatus2 } from "../../core/auth";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { MonitorDetail, useActiveMonitorCount } from "./index";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import { useAsyncAction } from "../../api/Async";
import { api } from "../../api";
import Tooltip from "@mui/material/Tooltip";
import grey from "@mui/material/colors/grey";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerPadding: {
      padding: theme.spacing(2),
    },
    grey: {
      color: missingGrey,
    },
    typeIcon: {
      fontSize: 28,
    },
    noWrap: {
      flexWrap: "nowrap",
    },
    textOverflow: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    rightText: {
      display: "block",
      textAlign: "right",
    },
    warningIcon: {
      color: warningYellow,
      fontSize: 28,
    },
    checkmarkIcon: {
      color: successGreen,
      fontSize: 28,
    },
    statusText: {
      fontSize: 12,
      border: "1px solid " + grey["500"],
      borderRadius: 3,
      paddingLeft: 3,
      paddingRight: 3,
      width: 60,
      textAlign: "center",
      color: grey["800"],
    },
  })
);

export function MonitorControls(props: {
  onUpdate(detail: MonitorDetail): void;
  onExpand(mode: ExpandMode): void;
  expanded: boolean;
  paused: boolean;
  id: number;
}) {
  const styles = useStyles();
  const onExpand = useCallback(() => props.onExpand("view"), [props]);
  const onCollapse = useCallback(() => props.onExpand("none"), [props]);
  const status = useCompanyStatus2();
  const activeMonitorCount = useActiveMonitorCount();

  const togglePause = useAsyncAction(async (value: boolean) => {
    const newMonitor = await api.setPaused({ id: props.id, paused: value });
    props.onUpdate(newMonitor);
  }, []);

  if (status === null) return null;

  const isExpiredFreeTrial =
    status.freeTrialDaysRemaining !== null && status.freeTrialDaysRemaining < 0;

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      className={styles.noWrap}
    >
      <Grid item>
        <Grid container spacing={1} alignItems="center">
          {!props.expanded && !isExpiredFreeTrial && (
            <Grid item>
              <div className={styles.statusText}>
                {props.paused ? "Paused" : "Active"}
              </div>
            </Grid>
          )}
          {!props.expanded && !isExpiredFreeTrial && (
            <Grid item>
              <Tooltip
                title={
                  props.paused && activeMonitorCount >= status.monitorCountLimit
                    ? "You've hit the active monitor limit for your plan"
                    : props.paused
                    ? "Start monitor"
                    : "Pause monitor"
                }
              >
                <span>
                  {togglePause.LoadingElement || (
                    <IconButton
                      onClick={() => togglePause.callback(!props.paused)}
                      size="small"
                      disabled={
                        props.paused &&
                        activeMonitorCount >= status.monitorCountLimit
                      }
                    >
                      {props.paused ? (
                        <PlayCircleFilledIcon />
                      ) : (
                        <PauseCircleFilledIcon />
                      )}
                    </IconButton>
                  )}
                </span>
              </Tooltip>
            </Grid>
          )}
          <Grid item>
            {!isExpiredFreeTrial && (
              <IconButton
                size="small"
                onClick={props.expanded ? onCollapse : onExpand}
              >
                {props.expanded ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
