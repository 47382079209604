import { useCompanyStatus2 } from "../../core/auth";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import { grey, red } from "@mui/material/colors";
import { successGreen } from "../monitorList/colors";
import { FreeTrialModalContext } from "./freeTrial/FreeTrialModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      border: "2px solid #F5A623",
      backgroundColor: "rgba(245,166,35,1)",
      borderRadius: 5,
    },
    button: {
      backgroundColor: red["900"],
      color: "#fff",

      "&:hover": {
        backgroundColor: red["900"],
      },
    },
    paymentSeparator: {
      height: theme.spacing(2),
    },
    noTopPadding: {
      paddingTop: 0,
    },
    paymentSubTitle: {
      fontWeight: "normal",
      marginBottom: 4,
    },
    greenCard: {
      backgroundColor: successGreen,
      color: "white",
      padding: theme.spacing(2),
      minWidth: 300,
    },
    priceWrapper: {
      backgroundColor: grey["100"],
      marginTop: 8,
    },
    price: {
      width: "100%",
      textAlign: "center",
      fontSize: 18,
      color: grey["900"],
    },
    paymentList: {
      paddingBottom: 0,
    },
    herokuHowToImage: {
      borderTop: "1px solid " + grey["200"],
    },
  })
);

export function FreeTrial(props: {}) {
  const styles = useStyles();
  const status = useCompanyStatus2();
  const freeTrialCtx = useContext(FreeTrialModalContext);

  useEffect(() => {
    console.log("free-trial-created");
    return () => console.log("free-trial-removed");
  }, []);

  if (status === null) return null;
  if (status.freeTrialDaysRemaining === null) return null;
  if (!status.onboarding.hasMonitors) return null;

  return (
    <Grid container className={styles.wrapper}>
        <Grid item>
              <div >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    {status.freeTrialDaysRemaining > 0 ? (
                      <Typography variant="body2" color="textSecondary">
                        You have {status.freeTrialDaysRemaining} days left on your free
                        trial
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        Your free trial has expired
                      </Typography>
                    )}
                    <Typography variant="body2" color="textSecondary">
                      Upgrade to keep monitoring
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      className={styles.button}
                      variant="contained"
                      onClick={freeTrialCtx.show}
                    >
                      Upgrade Now
                    </Button>
                  </Grid>
                </Grid>
              </div>
        </Grid>
    </Grid>
  );
}
