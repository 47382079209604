import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CardHeader from "@mui/material/CardHeader";
import * as React from "react";
import {Button, Card, Dialog, DialogContent, Grid } from "@mui/material";
import {green, grey } from "@mui/material/colors";
import {useCompanyStatus2} from "../../../core/auth";
import {useStripePromise} from "./FreeTrialModal";
import {useEffect, useRef, useState} from "react";
import {LinkButton, UpgradeModalStandard} from "./UpgradeModalStandard";
import {Elements} from "@stripe/react-stripe-js";
import {UpgradeModalHeroku} from "./UpgradeModalHeroku";
import {api, fixPath} from "../../../api";
import {trackEvent} from "../../ga";
import {useSnackbar} from "notistack";

type Plan = {
    key: string;
    name: string;
    price: string;
    features: string[];
}

const plans: Plan[] = [
    {
        key: "free",
        name: "Hobby Plan",
        price: "Free",
        features: [
            "5 monitors @ 2.5min interval",
            "1 user",
            "1 status page on *.statuslist.app domain",
        ]
    }, {
        key: "starter",
        name: "Business Plan",
        price: "8.99/mo",
        features: [
            "10 monitors @ 30sec interval",
            "2 users",
            "unlimited status pages on your custom domain",
        ]
    }, {
        key: "lifetime",
        name: "Lifetime Plan",
        price: "130.00/once",
        features: [
            "10 monitors @ 30sec interval",
            "2 users",
            "unlimited status pages on your custom domain",
        ]
    }
];

export function ShowPlansInner(props: {
    onClose(): void
}) {
    const status = useCompanyStatus2();
    const stripePromise = useStripePromise();
    const [showStarterUpgrade, setShowStarterUpgrade] = useState(false);

    const statusValid = !!status;
    const statusRef = useRef(status);
    statusRef.current = status;

    useEffect(() => {
        if(!statusValid) return;
        const obj = statusRef.current
        if(!obj) return;

        try {
            if(obj.isHeroku) {
                trackEvent("show-manage-plan-heroku")
            } else if(obj.frontendPlanName) {
                trackEvent("show-manage-plan-" + obj.frontendPlanName)
            } else {
                trackEvent("show-manage-plan")
            }
        } catch (e) {
            console.error(e)
        }
    }, [statusValid]);

    if (!status) return null;

    if (status.isHeroku) {
        return <UpgradeModalHeroku />
    }

    const canChange = status.frontendPlanName === "free"
    if(showStarterUpgrade) {
        return (
            <>
                <div style={{paddingLeft: 16, color: "black"}}>
                    <div>Upgrade to Business Plan</div>
                    <div>$8.99/mo</div>
                    <div style={{height: 10}}/>
                    <LinkButton onClick={() => setShowStarterUpgrade(false)}>Other Options</LinkButton>
                    <div style={{height: 10}}/>
                </div>

                <div style={{height: 10}}/>

                <Elements stripe={stripePromise}>
                    <UpgradeModalStandard onDone={props.onClose}/>
                </Elements>
            </>
        )
    }

    return (
        <div style={{backgroundColor: grey["200"], padding: 8, display: "flex"}}>
            {plans.map(p => <Card key={p.key} style={{padding: 8, margin: 8}}>
                    <div>
                        <div style={{fontSize: "1rem", fontWeight: "600"}}>{p.name}</div>
                        <div>{p.price}</div>
                    </div>
                    <div style={{display: "flex", opacity: p.key === status.frontendPlanName ? 1 : 0}}>
                        <div style={{
                            border: "1px solid " + green["800"],
                            borderRadius: 2,
                            color: green["800"], fontSize: "0.9rem", fontWeight: "600",
                            padding: 2, paddingLeft: 6, paddingRight: 6, marginTop: 8, marginBottom: 0,
                        }}>Current Plan
                        </div>
                    </div>
                    <div>
                        <ul style={{paddingLeft: 16, marginLeft: 0}}>
                            {p.features.map(f => <li key={f}>{f}</li>)}
                        </ul>
                    </div>
                    <Button fullWidth
                            disabled={status.frontendPlanName === p.key || !canChange}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                if (p.key === "starter") {
                                    setShowStarterUpgrade(true)
                                } else {
                                    // @ts-ignore
                                    window.location = fixPath("/api/subscription/lifetime");
                                }
                            }}
                    >{p.key === "free" ? "Choose" : "Upgrade"}</Button>
                </Card>
            )}
        </div>
    )
}

export function ManagePlan(props: {
    onClose(): void
}) {
    return (
        <Dialog open>
            <Header onClose={props.onClose}/>
            <ShowPlansInner onClose={props.onClose} />
        </Dialog>
    )
}

function Header(props: {
    simple?: boolean
    onClose(): void;
}) {

    const status = useCompanyStatus2();
    const isStripeRecurring = status?.isPaid && status.frontendPlanName !== "lifetime" && !status.isHeroku
    const snack = useSnackbar()

    return (
        <CardHeader
            title="Billing & Plans"
            subheader={<Grid container spacing={2} direction="column">
                {props.simple ? undefined : <Grid item style={{color: "black"}}>
                    More details at <a href="https://statuslist.app/pricing" target="_blank">statuslist.app/pricing</a>
                </Grid>}
                {isStripeRecurring && <Grid item><Button variant="outlined" size="small" onClick={async () => {
                    try {
                        const r = await api.getStripeManageLink()

                        // @ts-ignore
                        window.location = r.url;
                    } catch (e: any) {
                        snack.enqueueSnackbar("Error: " + e.toString(), {variant: "error"})
                    }
                }}>Manage Payment Details</Button></Grid>}
            </Grid>}
            action={
                <IconButton size="small" onClick={props.onClose}>
                    <CloseIcon/>
                </IconButton>
            }
        />
    )
}
