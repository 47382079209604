import React from "react";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import {Note} from "../Note";

export function AddStatusPage() {
    return <Note
        variant="info"
        text="Ready to setup your hosted status page?"
        action={<Link to="/status-page">
            <Typography variant="body2" color="textSecondary">
                Show Me
            </Typography>
        </Link>}
    />
}
