import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Elements } from "@stripe/react-stripe-js";
import Dialog from "@mui/material/Dialog";
import * as React from "react";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { loadStripe } from "@stripe/stripe-js";
import * as stripeJs from "@stripe/stripe-js";
import { useCompanyStatus2 } from "../../../core/auth";
import { UpgradeModalHeroku } from "./UpgradeModalHeroku";
import { UpgradeModalStandard } from "./UpgradeModalStandard";

export const FreeTrialModalContext = createContext<{
  show(): void;
  hide(): void;
  visible: boolean;
}>({
  show: () => console.warn("not setup"),
  hide: () => console.warn("not setup"),
  visible: false,
});

let stripePromise: Promise<stripeJs.Stripe | null> | null = null;

export function useStripePromise() {
    const status = useCompanyStatus2();
    if (!stripePromise && status !== null && !status.isHeroku) {
      stripePromise = loadStripe(status.stripePublicKey);
    }

    return stripePromise;
}

export function FreeTrialModalProvider(props: PropsWithChildren<{}>) {
  const [visible, setVisible] = useState(false);
  const show = useCallback(() => setVisible(true), []);
  const hide = useCallback(() => setVisible(false), []);
  const state = useMemo(
    () => ({
      show: show,
      hide: hide,
      visible: visible,
    }),
    [visible, show, hide]
  );



  return (
    <FreeTrialModalContext.Provider value={state}>
      {props.children}
    </FreeTrialModalContext.Provider>
  );
}

export function FreeTrialModal() {
  const ctx = useContext(FreeTrialModalContext);
  const status = useCompanyStatus2();
  if (status === null) return null;
  if (!status.isHeroku && stripePromise === null) return null;

  return (
    <Dialog open={ctx.visible} onClose={ctx.hide}>
      <CardHeader
        title="Upgrade Account"
        action={
          <IconButton size="small" onClick={ctx.hide}>
            <CloseIcon />
          </IconButton>
        }
      />
      {status.isHeroku ? (
        <UpgradeModalHeroku />
      ) : (
        <Elements stripe={stripePromise}>
          <UpgradeModalStandard onDone={ctx.hide} />
        </Elements>
      )}
    </Dialog>
  );
}
