import {StatusPageAdminDetails} from "../../api/Types";
import Button from "@mui/material/Button";
import React, {useCallback, useRef, useState} from "react";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import {
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    InputLabel,
    MenuItem,
    Popover,
    useTheme
} from "@mui/material";
import {grey, red} from "@mui/material/colors";
import {AddStatusPage} from "./AddStatusPage";
import DialogContentText from "@mui/material/DialogContentText";
import {useAsyncAction} from "../../api/Async";
import {api} from "../../api";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            // background: grey["400"],
        },
    }));

export function Selector(props: {
    hasMore: boolean;
    value: StatusPageAdminDetails
    selected: boolean;
    onClick(): void;
    onReload(): void;
}) {
    const styles = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLElement|null>(null);
    const [open, setOpen] = useState(false);
    const [rename, setRename] = useState(false);
    const [remove, setRemove] = useState(false);

    const onReloadRef = useRef(props.onReload);
    onReloadRef.current = props.onReload;

    const remover = useAsyncAction(async (input: StatusPageAdminDetails) => {
        await api.updateStatusPageDetails(Object.assign({}, input, {
            archived: true,
        }));

        onReloadRef.current();
        setRemove(false);
    }, [])

    const theme = useTheme();

    return (
        <>
            <Popover open={open} anchorEl={anchorEl} onClose={() => setOpen(false)}>
                <div style={{paddingTop: 16, paddingBottom: 16, padding: 10}}>
                    {props.value.name}
                </div>

                <Divider />
                <MenuItem onClick={() => {
                    setRename(true);
                    setOpen(false);
                }}>
                    Rename
                </MenuItem>
                <MenuItem onClick={() => {
                    setRemove(true)
                    setOpen(false);
                }} style={{color: red["700"]}} disabled={!props.hasMore}>
                    Remove
                </MenuItem>
            </Popover>
            {rename && <AddStatusPage value={props.value} onDone={() => {
                setRename(false);
                props.onReload();
            }} />}
            {remove && <Dialog open>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this status page?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {remover.LoadingElement}
                    <Button onClick={() => setRemove(false)}>Cancel</Button>
                    {props.value && <Button onClick={() => remover.callback(props.value)} style={{color: red["700"]}}>Delete</Button>}
                </DialogActions>
            </Dialog>}
            <Grid item>
                <Card onClick={props.onClick} style={{padding: 8,
                    cursor: "pointer",
                    border:
                        props.selected ? "2px solid " + theme.palette.primary.main : "2px solid transparent"}}>
                    <InputLabel shrink>Status Page</InputLabel>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}>
                        <div>
                            {props.value.name || "Default Page"}
                        </div>
                        {props.selected && <IconButton size="small" onClick={(e) => {
                            setAnchorEl(e.currentTarget)
                            setOpen(true)
                        }}>
                            <ArrowDropDown />
                        </IconButton>}
                    </div>
                </Card>
            </Grid>

        </>
    );
}