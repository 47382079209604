import * as React from "react";
import Grid from "@mui/material/Grid";
import { TextField, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import { api } from "../../api";
import Typography from "@mui/material/Typography";
import { trackEvent } from "../ga";

type Props = {
  onShowLogin?: () => void;
};

interface State {
  email: string;
  password: string;
  password2: string;
  firstName: string;
  lastName: string;
  loading: boolean;
  error: null | string;
}

export class RegisterInput extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      email: "",
      password: "",
      password2: "",
      firstName: "",
      lastName: "",
      loading: false,
      error: null,
    };
  }

  async submit() {
    try {
      if(this.state.password !== this.state.password2) {
        this.setState({
          loading: false,
          error: "Passwords don't match",
        })
        return;
      }

      this.setState({
        loading: true,
        error: null,
      });

      await api.register({
        email: this.state.email,
        password: this.state.password,
      });

      this.setState({
        loading: false,
      });

      // @ts-ignore
      window.location = "/";
    } catch (e: any) {
      this.setState({
        loading: false,
        error: e.message,
      });
    }
  }

  render() {
    return (
      <form
        onSubmit={(event) => {
          this.submit();
          event.preventDefault();
        }}
      >

            <Grid container direction="column" spacing={2}>
              <Grid item>
                <a
                    href="/api/auth?provider=google"
                    style={{ textDecoration: "none" }}
                >
                  <Button
                      onClick={() => trackEvent("register-login-click")}
                      variant="contained"
                      color="primary"
                      startIcon={
                        <img
                            style={{ height: "15px" }}
                            src="/google-icon2.png"
                            alt="google logo"
                        />
                      }
                  >
                    Sign in with Google
                  </Button>
                </a>
              </Grid>
              <Grid item>
                <Divider />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Email"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  variant="filled"
                  name="username"
                  type="email"
                  required
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Create a password"
                  type="password"
                  name="password"
                  variant="filled"
                  onChange={(e) => this.setState({ password: e.target.value })}
                  value={this.state.password}
                  required
                />
              </Grid><Grid item>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Confirm password"
                  type="password"
                  name="confirm-password"
                  variant="filled"
                  onChange={(e) => this.setState({ password2: e.target.value })}
                  value={this.state.password2}
                  required
                />
              </Grid>
              {this.state.error && (
                <Grid item>
                  <Typography color="error">{this.state.error}</Typography>
                </Grid>
              )}
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={
                        this.state.loading ||
                        !this.state.email ||
                        !this.state.password ||
                        !this.state.password2
                      }
                    >
                      Register
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button size="small" variant="text" onClick={this.props.onShowLogin}>
                      Have Account?
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
      </form>
    );
  }
}
