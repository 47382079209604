import { SurveyQuestions } from "./SurveyQuestions";
import React, { useCallback, useState } from "react";
import { companyStatusIsValid, useCompanyStatus } from "../../core/auth";

export function OnboardingPopups() {
  const status = useCompanyStatus();
  const [surveyDone, surveyIsDone] = useBit(false);

  if (!companyStatusIsValid(status)) return null;
  if (!status.onboarding) return null;
  if (status.onboarding.survey && !surveyDone)
    return (
      <SurveyQuestions {...status.onboarding.survey} onDone={surveyIsDone} />
    );

  return null;
}

function useBit(defaultValue: boolean): [boolean, () => void, () => void] {
  const [value, setValue] = useState<boolean>(defaultValue);
  const enable = useCallback(() => setValue(true), []);
  const disable = useCallback(() => setValue(false), []);
  return [value, enable, disable];
}
