import {MonitorDetail} from "../index";
import {useState} from "react";
import {Header} from "./EditMonitor";
import {useAsyncAction} from "../../../api/Async";
import {api} from "../../../api";
import {BasicDetails} from "./BasicDetails";
import {CustomHeaders} from "./CustomHeaders";
import {EditMonitorCommon} from "./EditMonitorCommon";
import * as React from "react";
import TextField from "@mui/material/TextField";

export function EditMonitorPing(props: MonitorDetail & {
    onDone(): void;
    onUpdate(input: MonitorDetail): void;
    onRemove(id: number): void;
}) {
    const [host, setHost] = useState(props.url);

    const save = useAsyncAction(
        async (opts: { checkIntervalMs: string; maxResponseTimeMs: string }) => {
            const result = await api.editSource({
                id: props.id,
                url: host,
                notifyResponseTimeGreaterThanMs: parseInt(opts.maxResponseTimeMs, 10),
                checkIntervalMs: parseInt(opts.checkIntervalMs, 10),
            });

            props.onUpdate(result);
            props.onDone();
        },
        [host]
    );

    return (
        <EditMonitorCommon
            {...props}
            editorParts={[
                <TextField
                    label="Hostname or IP Address"
                    value={host}
                    className={undefined}
                    onChange={e => setHost(e.target.value)}
                    type="text"
                    fullWidth
                    placeholder="myserver01.domain.com"
                />
            ]}
            saver={save}
            onSave={save.callback}
            onCancel={props.onDone}
            onRemove={props.onRemove}
        />
    )
}