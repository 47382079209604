import { Spacer } from "../monitorList";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import React from "react";
import Typography from "@mui/material/Typography";

export function SetupFooter(props: {
  loadingEl?: JSX.Element | null;
  onNext(): void;
}) {
  return (
    <React.Fragment>
      <Spacer />
      <Spacer />
      <div style={{paddingBottom: 16}}>
        <Typography variant="body2" color="textSecondary">
          <div style={{fontSize: "1rem", color: "black"}}>
            👋 Need help?</div>
          Our team would be happy to give you a hand.
            Click on the blue chat icon on the bottom right to get in touch.
        </Typography>
      </div>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        <Grid item xs>
          <Typography variant="body2" color="textSecondary">
            Got them all? Your API health endpoint, API reporting endpoint,
            Marketing site, App site, Login page?
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Looking for Ping, DB or GRPC monitors? You can add those from the Monitor tab
            afterwards.
          </Typography>
        </Grid>
        <Grid item>{props.loadingEl}</Grid>
        <Grid item>
          <Button onClick={props.onNext} variant="contained" color="primary">
            Save and Next
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
