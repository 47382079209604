import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import React from "react";

export function PageTitle(props: {
  title: string;
  subtitle?: any;
  rightAction?: JSX.Element;
}) {
  return (
    <div
      style={{
        padding: 10,
        paddingLeft: 16,
        paddingRight: 0,
        paddingBottom: 16,
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">{props.title}</Typography>
          {props.subtitle ? (
            <Typography variant="subtitle1" color="textSecondary">
              {props.subtitle}
            </Typography>
          ) : (
            <div style={{ height: 5 }} />
          )}
        </Grid>
        <Grid item>{props.rightAction}</Grid>
      </Grid>
    </div>
  );
}
