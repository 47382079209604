import React from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { TranscriptDisplay } from "../../monitorList/TranscriptDisplay";
import { MongoDbParams } from "../../../api/Types";

export function AddMongoMonitor(props: {
  params: MongoDbParams;
  darkMode?: boolean;
  onDbParamsChange(p: MongoDbParams): void;
}) {
  let transcript = "";

  if (props.params.enablePing) {
    transcript = `> db.adminCommand("ping")\n{ "ok" : 1 }`;
  }

  if (props.params.enableQuery) {
    if (transcript !== "") transcript += "\n\n";
    transcript +=
      "> db." +
      (props.params.collection || "collection") +
      '.findOne({}, {"_id": -1})\n{ "_id" : ObjectId("5f241bbe88ec444796c71254") }';
  }

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <TextField
          disabled={!props.params.enableQuery}
          label="Collection Name"
          value={props.params.collection}
          onChange={(e) =>
            props.onDbParamsChange(
              Object.assign({}, props.params, {
                collection: e.currentTarget.value,
              })
            )
          }
          variant="filled"
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>
      <Grid item>
        <InputLabel shrink>Here's the query we'll run</InputLabel>
        <TranscriptDisplay darkMode={props.darkMode} content={transcript} />
      </Grid>
    </Grid>
  );
}
