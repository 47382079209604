import React from "react";
import { MonitorDetail } from "../index";
import { EditMonitorHttp } from "./EditMonitorHttp";
import { EditMonitorDb } from "./EditMonitorDb";
import { EditMonitorGRPC } from "./EditMonitorGRPC";
import { EditMonitorPing } from "./EditMonitorPing";

export interface Header {
  name: string;
  value: string;
  sensitive: boolean;
}

export function EditMonitor(
  props: MonitorDetail & {
    onDone(): void;
    onRemove(id: number): void;
    onUpdate(detail: MonitorDetail): void;
  }
) {
  if (props.kind === "http") {
    return <EditMonitorHttp {...props} />;
  }

  if (props.kind === "grpc") {
    return <EditMonitorGRPC {...props} />;
  }

  if (props.kind === "ping") {
    return <EditMonitorPing {...props} />;
  }

  return <EditMonitorDb {...props} />;
}
