import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { green, grey, red, yellow } from "@mui/material/colors";
import React from "react";
import { Tooltip } from "@mui/material";
import { StatusLevel } from "./index";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ok: {
      backgroundColor: green["600"],
    },
    warn: {
      backgroundColor: yellow["800"],
    },
    error: {
      backgroundColor: red["800"],
    },
    none: {
      backgroundColor: grey["600"],
    },
    padding: {
      padding: theme.spacing(1),
    },
  })
);

export function ColoredTooltip(props: {
  status: StatusLevel;
  children: React.ReactElement;
  label: React.ReactElement;
}) {
  const styles = useStyles();

  return (
    <Tooltip
      classes={{ tooltip: styles[props.status] }}
      title={<div className={styles.padding}>{props.label}</div>}
      arrow
    >
      {props.children}
    </Tooltip>
  );
}
