import React, { createContext, useCallback, useMemo, useState } from "react";
import Confetti from "react-confetti";

interface Context {
  trigger(): void;
  isShowing: boolean;
}

export const CrushAnimationContext = createContext<Context>({
  trigger: () => console.warn("not setup"),
  isShowing: false,
});

export function CrushAnimationProvider(props: React.PropsWithChildren<{}>) {
  const [isShowing, setIsShowing] = useState(false);

  const trigger = useCallback(() => setIsShowing(true), []);

  const state = useMemo(() => ({ trigger: trigger, isShowing: isShowing }), [
    trigger,
    isShowing,
  ]);
  const hide = useCallback(() => {
    setIsShowing(false);
  }, []);

  return (
    <CrushAnimationContext.Provider value={state}>
      {props.children}
      {isShowing && <Animation onDone={hide} />}
    </CrushAnimationContext.Provider>
  );
}

function Animation(props: { onDone(): void }) {
  return (
    <Confetti
      numberOfPieces={1000}
      recycle={false}
      initialVelocityY={20}
      onConfettiComplete={props.onDone}
    />
  );
}
