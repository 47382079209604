import React from "react";
import { Card } from "@mui/material";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useAsync, useAsyncAction } from "nate-react-api-helpers";
import { api } from "../api";
import { NotificationBehaviour } from "../api/Types";
import { useSnackbar } from "notistack";
import { Header } from "./notificationSettings/Header";

export function NotificationSettings(props: {}) {
  const settings = useAsync(() => api.getNotificationSettings());
  const { enqueueSnackbar } = useSnackbar();

  const update = useAsyncAction(
    async (value: NotificationBehaviour) => {
      await api.updateNotificationSettings({ downBehaviour: value });
      settings.reload();
      enqueueSnackbar("Saved");
    },
    [enqueueSnackbar]
  );

  return (
    <Card>
      <Table size="small">
        <TableHead>
          <Header cols={2} title="Settings" />
          <TableRow>
            <TableCell>Event</TableCell>
            <TableCell>Alerting Behaviour</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Down Alert</TableCell>
            <TableCell>
              {settings.LoadingOrErrorElement}
              {update.LoadingElement}
              {settings.result && (
                <Select
                  style={{ fontSize: "14px" }}
                  disableUnderline
                  value={settings.result.downBehaviour}
                  onChange={async (e) => {
                    update.callback(e.target.value as any);
                  }}
                >
                  <MenuItem value="immediately">
                    Send notifications immediately
                  </MenuItem>
                  <MenuItem value="after-2-consecutive">
                    Send notifications after 2 consecutive failures
                  </MenuItem>
                </Select>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Performance Degradation Alert</TableCell>
            <TableCell>
              <Select
                style={{ fontSize: "14px" }}
                disableUnderline
                value={"after-2-consecutive"}
              >
                <MenuItem value="after-2-consecutive">
                  Send notifications after 2 consecutive failures
                </MenuItem>
              </Select>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
}
