import {ManagePlan} from "./header/freeTrial/ManagePlan";
import React from "react";
import {useHistory} from "react-router-dom";

export function ShowPlans() {
    const history = useHistory();

    return (
        <ManagePlan onClose={() => {
            history.push("/")
        }} />
    )
}