import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import * as React from "react";
import { StatusPageAdminDetails } from "../../api/Types";
import { useAsyncAction } from "../../api/Async";
import { api } from "../../api";
import { Spacer } from "../monitorList";
import { CompanyStatusContext } from "../../core/auth";
import { useContext } from "react";

export function Header(props: {
  details: StatusPageAdminDetails;
  onReload(): void;
}) {
  const published = props.details.isPublic;
  const url = "https://statuslist.app/status/" + props.details.publicId;

  const status = useContext(CompanyStatusContext);
  const update = useAsyncAction(
    async (input: Partial<StatusPageAdminDetails>) => {
      await api.updateStatusPageDetails(
        Object.assign({}, props.details, input)
      );

      status.reload();
      props.onReload();
    },
    [props, status]
  );

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Spacer />
        <Typography variant="body1" color="textPrimary">
          {published
            ? "Your status page is public"
            : "Your status page is not publicly visible"}
        </Typography>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="caption">
                {props.details.customDomain && <>
                    <Link target="_blank" href={"https://" + props.details.customDomain}>
                        {"https://" + props.details.customDomain}
                    </Link>
                    <br />
                </>}
              <Link target="_blank" href={url}>
                {url}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {!update.loading && (
        <Grid item>
          {published ? (
            <Button
              variant="text"
              onClick={() => {
                update.callback({
                  isPublic: false,
                });
              }}
            >
              Disable
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                update.callback({
                  isPublic: true,
                });
              }}
            >
              Make Public
            </Button>
          )}
        </Grid>
      )}
      {update.loading && <Grid item>{update.LoadingElement}</Grid>}
    </Grid>
  );
}
