import * as React from "react";
import { grey } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { useCompanyStatus2 } from "../core/auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: grey["300"],
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      fontSize: 13,
      color: grey["900"],
      "& a": {
        color: grey["900"],
      },
    },
  })
);

export function Footer(props: {}) {
  const styles = useStyles();
  const status = useCompanyStatus2();
  const isHeroku = status ? status.isHeroku : false;

  return (
    <div className={styles.root}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <a href="https://statuslist.app">Home Page</a>
            </Grid>
            <Grid item>
              <a href="https://statuslist.app/uptime-monitoring/guides/">
                Articles/Guides
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {isHeroku && <Grid container spacing={2}>
            <Grid item>
              <a href="https://elements.heroku.com/addons/statuslist">
                Heroku Marketplace Addon
              </a>
            </Grid>
            <Grid item>
              <a href="https://devcenter.heroku.com/articles/statuslist">
                Heroku Documentation
              </a>
            </Grid>
          </Grid>}
        </Grid>
        <Grid item>
          Questions?{" "}
          <a href="mailto:hello@statuslist.app">hello@statuslist.app</a>
        </Grid>
      </Grid>
    </div>
  );
}
