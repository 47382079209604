import { Grid, IconButton, TableBody } from "@mui/material";
import { Title } from "../debugView/Title";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import React, { useCallback } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { Header } from "./EditMonitor";
import { useElementChangeEvent } from "./useElementChangeEvent";
import Checkbox from "@mui/material/Checkbox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    httpHeaderNameColumn: {
      width: 200,
    },
    valueCell: {
      padding: 0,
    },
  })
);

const addHeader = { name: "", value: "", sensitive: false };

export function CustomHeaders(props: {
  headers: Header[];
  onHeaderChanged(headers: Header[]): void;
}) {
  const styles = useStyles();
  const onChange = useCallback(
    (header: Header, index: number) => {
      let newHeaders = props.headers.map((h, i) => {
        if (i !== index) return h;
        return header;
      });

      if (index === newHeaders.length) {
        newHeaders.push(header);
      }

      props.onHeaderChanged(newHeaders);
    },
    [props]
  );

  const onRemove = useCallback(
    (index: number) => {
      const headers = [...props.headers];
      headers.splice(index, 1);
      props.onHeaderChanged(headers);
    },
    [props]
  );

  const headers = [...props.headers, addHeader];

  return (
    <Grid container>
      <Grid item xs={6}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Title>Custom HTTP Headers</Title>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              You can include custom HTTP headers to your monitor’s check. This
              is great for things like checking a protected API endpoint.
            </Typography>
          </Grid>
          <Grid item>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={styles.httpHeaderNameColumn}>
                    Name
                  </TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>Sensitive</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {headers.map((h, index) => (
                  <HeaderRow
                    onChange={onChange}
                    onRemove={onRemove}
                    key={index}
                    sensitive={h.sensitive}
                    name={h.name}
                    value={h.value}
                    index={index}
                    isAddNew={index === headers.length - 1}
                  />
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function HeaderRow(props: {
  index: number;
  name: string;
  value: string;
  sensitive: boolean;
  isAddNew: boolean;
  onChange(value: Header, index: number): void;
  onRemove(index: number): void;
}) {
  const styles = useStyles();

  const nameChanged = useCallback(
    (name: string) =>
      props.onChange(
        {
          name: name,
          value: props.value,
          sensitive: props.sensitive,
        },
        props.index
      ),
    [props]
  );
  const nameChange = useElementChangeEvent(nameChanged);

  const valueChanged = useCallback(
    (value: string) =>
      props.onChange(
        {
          name: props.name,
          value: value,
          sensitive: props.sensitive,
        },
        props.index
      ),
    [props]
  );
  const valueChange = useElementChangeEvent(valueChanged);

  const sensitiveChanged = useCallback(
    (event: any, checked: boolean) =>
      props.onChange(
        {
          name: props.name,
          value: props.value,
          sensitive: checked,
        },
        props.index
      ),
    [props]
  );

  const remove = useCallback(() => props.onRemove(props.index), [props]);

  return (
    <TableRow>
      <TableCell className={styles.valueCell} key="name">
        <TextField type="text" value={props.name} onChange={nameChange} />
      </TableCell>
      <TableCell className={styles.valueCell} key="value">
        <TextField
          type="text"
          value={props.value}
          fullWidth
          onChange={valueChange}
        />
      </TableCell>
      <TableCell padding="checkbox">
        <Checkbox onChange={sensitiveChanged} checked={props.sensitive} />
      </TableCell>
      <TableCell padding="checkbox">
        {!props.isAddNew && (
          <IconButton size="small" onClick={remove}>
            <RemoveCircleIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
}
