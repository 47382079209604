import * as React from "react";
import { useAsync } from "../../api/Async";
import { api } from "../../api";
import {useContext, useEffect, useState} from "react";
import { PageTitle } from "../PageTitle";
import {StatusPageAdminDetails} from "../../api/Types";
import {ManageStatusPage} from "./ManageStatusPage";
import {Grid, Button, Select, MenuItem, Card, InputLabel, useTheme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {grey} from "@mui/material/colors";
import Add from "@mui/icons-material/Add";
import { Selector } from "./Selector";
import {useCompanyStatus2} from "../../core/auth";
import {FreeTrialModalContext} from "../header/freeTrial/FreeTrialModal";
import {AddStatusPage} from "./AddStatusPage";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            background: grey["200"],
        },
    }));
export function ManageStatusPages() {
  const adminDetails = useAsync(() => api.statusPageAdminDetails());
  const [selected, setSelected] = useState<StatusPageAdminDetails|null>(null);
  const styles = useStyles();

  useEffect(() => {
      if(adminDetails.loading) return;

      if(!adminDetails.result) {
          setSelected(null);
          return;
      }

      const matches = adminDetails.result.filter(r => r.id === selected?.id);
      if(matches.length === 1) {
          setSelected(matches[0])
          return;
      }

      setSelected(adminDetails.result[0]);
  }, [adminDetails.loading, adminDetails.result, selected])

    const plan = useCompanyStatus2();
    const trialCtx = useContext(FreeTrialModalContext);
    const [add, setAdd] = useState<StatusPageAdminDetails|true|false>(false);
    const theme = useTheme()

  return (
    <React.Fragment>
      <PageTitle
        title="Status Page"
        subtitle={<>
            Want to see a demo? <a href="https://status.statuslist.app" target="_blank">status.statuslist.app</a>
        </>}
      />
        {add && <AddStatusPage onDone={value => {
            setAdd(false);
            adminDetails.reload();
            if(value) setSelected(value)
        }} />}
        <div style={{paddingLeft: 8}}>
            <Grid container spacing={1} alignItems="center">
                {adminDetails.result?.map(r =>
                    <Selector
                        value={r}
                        onClick={() => setSelected(r)}
                        onReload={adminDetails.reload} hasMore={(adminDetails.result?.length || 0) > 1} selected={r === selected} />)}

                <Grid item>
                    <Card onClick={() => {
                        if(!plan?.multipleStatusPages) {
                            trialCtx.show();
                        } else {
                            setAdd(true);
                        }
                    }} style={{padding: 8,
                        marginRight: 8,
                        cursor: "pointer",
                        border: "2px solid transparent"}}>
                        <InputLabel shrink>&nbsp;</InputLabel>
                        <div>+ Add Another</div>
                    </Card>
                </Grid>
            </Grid>
        </div>
        <div style={{height: 10}} />
        {adminDetails.LoadingElement}
        {selected && <ManageStatusPage value={selected} onReload={adminDetails.reload} />}
    </React.Fragment>
  );
}
