const seconds = {
  long: "second",
  short: "sec",
};

const minutes = {
  long: "minute",
  short: "min",
};

export function humanizeSeconds(n: number, short: boolean = false) {
  const isSeconds = n < 60;
  const value = isSeconds ? n : Math.round((n / 60) * 100) / 100;

  let unitType = isSeconds ? seconds : minutes;
  let unit = short ? unitType.short : unitType.long;
  if (value > 1 || value === 0) unit += "s";
  const spacer = " ";

  return value + spacer + unit;
}
