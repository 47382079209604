import Grid from "@mui/material/Grid";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React, { useContext, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { dangerRed, missingGrey, successGreen, warningYellow } from "./colors";
import { FreeTrialModalContext } from "../header/freeTrial/FreeTrialModal";
import { humanizeSeconds } from "./humanize";
import Popover from "@mui/material/Popover";
import { useCompanyStatus2 } from "../../core/auth";
const iconSize = 20;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerPadding: {
      padding: theme.spacing(2),
    },
    grey: {
      color: missingGrey,
    },
    typeIcon: {
      fontSize: iconSize,
    },
    noWrap: {
      flexWrap: "nowrap",
    },
    textOverflow: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    rightText: {
      display: "block",
      textAlign: "right",
    },
    warningIcon: {
      color: warningYellow,
      fontSize: iconSize,
    },
    dangerIcon: {
      color: dangerRed,
      fontSize: iconSize,
    },
    cancelIcon: {
      color: missingGrey,
      fontSize: iconSize,
    },
    checkmarkIcon: {
      color: successGreen,
      fontSize: iconSize,
    },
    clickableLink: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

export function CollapsedBodyHint(props: {
  expiredTrial: boolean;
  hasActiveIncident: boolean;
  dnsChanged: boolean;
  forcePaused: boolean;
  throttled: boolean;
  requestedCheckIntervalMs: number;
  showIncidents(): void;
  showDns(): void;
}) {
  const trialContext = useContext(FreeTrialModalContext);
  const status = useCompanyStatus2();
  const styles = useStyles();

  if (props.expiredTrial) {
    return (
      <Hint
        icon={
          <div className={styles.cancelIcon}>
            <CancelIcon fontSize="inherit" color="inherit" />
          </div>
        }
        title={
          <Typography className={styles.grey} variant="body2">
            Monitoring is paused because your free trial expired
            <br />
            <Link className={styles.clickableLink} onClick={trialContext.show}>
              Upgrade and Resume
            </Link>
          </Typography>
        }
      />
    );
  }

  if (props.forcePaused) {
    return (
      <Hint
        icon={
          <div className={styles.cancelIcon}>
            <WarningIcon fontSize="inherit" color="inherit" />
          </div>
        }
        title={
          <Typography className={styles.grey} variant="body2">
            Check paused
          </Typography>
        }
        info={
          <Typography className={styles.grey} variant="body2">
            Your plan only allows for {status?.monitorCountLimit} monitors. To
            unpause this monitor{" "}
            <Link className={styles.clickableLink} onClick={trialContext.show}>
              upgrade your account
            </Link>
          </Typography>
        }
      />
    );
  }

  if (props.hasActiveIncident) {
    return (
      <Hint
        icon={
          <div className={styles.dangerIcon}>
            <CancelIcon fontSize="inherit" color="inherit" />
          </div>
        }
        title={
          <Typography className={styles.grey} variant="body2">
            There's an ongoing incident!{" "}
            <Link
              className={styles.clickableLink}
              onClick={props.showIncidents}
            >
              view
            </Link>
          </Typography>
        }
      />
    );
  }

  if (props.throttled) {
    return (
      <Hint
        icon={
          <div className={styles.cancelIcon}>
            <WarningIcon fontSize="inherit" color="inherit" />
          </div>
        }
        title={
          <Typography className={styles.grey} variant="body2">
            Check interval throttled
          </Typography>
        }
        info={
          <Typography className={styles.grey} variant="body2">
            Your plan only allows for checks every{" "}
            {humanizeSeconds(status?.maxPollRateInSeconds || 0, true)}. To
            resume checks every{" "}
            {humanizeSeconds(props.requestedCheckIntervalMs / 1000, true)}{" "}
            <Link className={styles.clickableLink} onClick={trialContext.show}>
              upgrade your account
            </Link>
          </Typography>
        }
      />
    );
  }

  if (props.dnsChanged) {
    return (
      <Hint
        icon={
          <div className={styles.warningIcon}>
            <WarningIcon fontSize="inherit" color="inherit" />
          </div>
        }
        title={
          <Typography className={styles.grey} variant="body2">
            DNS recently changed{" "}
            <Link className={styles.clickableLink} onClick={props.showDns}>
              view
            </Link>
          </Typography>
        }
      />
    );
  }

  return (
    <Hint
      icon={
        <div className={styles.checkmarkIcon}>
          <CheckCircleIcon fontSize="inherit" color="inherit" />
        </div>
      }
      title={
        <Typography className={styles.grey} variant="body2">
          DNS tracking enabled
        </Typography>
      }
    />
  );
}

function Hint(props: {
  icon: JSX.Element;
  title: JSX.Element;
  info?: JSX.Element;
}) {
  const [showInfo, setShowInfo] = useState(false);
  const [infoRef, setInfoRef] = useState<any>();
    const styles = useStyles();
  return (
    <Grid key="dns-ok" container spacing={2} alignItems="center">
      <Grid item style={{ display: "flex" }}>
        {props.title}
        {props.info && (
          <React.Fragment>
            <Link
              style={{ marginLeft: 5 }}
              className={styles.clickableLink}
              ref={setInfoRef}
              onClick={() => setShowInfo(!showInfo)}
            >
              <Typography variant="body2">details</Typography>
            </Link>
            <Popover
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              anchorEl={infoRef}
              open={showInfo}
              onClose={() => setShowInfo(false)}
            >
              <div style={{ padding: 8, maxWidth: 300 }}>{props.info}</div>
            </Popover>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
}

export function ClickableLink(props: {
    children: any;
    onClick(): void;
}) {
    const styles = useStyles();
    return (
        <Link className={styles.clickableLink} onClick={props.onClick}>
            {props.children}
        </Link>
    )
}
