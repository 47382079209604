import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import React, {useRef, useState} from "react";
import Button from "@mui/material/Button";
import {useAsyncAction} from "../../api/Async";
import {api} from "../../api";
import {StatusPageAdminDetails} from "../../api/Types";

export function AddStatusPage(props: {
    value?: StatusPageAdminDetails;
    onDone(value?: StatusPageAdminDetails): void;
}) {
    const [title, setTitle] = useState(props.value?.name || "");
    const onDoneRef = useRef(props.onDone);
    onDoneRef.current = props.onDone;

    const submit = useAsyncAction(async (input: StatusPageAdminDetails) => {
        const rs = await api.updateStatusPageDetails(input)
        onDoneRef.current(rs);
    }, []);

    return (
        <Dialog open={true}>
            <DialogTitle>{props.value ? "Rename Status Page" : "Add Status Page"}</DialogTitle>
            <DialogContent>
                <TextField value={title} placeholder="Internal Page Name" onChange={e => setTitle(e.target.value)} />
            </DialogContent>
            <DialogActions>
                {submit.LoadingElement}
                <Button onClick={() => props.onDone()}>Cancel</Button>
                <Button onClick={() => {
                    submit.callback(Object.assign(props.value || {}, {
                        id: props.value?.id || 0,
                        name: title,
                    }) as any);
                }}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}

