import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {EmailPasswordInput} from "./EmailPasswordInput";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import * as React from "react";
import Grid from "@mui/material/Grid";
import {TextField} from "@mui/material";
import {useState} from "react";
import Typography from "@mui/material/Typography";
import {useAsyncAction} from "../../api/Async";
import {api} from "../../api";

export function ForgotPassword(props: {
    onBack(): void
}) {

    const [email, setEmail] = useState("")
    const action = useAsyncAction(async (input) => {
        await api.startPasswordReset(input)
        return true;
    }, [])

    if(action.result) {
        return <Dialog open>
            <DialogTitle>Success!</DialogTitle>
            <DialogContent>
                <Typography variant="body2" style={{maxWidth: 300}}>
                    An email has been sent to {email} with a link to reset your password. You can close this page.
                </Typography>
            </DialogContent>
        </Dialog>
    }

    return (
        <Dialog open>
            <form onSubmit={e => {
                e.preventDefault();
                action.callback({
                    email: email,
                })
            }}>
                <DialogTitle>Reset Password</DialogTitle>

                <DialogContent>
                    <Typography variant="body2" style={{maxWidth: 300}}>
                        Having trouble accessing your account? Enter your email and we'll send you a reset link.
                        <br/><br/>You can also reach out to hello@statuslist.app for support.
                        <br/><br/>
                    </Typography>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                style={{width: 300}}
                                label="Email"
                                variant="filled"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                name="username"
                                type="email"
                                required
                            />
                        </Grid>
                    </Grid>
                    {action.LoadingElement}
                    {action.error}
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onBack}>Back</Button>
                    <div style={{flex: 1}} />
                    <Button variant="contained" color="primary" type="submit">Send Reset Email</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}