import React, { useEffect, useState } from "react";
import { Slider, Theme } from "@mui/material";
import {makeStyles, createStyles} from '@mui/styles';
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import {blue, orange, red } from "@mui/material/colors";
import grey from "@mui/material/colors/grey";

const seconds = [5, 10, 15, 30, 45, 60, 5 * 60];

let marks: {
  value: number;
  seconds: number;
  label: string
}[] = [];

for (let sec of seconds) {
  marks.push({
    value: marks.length,
    seconds: sec,
    label: "",
  });
}

type Seconds = number;

function secondsFormatter(v: Seconds): string {
  if (v < 60) return v + "s";
  return Math.round((v / 60) * 10) / 10 + "m";
}

function valueFormatter(index: number) {
  return secondsFormatter(marks[index]?.seconds || 0);
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    sliderOuter: {
      padding: 3,
      paddingLeft: 0,
      paddingRight: 10,
      margin: 5,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    sliderText: {
      flexGrow: 0,
      width: 50,
      textAlign: "right",
    },
  })
);

function nearestMark(value: Seconds) {
  for (let i = marks.length - 1; i >= 0; i--) {
    if (marks[i].seconds <= value) return i;
  }

  return 0;
}

export function CheckIntervalSlider(props: {
  compact?: boolean;
  disabled?: boolean
  pollRateMs: number;
  onChange(n: number): void;
}) {
  const styles = useStyle();
  const [value, setValue] = useState(0);
  const { pollRateMs, onChange } = props;
  useEffect(() => {
    setValue(nearestMark(pollRateMs / 1000));
  }, [pollRateMs]);

  useEffect(() => {
    var timer = setTimeout(
      () => onChange(marks[value as number].seconds * 1000),
      100
    );
    return () => clearTimeout(timer);
  }, [value, onChange]);

  return (
    <div
      style={
        props.compact ? {} : { paddingLeft: 6, paddingRight: 6, paddingTop: 6 }
      }
    >
      {!props.compact && <InputLabel shrink>Check Interval</InputLabel>}
      <div style={props.compact ? {} : { paddingLeft: 20, paddingRight: 20 }}>
        <div className={styles.sliderOuter}>
          <Slider
            value={value}
            onChange={(e, v) => setValue(v as number)}
            step={null}
            disabled={props.disabled}
            onBlur={() => props.onChange(marks[value as number].seconds * 1000)}
            marks={marks}
            max={marks[marks.length - 1].value}
            valueLabelFormat={valueFormatter}
            valueLabelDisplay={"auto"}
            style={{
              color:
                  props.disabled ? grey["400"] :
                  props.pollRateMs > 45 * 1000 ?
                      red["500"] :
                  props.pollRateMs > 30 * 1000 ?
                      orange["500"] :
                      blue["500"]
              ,
            }}
          />
          <div style={{ width: 5 }} />
          <Typography variant="body2" className={styles.sliderText}>
            {secondsFormatter(props.pollRateMs / 1000)}
          </Typography>
        </div>
        <div style={{color: red["800"]}}>
          {props.pollRateMs > 45 * 1000 &&
              "You might want to consider a shorter check interval" }
        </div>
      </div>
    </div>
  );
}
