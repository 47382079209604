import React, { useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { ChooseDbType } from "./ChooseDbType";
import { DbType } from "../../../api/Types";
import { api } from "../../../api";
import { useAsyncAction } from "../../../api/Async";
import { newMonitorEventName, newMonitorEvents } from "../../AddMonitor";
import { AddMonitorActions } from "./AddMonitorActions";
import { useDbMonitorParams } from "./UseDbMonitorParams";
import { useCompanyStatus2 } from "../../../core/auth";

export function AddMonitorDB(props: {
  onDone(): void;
  onHeightChange?(): void;
}) {
  const [dbType, setDbKind] = useState<DbType>();
  const useDbParams = useMemo(
    () => ({ dbType: dbType || ("" as DbType | ""), create: true }),
    [dbType]
  );
  const { element, value, resetDbParams } = useDbMonitorParams(useDbParams);
  const status = useCompanyStatus2();

  const addMonitor = useAsyncAction(async () => {
    if (dbType === undefined) throw new Error("must select db-type first");
    if (value === null) throw new Error("missing db-type");
    if (status === null) throw new Error("missing company status");

    const result = await api.addSource(
      Object.assign({}, value, {
        notifyResponseTimeGreaterThanMs: 1000,
        checkIntervalMs: status.maxPollRateInSeconds * 1000,
      })
    );

    newMonitorEvents.emit(newMonitorEventName, result);
    props.onDone();
  }, [props, dbType, value, status]);

  if (addMonitor.loading) {
    return addMonitor.LoadingElement;
  }

  return (
    <div>
      <Grid container spacing={1} direction="column">
        <Grid item>
          <ChooseDbType
            dbKind={dbType}
            setDbKind={(dbKind) => {
              setDbKind(dbKind);
              resetDbParams();
              setTimeout(() => {
                if (props.onHeightChange) props.onHeightChange();
              }, 80);
            }}
          />
        </Grid>
        {dbType !== undefined && element}
        {dbType !== undefined && (
          <AddMonitorActions submitter={addMonitor} onClose={props.onDone} />
        )}
      </Grid>
    </div>
  );
}
