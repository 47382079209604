import { Grid } from "@mui/material";
import { Loading } from "../../Loading";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React from "react";
import { AsyncAction } from "../../../api/Async";

export function AddMonitorActions(props: {
  submitter: AsyncAction<any, any>;
  onClose(): void;
}) {
  const { submitter } = props;
  return (
    <Grid item>
      <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
        {submitter.loading && (
          <Grid item>
            <Loading />
          </Grid>
        )}
        {submitter.error && (
          <Grid item>
            <Typography color="error">{submitter.error}</Typography>
          </Grid>
        )}
        <Grid item>
          <Button
            onClick={submitter.callback}
            variant="contained"
            color="primary"
            disabled={submitter.loading}
          >
            Add
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={props.onClose} color="primary">
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
