import React, { useCallback } from "react";
import { MonitorDetail, Spacer, useActiveMonitorCount } from "./index";
import { MonitorControls } from "./MonitorControls";
import { isForcePaused, MonitorCollapsedBody } from "./MonitorCollapsedBody";
import { MonitorHeader } from "./MonitorHeader";
import { MonitorDebugView } from "./MonitorDebugView";
import { EditMonitor } from "./editMonitor/EditMonitor";
import { MonitorExpandedHeader } from "./MonitorExpandedHeader";
import { DnsHistory } from "./dnsHistory/DnsHistory";
import { IncidentHistory } from "./incidentHistory/IncidentHistory";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import grey from "@mui/material/colors/grey";
import { useCompanyStatus2 } from "../../core/auth";

export type ExpandMode = "none" | "edit" | "view" | "dns" | "incident";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      "&:not(:first-child)": {
        borderTop: "1px solid " + grey["200"],
        paddingTop: theme.spacing(2),
      },
    },
  })
);

export const Monitor = React.memo(function (
  props: MonitorDetail & {
    expandMode: ExpandMode;
    onExpand(id: number, mode: ExpandMode): void;
    onRemove(id: number): void;
    onUpdate(detail: MonitorDetail): void;
  }
) {
  const styles = useStyles();
  const propsOnExpand = props.onExpand;
  const id = props.id;
  const expandMode = props.expandMode;

  const changeMode = useCallback(
    (mode: ExpandMode) => {
      if (mode === expandMode) return;
      propsOnExpand(id, mode);
    },
    [id, expandMode, propsOnExpand]
  );

  const shrink = useCallback(() => propsOnExpand(-1, "none"), [propsOnExpand]);
  const showDns = useCallback(() => propsOnExpand(id, "dns"), [
    propsOnExpand,
    id,
  ]);
  const showIncidents = useCallback(() => propsOnExpand(id, "incident"), [
    propsOnExpand,
    id,
  ]);
  const showDebug = useCallback(() => propsOnExpand(id, "view"), [
    propsOnExpand,
    id,
  ]);

  const activeMonitorCount = useActiveMonitorCount();
  const status = useCompanyStatus2();
  if (!status) return null;

  const hasActiveIncident = props.hasActiveIncident;
  const dnsChanged = props.dnsChanged;
  const expiredTrial =
    status.freeTrialDaysRemaining !== null && status.freeTrialDaysRemaining < 0;

  const forcePaused = isForcePaused(props, status, activeMonitorCount);
  const throttled = props.requestedCheckIntervalMs < props.checkIntervalMs;

  return (
    <div className={styles.wrapper}>
      <MonitorHeader
        status={
          hasActiveIncident
            ? "error"
            : dnsChanged
            ? "warn"
            : throttled || forcePaused || expiredTrial
            ? "grey-warn"
            : "ok"
        }
        iconTitle={
          hasActiveIncident ? "Active incident" : dnsChanged ? "DNS changed" :
              throttled ? "Throttled: upgrade account for faster check interval" :
                forcePaused ? "Paused: upgrade account to resume monitoring" :
                    expiredTrial ? "Expired trial: upgrade account to resume monitoring" : ""
        }
        {...props}
        onOpenDebug={showDebug}
      />
      {props.expandMode !== "none" && (
        <MonitorExpandedHeader
          mode={props.expandMode}
          changeMode={changeMode}
        />
      )}
      {props.expandMode === "view" ? (
        <MonitorDebugView id={props.id} showDns={showDns} />
      ) : props.expandMode === "edit" ? (
        <EditMonitor onDone={shrink} {...props} />
      ) : props.expandMode === "dns" ? (
        <DnsHistory {...props} />
      ) : props.expandMode === "incident" ? (
        <IncidentHistory {...props} showDebug={showDebug} />
      ) : (
        <MonitorCollapsedBody
          {...props}
          hasActiveIncident={hasActiveIncident}
          dnsChanged={dnsChanged}
          expiredTrial={expiredTrial}
          forcePaused={forcePaused}
          throttled={throttled}
          showDns={showDns}
          showIncidents={showIncidents}
          onExpand={changeMode}
          onUpdate={props.onUpdate}
        />
      )}
      {props.expandMode !== "edit" && props.expandMode !== "none" && (
        <MonitorControls
          {...props}
          onUpdate={props.onUpdate}
          expanded={true}
          onExpand={changeMode}
        />
      )}
      <Spacer />
    </div>
  );
});
