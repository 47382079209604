import Button from "@mui/material/Button";
import React, { useCallback, useState } from "react";
import { api } from "../../../api";
import { useAsyncAction } from "../../../api/Async";
import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";

export function DeleteMonitor(props: {
  id: number;
  onRemoved(id: number): void;
}) {
  const deleteAction = useAsyncAction(() => api.archiveSource(props.id), [
    props,
  ]);
  const deleteMonitor = useCallback(async () => {
    await deleteAction.callback(null);
    props.onRemoved(props.id);
  }, [deleteAction, props]);

  const [showConfirm, setShowConfirm] = useState(false);
  const [buttonEl, setButtonEl] = useState<any>();
  const onShowConfirm = useCallback(() => setShowConfirm(true), []);
  const hideConfirm = useCallback(() => setShowConfirm(false), []);

  if (deleteAction.loading) {
    return <CircularProgress />;
  }

  if (deleteAction.error) {
    return (
      <Grid container direction="column">
        <Grid item>
          <Button color="secondary" onClick={deleteMonitor}>
            Delete Monitor
          </Button>
        </Grid>
        <Grid item>
          <Typography color="error">{deleteAction.error}</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <React.Fragment>
      <Button color="secondary" onClick={onShowConfirm} ref={setButtonEl}>
        Delete Monitor
      </Button>
      <Popover open={showConfirm} anchorEl={buttonEl}>
        <MenuList>
          <MenuItem onClick={deleteMonitor}>Yes, delete this monitor</MenuItem>
          <MenuItem onClick={hideConfirm}>Cancel</MenuItem>
        </MenuList>
      </Popover>
    </React.Fragment>
  );
}
