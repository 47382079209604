import React from "react";
import { MonitorDetail } from "../index";
import { MonitorExpandedContainer } from "../MonitorExpandedContainer";
import { Table, TableRow, Typography } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import moment from "moment";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { api } from "../../../api";
import CircularProgress from "@mui/material/CircularProgress";
import WarningIcon from "@mui/icons-material/Warning";
import Grid from "@mui/material/Grid";
import { warningYellow } from "../colors";
import { useAsync } from "../../../api/Async";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dateColumn: {
      whiteSpace: "nowrap",
    },
    warningIcon: {
      color: warningYellow,
      fontSize: 28,
    },
  })
);

export function DnsHistory(props: MonitorDetail) {
  const styles = useStyles();
  const data = useAsync(() => api.getDnsHistory({ endpoint: props.id }));

  return (
    <MonitorExpandedContainer noBorderBottom>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>First Seen</TableCell>
            <TableCell>IPv4</TableCell>
            <TableCell>IPv6</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.dnsLoadBalanced && (
            <TableRow>
              <TableCell colSpan={3}>
                <Grid container alignItems="center" wrap="nowrap" spacing={2}>
                  <Grid item className={styles.warningIcon}>
                    <WarningIcon fontSize="inherit" color="inherit" />
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="textSecondary">
                      We've detected a lot of DNS changes for this monitor. This
                      is most likely cause by DNS load balancing. You can
                      probably ignore these changes.
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          )}
          {data.loading ? (
            <TableRow>
              <TableCell colSpan={3}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : data.error ? (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography color="error">{data.error}</Typography>
              </TableCell>
            </TableRow>
          ) : (
            (data.result || []).map((log) => {
              const seen = moment(log.firstSeen);
              let value = seen.fromNow();

              if (moment().diff(seen, "day") > 10) {
                value = seen.format("YYYY-MMM-DD");
              }

              return (
                <TableRow key={log.firstSeen}>
                  <TableCell className={styles.dateColumn}>{value}</TableCell>
                  <TableCell>
                    {(log.ipv4 || []).join(", ") || "(none)"}
                  </TableCell>
                  <TableCell>
                    {(log.ipv6 || []).join(", ").replace(/:/g, ":\u200B") ||
                      "(none)"}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </MonitorExpandedContainer>
  );
}
