import { useHistory, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: "white",
    },
    selectedLink: {
      color: "white",
      backgroundColor: "hsla(0,0%,0%, .2)",
    },
  })
);

export function NavLink(props: PropsWithChildren<{ route: string }>) {
  const history = useHistory();
  const location = useLocation();
  const styles = useStyles();

  return (
    <Grid item>
      <Button
        onClick={() => history.push(props.route)}
        className={
          location.pathname === props.route ? styles.selectedLink : styles.link
        }
      >
        {props.children}
      </Button>
    </Grid>
  );
}
