import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AddSourceProps,
  DbType,
  MongoDbParams,
  RedisParams,
  SqlParams,
} from "../../../api/Types";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { AddMongoMonitor } from "./AddMongoMonitor";
import { AddRedisMonitor } from "./AddRedisMonitor";
import { AddSqlMonitor } from "./AddSqlMonitor";
import { MonitorDetail } from "../../monitorList";

export function useDbMonitorParams(
  props: (MonitorDetail | { create: boolean; dbType: DbType | "" }) & {
    darkMode?: boolean;
  }
) {
  let { dbType } = props;
  if (!dbType) dbType = "postgres";

  const [queryCheck, setQueryCheck] = useState(true);
  const [pingCheck, setPingCheck] = useState(true);
  const [connectionString, setConnectionString] = useState("");
  const [dbLabel, setDbLabel] = useState("");

  const [mongoParams, setMongoParams] = useState<MongoDbParams>({
    enablePing: true,
    enableQuery: true,
    collection: "",
  });

  const [sqlParams, setSqlParams] = useState<SqlParams>({
    enablePing: true,
    enableQuery: true,
    table: "",
    column: "",
  });

  const [redisParams, setRedisParams] = useState<RedisParams>({
    enablePing: true,
    enableQuery: true,
  });

  useEffect(() => {
    if ("create" in props) return;
    switch (props.dbType) {
      case "mongo":
        setMongoParams(props.dbParams);
        break;
      case "mysql":
      case "postgres":
        setSqlParams(props.dbParams);
        break;
      case "redis":
        setRedisParams(props.dbParams);
        break;
    }

    if (props.dbType !== "") {
      setQueryCheck(props.dbParams.enableQuery);
      setPingCheck(props.dbParams.enablePing);
      setConnectionString(props.url);
      setDbLabel(props.dbLabel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMongoParams((existing) =>
      Object.assign({}, existing, {
        enablePing: pingCheck,
        enableQuery: queryCheck,
      })
    );

    setSqlParams((existing) =>
      Object.assign({}, existing, {
        enablePing: pingCheck,
        enableQuery: queryCheck,
      })
    );

    setRedisParams((existing) =>
      Object.assign({}, existing, {
        enablePing: pingCheck,
        enableQuery: queryCheck,
      })
    );
  }, [queryCheck, pingCheck]);

  const resetDbParams = useCallback(() => {
    setPingCheck(true);
    setQueryCheck(true);
    setConnectionString("");
    setDbLabel("");
  }, []);

  const value: AddSourceProps | null = useMemo(() => {
    if (dbType === undefined) return null;
    return {
      kind: "db",
      url: connectionString,
      dbLabel: dbLabel,
      dbType: dbType as DbType,
      dbParams: (dbType === "postgres"
        ? sqlParams
        : dbType === "mysql"
        ? sqlParams
        : dbType === "mongo"
        ? mongoParams
        : redisParams) as any,
      notifyResponseTimeGreaterThanMs: 0,
      checkIntervalMs: 0,
    };
  }, [connectionString, dbLabel, dbType, sqlParams, mongoParams, redisParams]);

  let connectionStringPlaceholder = dbType + "://";
  if (dbType === "mongo") {
    connectionStringPlaceholder = "mongodb://";
  }

  const element = (
    <React.Fragment>
      <Grid item>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          variant="filled"
          placeholder={"Primary DB"}
          label="Internal Label"
          value={dbLabel}
          onChange={(e) => setDbLabel(e.target.value)}
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          variant="filled"
          placeholder={connectionStringPlaceholder}
          label="Connection String"
          value={connectionString}
          onChange={(e) => setConnectionString(e.target.value)}
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={pingCheck}
              onChange={(e: any, checked: boolean) => setPingCheck(checked)}
              color="primary"
            />
          }
          label="Ping Check"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={queryCheck}
              onChange={(e: any, checked: boolean) => setQueryCheck(checked)}
              color="primary"
            />
          }
          label="Query Check"
        />
      </Grid>
      {dbType === "mongo" && (
        <AddMongoMonitor
          darkMode={props.darkMode}
          params={mongoParams}
          onDbParamsChange={setMongoParams}
        />
      )}
      {dbType === "redis" && (
        <AddRedisMonitor darkMode={props.darkMode} params={redisParams} />
      )}
      {(dbType === "postgres" || dbType === "mysql") && (
        <AddSqlMonitor
          darkMode={props.darkMode}
          params={sqlParams}
          onDbParamsChange={setSqlParams}
        />
      )}
      <Grid item key="spacer" />
    </React.Fragment>
  );

  return {
    element,
    resetDbParams,
    value,
  };
}
