import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { TeamRow } from "./TeamRow";
import Table from "@mui/material/Table";
import React from "react";
import { useAsync } from "../../api/Async";
import { api } from "../../api";
import { useCompanyStatus2 } from "../../core/auth";
import { teamListLoaded } from "../Team";

export interface TeamHook  {
  onSubmit(): Promise<void>
}

export function TeamEditor(props: {
  teamHook?: TeamHook
  bigAddButton?: boolean
}) {
  const people = useAsync(async () => {
    const people = await api.listPeople();
    teamListLoaded.emit(people);
    return people;
  });

  const list = people.result || [];
  const status = useCompanyStatus2();
  if (status === null) return null;
  const canAdd = list.length < status.teamMemberCountLimit;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell
            style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 15 }}
          >
            First Name
          </TableCell>
          <TableCell padding="checkbox">Last Name</TableCell>
          <TableCell padding="checkbox">Email</TableCell>
          <TableCell colSpan={3} />
        </TableRow>
      </TableHead>
      <TableBody>
        {people.LoadingElement && !list && (
          <TableRow>
            <TableCell colSpan={6}>{people.LoadingElement}</TableCell>
          </TableRow>
        )}
        {canAdd && (
          <TeamRow
            canRemove={false}
            reloadList={people.reload}
            isAdd
            teamHook={props.teamHook}
            bigAddButton={props.bigAddButton}
            key="add"
            person={{
              id: -1,
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              passwordIsSetup: false,
              emailVerified: false,
            }}
          />
        )}
        {list.map((p) => (
          <TeamRow
            canRemove={list.length > 1}
            reloadList={people.reload}
            key={p.id}
            person={p}
          />
        ))}
      </TableBody>
    </Table>
  );
}
