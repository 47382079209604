import { MonitorDetail } from "../index";
import React, { useCallback, useState } from "react";
import { api } from "../../../api";
import { useAsync } from "../../../api/Async";
import { MonitorExpandedContainer } from "../MonitorExpandedContainer";
import { Table, TableRow, Typography, Grid, Button } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { dangerRed, warningYellow } from "../colors";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Incident } from "../../../api/Types";
import CardHeader from "@mui/material/CardHeader";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import CardContent from "@mui/material/CardContent";
import { TranscriptDisplay } from "../TranscriptDisplay";
import { Loading } from "../../Loading";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dateColumn: {
      whiteSpace: "nowrap",
    },
    grow: {},
    down: {
      color: dangerRed,
      fontSize: 28,
      display: "flex",
    },
    degraded: {
      color: warningYellow,
      fontSize: 28,
      display: "flex",
    },
  })
);

export function IncidentHistory(props: MonitorDetail & { showDebug(): void }) {
  const styles = useStyles();
  const data = useAsync(() => api.getRecentIncidents({ endpoint: props.id }));

  return (
    <MonitorExpandedContainer noBorderBottom>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Started (local timezone)</TableCell>
            <TableCell className={styles.grow}>Ended</TableCell>
            <TableCell>Type</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.loading ? (
            <TableRow>
              <TableCell colSpan={4}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : data.error ? (
            <TableRow>
              <TableCell colSpan={4}>
                <Typography color="error">{data.error}</Typography>
              </TableCell>
            </TableRow>
          ) : (
            (data.result || []).map((log) => (
              <Row key={log.id} {...log} showDebug={props.showDebug} />
            ))
          )}
        </TableBody>
      </Table>
    </MonitorExpandedContainer>
  );
}

function Row(props: Incident & { showDebug(): void }) {
  const styles = useStyles();
  const log = props;

  const [showTranscript, setShowTranscript] = useState(false);
  const onShow = useCallback(() => setShowTranscript(true), []);
  const onHide = useCallback(() => setShowTranscript(false), []);

  return (
    <TableRow>
      <TableCell className={styles.dateColumn}>
        {autoFormatDate(log.startedAt)}
      </TableCell>
      <TableCell className={styles.dateColumn}>
        {autoFormatDate(log.endedAt)}
      </TableCell>
      <TableCell>
        {log.incidentType === "down" ? (
          <Tooltip title="Service is down">
            <div className={styles.down}>
              <CancelIcon color="inherit" fontSize="inherit" />
            </div>
          </Tooltip>
        ) : (
          <Tooltip title="Service is degraded">
            <div className={styles.degraded}>
              <WarningIcon color="inherit" fontSize="inherit" />
            </div>
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        <Grid container spacing={1}>
          <Grid item>
            <Button color="primary" onClick={onShow}>
              View Transcript
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={props.showDebug}>
              Debug
            </Button>
          </Grid>
        </Grid>

        {showTranscript && <Transcript id={log.id} onHide={onHide} />}
      </TableCell>
    </TableRow>
  );
}

function autoFormatDate(date: string | null): string {
  if (!date) return "-";
  const dt = moment(date);
  if (dt.format("YYYY-MM-dd") === moment().format("YYYY-MM-dd")) {
    if (moment().diff(dt, "minute") < 5) {
      return dt.toNow();
    }

    return "Today at " + dt.format("HH:mm a");
  }

  if (moment().diff(dt, "day") < 5) {
    return dt.format("ddd [at] HH:mm a");
  }

  return dt.format("YYYY-MM-DD HH:mm a");
}

const noTopPadding = { paddingTop: 0 };
function Transcript(props: { onHide(): void; id: number }) {
  const getTranscript = useAsync(() => api.getTranscriptForIncident(props.id));

  return (
    <Dialog open={true} onClose={props.onHide} fullWidth maxWidth="md">
      <CardHeader
        title="Incident Transcript"
        action={
          <IconButton size="small" onClick={props.onHide}>
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent style={noTopPadding}>
        {getTranscript.result !== null ? (
          <TranscriptDisplay
            maxHeight={window.innerHeight - 200}
            content={getTranscript.result}
          />
        ) : getTranscript.loading ? (
          <Loading />
        ) : getTranscript.error ? (
          <Typography color="error">{getTranscript.error}</Typography>
        ) : (
          <div />
        )}
      </CardContent>
    </Dialog>
  );
}
