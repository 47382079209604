import React, { useContext, useEffect } from "react";
import { SetupHeader } from "./SetupHeader";
import { Card } from "@mui/material";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { CrushAnimationContext } from "../CrushAnimation";

export function DonePageSetup(props: {}) {
  const history = useHistory();
  const { trigger } = useContext(CrushAnimationContext);
  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <React.Fragment>
      <SetupHeader title="You're done!" stepIndex={4} />
      <Card>
        <div style={{ padding: 20 }}>
          Let's go check how your monitors are doing.
          <br />
          <br />
          <Button
            onClick={() => history.push("/")}
            variant="contained"
            color="primary"
          >
            View Monitors
          </Button>
        </div>
      </Card>
    </React.Fragment>
  );
}
