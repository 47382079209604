import {useHistory} from "react-router-dom";
import {useContext, useEffect} from "react";
import {CompanyStatusContext} from "../core/auth";
import * as React from "react";

export function AutoRedirectRoot() {
    const history = useHistory();
    const status = useContext(CompanyStatusContext);

    useEffect(() => {
        if (!status.status) return;
        if (!status.status.onboarding.hasMonitors) {
            history.push("/setup");
            return;
        }

        history.push("/monitors")
    }, [status, history]);

    return <div>...</div>
}