import { api } from "../../api";
import { useAsyncAction } from "../../api/Async";
import { Dialog } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormGroup from "@mui/material/FormGroup";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import * as React from "react";
import { CheckItem } from "./CheckItem";
import { useCallback, useState } from "react";

export function SurveyQuestions(props: {
  question: string;
  options: string[];
  onDone(): void;
}) {
  const [answers, setAnswers] = useState<string[]>([]);

  const submit = useAsyncAction(
    () =>
      api.answerOnboardingSurvey({
        question: props.question,
        answers: answers,
      }),
    [props, answers]
  );

  const toggle = useCallback(
    (answer: string, tf: boolean) => {
      if (tf) {
        setAnswers([...answers, answer]);
        return;
      }

      setAnswers(answers.filter((d) => d !== answer));
    },
    [answers, setAnswers]
  );

  const onSubmit = useCallback(async () => {
    await submit.callback(null);
    props.onDone();
  }, [props, submit]);

  return (
    <Dialog open={true} fullWidth={true} maxWidth="xs">
      <DialogTitle>Let's setup your account</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1" color="textPrimary">
            {props.question}
          </Typography>
        </DialogContentText>
        <FormGroup>
          {props.options.map((d) => {
            return (
              <CheckItem
                key={d}
                value={d}
                checked={answers.indexOf(d) !== -1}
                onToggle={toggle}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        {submit.error && <Typography color="error">{submit.error}</Typography>}
        {submit.loading && <CircularProgress size="small" />}
        <Button
          disabled={answers.length === 0 || submit.loading}
          onClick={onSubmit}
          color="primary"
          autoFocus
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
