import { Paper } from "@mui/material";
import { MonitorHeader } from "../MonitorHeader";
import React, { PropsWithChildren, useMemo } from "react";
import { MonitorDetail, Spacer } from "../index";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { useCompanyStatus2 } from "../../../core/auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerPadding: {
      padding: theme.spacing(2),
    },
    paper: {
      backgroundColor: grey["200"],
    },
    innerPadding: {
      paddingTop: theme.spacing(2),
    },
  })
);

const doNothing = () => {};

export function AddMonitorCard(
  props: PropsWithChildren<{
    title: string;
    kind: "db" | "http";
  }>
) {
  const styles = useStyles();
  const status = useCompanyStatus2();

  const fakeResult: MonitorDetail = useMemo(
    () => ({
      id: 0,
      name: "",
      paused: false,
      requestedCheckIntervalMs: 1000,
      kind: props.kind,
      responseTimeMs: 20,
      recentSummary: [],
      dnsChanged: false,
      dnsLoadBalanced: false,
      lastCheckedAt: new Date().toString(),
      checkIntervalInSeconds: 1,
      hasActiveIncident: false,
      responseTimeRankingLevel: "ok",
      responseTimeRanking: "1",
      uptimeRankingLevel: "ok",
      uptimeRanking: "1",
      method: "GET",
      url: "",
      dbType: "",
      headers: [],
      maxResponseTimeMs: 1000,
      checkIntervalMs: (status?.maxPollRateInSeconds || 30) * 1000,
    }),
    [status, props]
  );

  const monitor = useMemo(
    () =>
      Object.assign({}, fakeResult, {
        name: props.title,
      }),
    [props, fakeResult]
  );

  return (
    <React.Fragment>
      <Paper elevation={0} variant="outlined" className={styles.paper}>
        <div className={styles.outerPadding}>
          <MonitorHeader status="ok" {...monitor} onOpenDebug={doNothing} />
          <div className={styles.innerPadding}>{props.children}</div>
        </div>
      </Paper>
      <Spacer />
    </React.Fragment>
  );
}
