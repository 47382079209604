import * as React from "react";
import { Header } from "./Header";
import { CompanyInfo } from "./CompanyInfo";
import { StatusSources } from "./StatusSources";
import { Messages } from "./Messages";
import { useAsync } from "../../api/Async";
import { api } from "../../api";
import { Card } from "../Card";
import { useEffect, useState } from "react";
import { AccordionSection } from "./AccordionSection";
import { PageTitle } from "../PageTitle";
import {CustomDomainInfo} from "./CustomDomainInfo";
import {StatusPageAdminDetails} from "../../api/Types";

export enum Section {
  MESSAGES = "messages",
  COMPANY_INFO = "company-info",
  SOURCES = "sources",
  CUSTOM_DOMAIN = "custom-domain"
}

export function ManageStatusPage(props: {
    value: StatusPageAdminDetails
    onReload(): void;
}) {
  const [section, setSection] = useState<Section>(Section.MESSAGES);

  const result = props.value;
  const r = props.value;

  useEffect(() => {
    if (!result.companyName) {
      setSection(Section.COMPANY_INFO);
      return;
    }

    if (result.sources.length === 0) {
      setSection(Section.SOURCES);
      return;
    }

    if(!result.customDomain) {
        setSection(Section.CUSTOM_DOMAIN);
        return;
    }

    setSection(Section.MESSAGES);
  }, [props.value.id]); // only update if the page changes

  const disableMessages = r.sources.length === 0 || !r.companyName;

  return (
    <React.Fragment>
      {!disableMessages && <AccordionSection
        disabled={disableMessages}
        selected={section}
        value={Section.MESSAGES}
        onChange={setSection}
        title="Public Messages"
        description={disableMessages ? "*Need sources and company name to post messages" : "Post custom messages to your Status Page"}
      >
        <Messages
          onReload={props.onReload}
          details={result}
        />
      </AccordionSection>}

      <AccordionSection
        selected={section}
        value={Section.COMPANY_INFO}
        onChange={setSection}
        title="Public Company Info"
        description="How your details show on your Status Page"
      >
        <CompanyInfo
            onReload={props.onReload}
            details={result}
        />
      </AccordionSection>
      <AccordionSection
        selected={section}
        value={Section.SOURCES}
        onChange={setSection}
        title="Uptime Data Sources"
        description="Setup which monitor data shows on your Status Page"
      >
        <StatusSources
            onReload={props.onReload}
            details={result}
        />
      </AccordionSection>
        <AccordionSection
            selected={section}
            value={Section.CUSTOM_DOMAIN}
            onChange={setSection}
            title="Custom Domain"
            description="Show your status page on status.yourdomain.com"
        >
            <CustomDomainInfo domain={result?.customDomain} />
        </AccordionSection>
      <Card slim>
        <Header onReload={props.onReload}
                details={result} />
      </Card>
    </React.Fragment>
  );
}
