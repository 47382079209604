import { useCallback } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import * as React from "react";

export function CheckItem(props: {
  value: string;
  checked: boolean;
  onToggle(domain: string, tf: boolean): void;
}) {
  const toggle = useCallback(
    (a, checked) => {
      props.onToggle(props.value, checked);
    },
    [props]
  );

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={props.checked}
          onChange={toggle}
          name={props.value}
        />
      }
      label={props.value}
    />
  );
}
