import Button from "@mui/material/Button";
import React, { useCallback, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Popover, PopoverActions, Tab } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { AddMonitorHTTP } from "./addMonitor/AddMonitorHttp";
import { AddMonitorDB } from "./addMonitor/AddMonitorDB";
import Tabs from "@mui/material/Tabs";
import grey from "@mui/material/colors/grey";
import { AddMonitorGRPC } from "./addMonitor/AddMonitorGRPC";
import { AddMonitorPing } from "./addMonitor/AddMonitorPing";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    padding: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(0.5),
    },
    link: {
      textDecoration: "underline",
      fontSize: 14,
      cursor: "pointer",
    },
    tabsWrapper: {
      borderBottom: "1px solid " + grey["200"],
    },
    monitorsUsed: {
      padding: theme.spacing(2),
    },
  })
);

export function AddMonitor(props: {}) {
  const [anchorEl, setAnchorEl] = useState<any>();
  const [open, setOpen] = useState(false);

  return (
    <Grid item>
      <Button
        variant="contained"
        ref={setAnchorEl}
        endIcon={<ExpandMoreIcon />}
        onClick={() => setOpen(true)}
      >
        Add Monitor
      </Button>
        {open && (<AddMonitorPopover anchorEl={anchorEl} onClose={() => setOpen(false)} />)}
    </Grid>
  );
}

export function AddMonitorPopover(props: {
    anchorEl: any
    initialMode?: string;
    onClose: () => void
}) {
    const [popoverActions, setPopoverActions] = useState<PopoverActions | null>();
    const styles = useStyles();
    const [tab, setTab] = useState<"http" | "db" | "grpc" | "ping" | null>((props.initialMode || "http") as any);

    return (
        <Popover
            open
            anchorEl={props.anchorEl}
            onClose={props.onClose}
            action={setPopoverActions}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
        >
            <Paper>
                <Grid container direction="column" spacing={1} alignItems="stretch">
                    <Grid item>
                        <div className={styles.tabsWrapper}>
                            <Tabs
                                indicatorColor="primary"
                                textColor="primary"
                                value={tab}
                                onChange={(e, tab) => {
                                    setTab(tab);
                                    popoverActions?.updatePosition();
                                }}
                            >
                                <Tab label="HTTP Monitor" value="http" />
                                <Tab label="Ping Monitor" value="ping" />
                                <Tab label="DB Monitor" value="db" />
                                <Tab label="GRPC Monitor" value="grpc" />
                            </Tabs>
                        </div>
                    </Grid>

                    <Grid item>
                        <div className={styles.padding}>
                            {tab === "db" ? (
                                <AddMonitorDB
                                    onDone={props.onClose}
                                    onHeightChange={popoverActions?.updatePosition}
                                />
                            ) : tab === "grpc" ? (
                                <AddMonitorGRPC
                                    onDone={props.onClose}
                                />
                            ) : tab === "ping" ? (
                                <AddMonitorPing
                                    onDone={props.onClose}
                                />
                            ) : (
                                <AddMonitorHTTP onDone={props.onClose} />
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </Popover>
    )
}
