import { EditMonitorCommon } from "./EditMonitorCommon";
import { MonitorDetail } from "../index";
import { api } from "../../../api";
import { useAsyncAction } from "../../../api/Async";
import React from "react";
import { useDbMonitorParams } from "../../header/addMonitor/UseDbMonitorParams";
import { Grid } from "@mui/material";

export function EditMonitorDb(
  props: MonitorDetail & {
    onDone(): void;
    onRemove(id: number): void;
    onUpdate(detail: MonitorDetail): void;
  }
) {
  const { value, element } = useDbMonitorParams(props);

  const saver = useAsyncAction(
    async (opts: { checkIntervalMs: string; maxResponseTimeMs: string }) => {
      const result = await api.editSource(
        Object.assign({}, value, {
          id: props.id,
          notifyResponseTimeGreaterThanMs: parseInt(opts.maxResponseTimeMs, 10),
          checkIntervalMs: parseInt(opts.checkIntervalMs, 10),
        })
      );

      props.onUpdate(result);
      props.onDone();
    },
    [value]
  );

  return (
    <EditMonitorCommon
      {...props}
      editorParts={[
        <Grid container direction="column" spacing={1}>
          {element}
        </Grid>,
      ]}
      saver={saver}
      onSave={saver.callback}
      onCancel={props.onDone}
      onRemove={props.onRemove}
    />
  );
}
