import Grid from "@mui/material/Grid";
import { BasicDetails } from "../editMonitor/BasicDetails";
import Button from "@mui/material/Button";
import { CircularProgress, Typography } from "@mui/material";
import * as React from "react";
import { useCallback, useState } from "react";
import { AddMonitorCard } from "./AddMonitorCard";
import { MonitorDetail } from "../index";
import { ExpandMode } from "../Monitor";
import { useCompanyStatus2 } from "../../../core/auth";
import { api } from "../../../api";
import { useAsyncAction } from "../../../api/Async";
import { autoFixURL } from "../../setup/HttpSetup";

export function AddHttpMonitor(props: {
  title: string;
  alwaysShow?: boolean;
  onAdd(monitor: MonitorDetail): void;
  onNavigate(id: number, editMode: ExpandMode): void;
}) {
  const [show, setShow] = useState(true);
  const [url, setUrl] = useState("");
  const [method, setMethod] = useState("GET");
  const status = useCompanyStatus2();
  const submit = useAsyncAction(
    async (showConfig) => {
      if (status === null) throw new Error("Invalid state");

      const newMonitor = await api.addSource({
        kind: "http",
        method: method,
        url: autoFixURL(url),
        headers: [],
        notifyResponseTimeGreaterThanMs: 30 * 1000,
        checkIntervalMs: status.maxPollRateInSeconds * 1000,
        onboardingSrc: props.title,
      });

      if (props.alwaysShow) {
        setUrl("");
        setMethod("GET");
      } else {
        setShow(false);
      }

      props.onAdd(newMonitor);
      if (showConfig) {
        props.onNavigate(newMonitor.id, "edit");
      } else {
        props.onNavigate(newMonitor.id, "view");
      }

      window.scrollTo(0, 0);
    },
    [method, url, props]
  );

  const valid = validateURL(url);
  const formSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!valid) {
        console.warn("form not valid");
        return;
      }

      submit.callback(false);
    },
    [submit, valid]
  );
  const customizeSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!valid) {
        console.warn("form not valid");
        return;
      }

      submit.callback(true);
    },
    [submit, valid]
  );

  if (!show) return null;

  return (
    <AddMonitorCard title={props.title} kind="http">
      <form onSubmit={formSubmit}>
        {!submit.loading ? (
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item xs>
              <BasicDetails
                url={url}
                method={method}
                onMethodChange={setMethod}
                onUrlChange={setUrl}
                filled
              />
            </Grid>
            <Grid item>
              <Button
                disabled={!valid}
                variant="contained"
                color="primary"
                onClick={formSubmit}
              >
                Add
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={!valid}
                color="primary"
                onClick={customizeSubmit}
              >
                Customize
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container alignItems="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        )}
        {submit.error && (
          <Grid container alignItems="center">
            <Grid item>
              <Typography color="error">{submit.error}</Typography>
            </Grid>
          </Grid>
        )}
      </form>
    </AddMonitorCard>
  );
}

export function validateURL(url: string): boolean {
  return !!url &&
  url.match(
    /(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
  ) !== null;
}
