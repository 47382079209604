import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import React from "react";
import { Grid } from "@mui/material";
import { companyStatusIsValid, useCompanyStatus } from "../../core/auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: "relative",
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: "#9E7CC1",
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      color: "white",
    },
    title: {
      fontSize: 16,
    },
    rightSide: {
      fontSize: 13,
      fontWeight: "normal",
      "& a": {
        color: "white",
      },
    },
  })
);

export function HerokuBar(props: {}) {
  const style = useStyles();
  const status = useCompanyStatus();
  if (!companyStatusIsValid(status)) return null;
  if (!status.isHeroku) return null;

  const prefix = "https://dashboard.heroku.com/apps/" + status.herokuAppName;

  return (
    <div className={style.wrapper}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <div className={style.title}>Heroku</div>
        </Grid>
        <Grid item>
          <Grid container spacing={2} className={style.rightSide}>
            <Grid item>{status.herokuAppName}</Grid>
            <Grid item>
              <a href={prefix + "/"}>Overview</a>
            </Grid>
            <Grid item>
              <a href={prefix + "/resources"}>Resources</a>
            </Grid>
            <Grid item>
              <a href={prefix + "/settings"}>Settings</a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
