import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import * as React from "react";
import { EmailPasswordInput } from "./login/EmailPasswordInput";
import {useCallback, useEffect, useState} from "react";
import { Register } from "./login/Register";
import {ForgotPassword} from "./login/ForgotPassword";
import {useAuthenticated} from "nate-react-api-helpers";
import {useHistory, useLocation} from "react-router-dom";
import DialogContentText from "@mui/material/DialogContentText";
import {ShowPlansInner} from "./header/freeTrial/ManagePlan";
import { CompanyStatusProvider } from "../core/auth";

export function Login() {
  const [showRegister, setShowRegister] = useState(false);
  const onShowRegister = useCallback(() => setShowRegister(true), []);
  const onShowLogin = useCallback(() => setShowRegister(false), []);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const auth = useAuthenticated();
  console.log("auth", auth)

  const location = useLocation();
  const history = useHistory();
  const hasAuth = !!auth.lastPingResult;

  const hasPreventedAutoArchive = hasAuth && location.pathname === "/login" && !!(new URLSearchParams(location.search).get("keep-active"))
  const shouldRedirect = hasAuth && location.pathname === "/login" && !hasPreventedAutoArchive;
  useEffect(() => {
      if(shouldRedirect) history.push("/");
  }, [shouldRedirect])

  if(hasPreventedAutoArchive) {
    return (
        <Dialog open>
            <DialogTitle>
                Account Status Updated
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Your account has been marked as active.
                    Your free plan will continue as before.
                    If you would like to avoid this in the future, please upgrade to one of our paid plans below.
                </DialogContentText>
            </DialogContent>
            <CompanyStatusProvider>
                <ShowPlansInner onClose={() => history.push("/")} />
            </CompanyStatusProvider>
            <DialogActions>
                <Button onClick={() => history.push("/")}>Close</Button>
            </DialogActions>
        </Dialog>
    )
  }

  if(auth.authenticated) return null;

  if (showForgotPassword) {
      return <ForgotPassword onBack={() => setShowForgotPassword(false)} />;
  }

  if (showRegister) {
    return <Register onShowLogin={onShowLogin} />;
  }

  return (
    <Dialog open>
      <DialogTitle>Login</DialogTitle>

      <DialogContent dividers>
        <EmailPasswordInput />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowForgotPassword(true)}>Forgot Password</Button>
        <div style={{flex: 1}} />
        <Button onClick={onShowRegister}>Create Account</Button>
      </DialogActions>
    </Dialog>
  );
}
