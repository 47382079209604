import {Dialog, Divider, IconButton, Menu, MenuItem, Tooltip} from "@mui/material";
import React, {useContext, useState} from "react";
import {api} from "../../api";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CardHeader from "@mui/material/CardHeader";
import CloseIcon from "@mui/icons-material/Close";
import CardContent from "@mui/material/CardContent";
import DialogContentText from "@mui/material/DialogContentText";
import { ManagePlan } from "./freeTrial/ManagePlan";
import {FreeTrialModalContext} from "./freeTrial/FreeTrialModal";

export function InfoMenu() {
    const [target, setTarget] = useState<HTMLElement | null>(null);
    const [showSupport, setShowSupport] = useState(false);
    const [showBilling, setShowBilling] = useState(false);
    const freeTrialCtx = useContext(FreeTrialModalContext)

    return (
        <>
            <Tooltip title="Account settings">
                <IconButton onClick={e => setTarget(e.currentTarget)} style={{color: "white"}}>
                    <AccountCircleIcon color="inherit" />
                </IconButton>
            </Tooltip>
            {target && <Menu anchorEl={target} open
                             onClose={e => {
                                setTarget(null);
                            }}
                             onClick={() => setTarget(null)}
                             PaperProps={{
                                 elevation: 0,
                                 sx: {
                                     overflow: 'visible',
                                     filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                     mt: 1.5,
                                     '& .MuiAvatar-root': {
                                         width: 32,
                                         height: 32,
                                         ml: -0.5,
                                         mr: 1,
                                     },
                                     '&::before': {
                                         content: '""',
                                         display: 'block',
                                         position: 'absolute',
                                         top: 0,
                                         right: 14,
                                         width: 10,
                                         height: 10,
                                         bgcolor: 'background.paper',
                                         transform: 'translateY(-50%) rotate(45deg)',
                                         zIndex: 0,
                                     },
                                 },
                             }}
                             transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                             anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => setShowSupport(true)}>
                    Support
                </MenuItem>
                <MenuItem onClick={() => setShowBilling(true)}>
                    Manage Plans & Billing
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => api.logout()}>
                    Logout
                </MenuItem>
            </Menu>}
            {showSupport && <Dialog open onClose={() => setShowSupport(false)}>
                <CardHeader
                    title="Support"
                    action={
                        <IconButton size="small" onClick={() => setShowSupport(false)}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
                <CardContent style={{marginTop: 0}}>
                    <DialogContentText>
                        We'd love to help you! Please reach out to{" "}
                        <a href="mailto:hello@statuslist.app">hello@statuslist.app</a> and our team will get back to you within 1 business day.
                    </DialogContentText>
                </CardContent>
            </Dialog>}
            {showBilling && <ManagePlan onClose={() => setShowBilling(false)} />}
            {freeTrialCtx.visible && <ManagePlan onClose={() => freeTrialCtx.hide()} />}
        </>
    )
}