import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { HeaderLeft } from "../statusPage/HeaderLeft";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { StatusPageAdminDetails } from "../../api/Types";
import { useAsyncAction } from "../../api/Async";
import { api } from "../../api";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import grey from "@mui/material/colors/grey";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    preview: {
      border: "1px solid " + grey["300"],
      borderRadius: 5,
    },
  })
);

export function CompanyInfo(props: {
  details: StatusPageAdminDetails;
  onReload(): void;
}) {
  const [logoURL, setLogoURL] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyHomePage, setCompanyHomePage] = useState("");
  const styles = useStyles();

  useEffect(() => {
    setLogoURL(props.details.logoURL);
    setCompanyHomePage(props.details.companyURL);
    setCompanyName(props.details.companyName);
  }, [props]);

  const update = useAsyncAction(
    async (input: Partial<StatusPageAdminDetails>) => {
      await api.updateStatusPageDetails(
        Object.assign({}, props.details, input)
      );
      props.onReload();
    },
    [props]
  );

  return (
    <Grid container spacing={1} direction="column">
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <TextField
                  label="Logo URL"
                  variant="filled"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  placeholder="https://applesonline.com/logo.png"
                  value={logoURL}
                  onChange={(e) => setLogoURL(e.target.value)}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Company Name"
                  variant="filled"
                  placeholder="Apples Online"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Company Home Page"
                  variant="filled"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  placeholder="https://applesonline.com"
                  value={companyHomePage}
                  onChange={(e) => setCompanyHomePage(e.target.value)}
                />
              </Grid>
              <Grid item>
                <div style={{ textAlign: "right" }}>
                  {update.LoadingElement}
                  <Button
                    disabled={update.loading}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      update.callback({
                        logoURL: logoURL,
                        companyName: companyName,
                        companyURL: companyHomePage,
                      });
                    }}
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="caption">Preview</Typography>
            <div className={styles.preview}>
              <HeaderLeft
                img={logoURL}
                name={companyName}
                url={companyHomePage}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
