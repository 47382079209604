import React from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { TranscriptDisplay } from "../../monitorList/TranscriptDisplay";
import { SqlParams } from "../../../api/Types";

export function AddSqlMonitor(props: {
  params: SqlParams;
  darkMode?: boolean;
  onDbParamsChange(p: SqlParams): void;
}) {
  const { enablePing, enableQuery, column, table } = props.params;

  const col = column || "id";
  const tbl = table || "table";
  const dashes = "-".repeat(col.length + 2);

  let transcript = "";
  if (enablePing) {
    transcript += "# ; -- ping: send empty command\n# -- blank result: success";
  }

  if (enableQuery) {
    if (transcript !== "") transcript += "\n\n";
    transcript += `# select ${col}\n# from ${tbl}\n# order by ${col} desc\n# limit 1;\n\n ${col} \n${dashes}\n 199\n(1 row)`;
  }

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <TextField
          disabled={!enableQuery}
          label="Table"
          value={table}
          onChange={(e) =>
            props.onDbParamsChange(
              Object.assign({}, props.params, {
                table: e.currentTarget.value,
              })
            )
          }
          variant="filled"
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          disabled={!enableQuery}
          label="Column"
          value={column}
          onChange={(e) =>
            props.onDbParamsChange(
              Object.assign({}, props.params, {
                column: e.currentTarget.value,
              })
            )
          }
          variant="filled"
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>
      <Grid item>
        <InputLabel shrink>Here's the query we'll run</InputLabel>
        <TranscriptDisplay darkMode={props.darkMode} content={transcript} />
      </Grid>
    </Grid>
  );
}
