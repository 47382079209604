import Grid from "@mui/material/Grid";
import { Paper, Theme } from "@mui/material";
import {makeStyles, createStyles} from '@mui/styles';
import Typography from "@mui/material/Typography";
import * as React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { green } from "@mui/material/colors";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  })
);

export function SetupConfirmation() {
  const style = useStyle();

  return (
    <Grid item key="success">
      <Paper variant="outlined" className={style.paper}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <CheckCircleIcon style={{ color: green["500"] }} />
          </Grid>
          <Grid item>
            <Typography>Success, you're all setup!</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
