type Config = (
  action: "config",
  id: string,
  props: {
    page_path: string;
  }
) => void;

type Event = (
  action: "event",
  eventAction: string,
  props: {
    event_category: string;
    event_label: string;
    value: string;
  }
) => void;

declare var gtag: Config | Event;

export function trackEvent(name: string, value?: string) {
  try {
    (gtag as Event)("event", name, {
      event_category: "app-events",
      event_label: "track-event",
      value: value || "",
    });
  } catch (e) {
    console.error(e);
  }
}
