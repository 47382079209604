import { Grid } from "@mui/material";
import React, { useMemo } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import grey from "@mui/material/colors/grey";
import { DbType } from "../../../api/Types";

export function ChooseDbType(props: {
  dbKind: DbType | null | undefined;
  setDbKind(kind: DbType): void;
}) {
  const { dbKind, setDbKind } = props;

  return (
    <Grid container spacing={1}>
      <Grid item>
        <DbOption
          src={"/db-logos/postgres.png"}
          kind={"postgres"}
          selected={dbKind}
          label="Postgres"
          onClick={setDbKind}
        />
      </Grid>
      <Grid item>
        <DbOption
          src={"/db-logos/mariadb.png"}
          kind={"mysql"}
          selected={dbKind}
          label="Maria"
          onClick={setDbKind}
        />
      </Grid>
      <Grid item>
        <DbOption
          src={"/db-logos/mongo.png"}
          kind={"mongo"}
          selected={dbKind}
          label="Mongo"
          onClick={setDbKind}
        />
      </Grid>
      <Grid item>
        <DbOption
          src={"/db-logos/redis.png"}
          kind={"redis"}
          selected={dbKind}
          label="Redis"
          onClick={setDbKind}
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: 70,
      height: 70,
      background: "#fff",
      border: "1px solid " + grey["300"],
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(1),
      borderRadius: 5,
      cursor: "pointer",
      marginBottom: theme.spacing(1),

      "&:hover": {
        borderColor: theme.palette.primary.dark,
      },
    },
    selectedWrapper: {
      borderColor: theme.palette.primary.dark,
    },
    notSelectedWrapper: {
      opacity: 0.6,
    },
    image: {
      flex: 1,
      backgroundSize: "contain",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
    },
    text: {
      marginTop: theme.spacing(1),
      color: grey["800"],
      fontSize: 13,
      textAlign: "center",
    },
  })
);

export function DbOption(props: {
  src: string;
  label: string;
  kind: string;
  selected: string | undefined | null;
  onClick(kind: string): void;
}) {
  const styles = useStyles();
  const style = useMemo(() => ({ backgroundImage: "url(" + props.src + ")" }), [
    props,
  ]);

  let wrapper = styles.wrapper;

  if (props.selected === props.kind) {
    wrapper += " " + styles.selectedWrapper;
  } else if (props.selected !== undefined && props.selected !== null) {
    wrapper += " " + styles.notSelectedWrapper;
  }

  return (
    <div className={wrapper} onClick={() => props.onClick(props.kind)}>
      <div className={styles.image} style={style} />
      <div className={styles.text}>{props.label}</div>
    </div>
  );
}
