import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { Grid } from "@mui/material";
import * as React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 25,
      fontWeight: "bold",
      color: "white",
    },
    blue: {
      padding: theme.spacing(1),
    },
    logo: {
      height: 40,
    },
  })
);

export function Logo(props: {}) {
  const styles = useStyles();

  return (
    <div className={styles.blue}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <img src={"/logo-white.png"} alt="logo" className={styles.logo} />
        </Grid>
        <Grid item className={styles.title}>
          Status List
        </Grid>
      </Grid>
    </div>
  );
}
