import * as React from "react";
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { api } from "../../api";
import Typography from "@mui/material/Typography";
import { trackEvent } from "../ga";

type Props = {};

interface State {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  loading: boolean;
  error: null | string;
}

export class EmailPasswordInput extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      loading: false,
      error: null,
    };
  }

  async submit() {
    try {
      this.setState({
        loading: true,
        error: null,
      });

      await api.login({
        username: this.state.email,
        password: this.state.password,
      });

      this.setState({
        loading: false,
      });

      window.location.reload();
    } catch (e: any) {
      debugger;
      this.setState({
        loading: false,
        error: e.message,
      });
    }
  }

  render() {
    return (
      <form
        onSubmit={(event) => {
          this.submit();
          event.preventDefault();
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Email"
                  variant="filled"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  name="username"
                  type="email"
                  required
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Password"
                  variant="filled"
                  type="password"
                  name="password"
                  onChange={(e) => this.setState({ password: e.target.value })}
                  value={this.state.password}
                  required
                />
              </Grid>
              {this.state.error && (
                <Grid item>
                  <Typography color="error">{this.state.error}</Typography>
                </Grid>
              )}
              <Grid item style={{ textAlign: "right" }}>
                <Grid container spacing={1}>
                  <Grid item>
                    <a
                      href="/api/auth?provider=google"
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => trackEvent("login-click")}
                        startIcon={
                          <img
                            style={{ height: "15px" }}
                            src="/google-icon.png"
                            alt="google logo"
                          />
                        }
                      >
                        Sign in with Google
                      </Button>
                    </a>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={this.state.loading}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Login
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
}
