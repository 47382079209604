import {notAuthenticated, useCompanyStatus} from "../../core/auth";
import {
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button
} from "@mui/material";
import React, {useContext, useState} from "react";
import {orange} from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import {FreeTrialModalContext} from "../header/freeTrial/FreeTrialModal";
import {ClickableLink} from "../monitorList/CollapsedBodyHint";

const invalidPlans = ["test", "starter", "free"];

export function CustomDomainInfo(props: {
    domain?: string | null;
}) {
    const status = useCompanyStatus();
    const [showInstallation, setShowInstallation] = useState(false);
    const trialContext = useContext(FreeTrialModalContext);

    if(status === notAuthenticated) return null;
    if(status === "loading") return null;

    if(props.domain && !showInstallation) {
        return (
            <div style={{flex: 1, paddingTop: 16}}>
                <Grid container spacing={1} direction="column">
                    <Grid item>
                        Your custom domain is configured.
                    </Grid>
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <Button onClick={() => setShowInstallation(true)}>Show installation instructions</Button>
                            </Grid>
                            <Grid item>
                                <a rel="noopener noreferrer" style={{textDecoration: "none"}} target="_blank" href={"https://" + props.domain}>
                                    <Button>View Status Page</Button>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }

    const invalidHeroku = status.isHeroku && invalidPlans.indexOf(status.herokuPlan) !== -1;
    const invalidOther = !status.isHeroku && !status.customDomain
    const isValid = !(invalidOther || invalidHeroku)

    return (
        <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
            {invalidHeroku &&
            <Typography variant="body2" style={{
                padding: 16,
                backgroundColor: orange["300"],
                borderRadius: 5,
                flex: 1,
            }}>
                You need to upgrade your Heroku plan to access this feature
            </Typography>}
            {invalidOther &&
                <Typography variant="body2" style={{
                    padding: 16,
                    backgroundColor: orange["100"],
                    borderRadius: 5,
                    flex: 1,
                }}>
                    You need to <ClickableLink onClick={trialContext.show}>upgrade your account</ClickableLink> to access this feature
                </Typography>
            }
            {isValid && <>
                <Typography variant="body1">
                    <ol>
                      <li>
                          Update your DNS with the following:

                          <Table size="small" style={{marginTop: 8, marginBottom: 8}}>
                              <TableHead>
                                  <TableRow>
                                      <TableCell>Type</TableCell>
                                      <TableCell>Name</TableCell>
                                      <TableCell>Value</TableCell>
                                      <TableCell>TTL</TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                  <TableRow>
                                      <TableCell>CNAME</TableCell>
                                      <TableCell>status</TableCell>
                                      <TableCell>status-page.statuslist.app</TableCell>
                                      <TableCell>3600</TableCell>
                                  </TableRow>
                              </TableBody>
                          </Table>
                      </li>
                        <li>
                            Contact us at <a rel="noopener noreferrer" href="mailto:hello@statuslist.app">hello@statuslist.app</a> and we'll get your account setup.
                        </li>
                    </ol>
                </Typography>

                {props.domain && <div>
                    <Button onClick={() => setShowInstallation(false)}>Hide instructions</Button>
                </div>}
            </>}
        </div>
    )
}