import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {createStyles, makeStyles} from "@mui/styles"
import { Grid, Theme } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    expandedSummary: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    content: {
      "div&.Mui-expanded": {
        minHeight: "auto",
      },
      "& .MuiAccordionSummary-content": {
        margin: "0",
      },
      "& .MuiButtonBase-root": {
        padding: 0,
        marginRight: theme.spacing(1),
      },
    },
  })
);

export function SectionSummary(props: {
  expanded: boolean;
  title: string;
  description: string;
}) {
  const styles = useStyle();

  return (
    <AccordionSummary
      classes={{ root: styles.content }}
      expandIcon={
        <ExpandMoreIcon
          style={props.expanded ? { color: "#fff" } : undefined}
        />
      }
      className={props.expanded ? styles.expandedSummary : ""}
    >
      <Grid container alignItems="center">
        <Grid item style={{ width: 250 }}>
          <Typography variant="body1" style={{ paddingTop: 2 }}>
            {props.title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{props.description}</Typography>
        </Grid>
      </Grid>
    </AccordionSummary>
  );
}
