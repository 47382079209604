import React from "react";
import { Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

export function Note(props: {
    text: string;
    action: JSX.Element;
    variant?: "info" | "warn"
}) {
  return (
    <Card
      elevation={0}
      style={{
        background: props.variant === "warn" ? "#ffc107" : "rgba(0,0,255,0.5)",
        padding: 5,
        paddingLeft: 17,
        paddingRight: 16,
        marginTop: 0,
        marginBottom: 10,
      }}
    >
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="body2" color="textSecondary">
              {props.text}
          </Typography>
        </Grid>
        <Grid item>
            {props.action}
        </Grid>
      </Grid>
    </Card>
  );
}
