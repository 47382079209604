import { useCallback, useState } from "react";

export function useSaved(props: { timeout: number }) {
  const [saved, setSaved] = useState(false);

  const trigger = useCallback(
    (() => {
      let savedTimer: any;
      return () => {
        if (savedTimer) clearTimeout(savedTimer);
        setSaved(true);

        savedTimer = setTimeout(() => {
          setSaved(false);
          savedTimer = null;
        }, props.timeout);
      };
    })(),
    [props.timeout]
  );

  return {
    trigger: trigger,
    show: saved,
  };
}
