import { MonitorDetail } from "../index";
import { BasicDetails } from "./BasicDetails";
import { CustomHeaders } from "./CustomHeaders";
import { useState } from "react";
import { api } from "../../../api";
import { useAsyncAction } from "../../../api/Async";
import { Header } from "./EditMonitor";
import * as React from "react";
import { EditMonitorCommon } from "./EditMonitorCommon";

export function EditMonitorHttp(
  props: MonitorDetail & {
    onDone(): void;
    onUpdate(input: MonitorDetail): void;
    onRemove(id: number): void;
  }
) {
  const [method, setMethod] = useState(props.method);
  const [url, setUrl] = useState(props.url);
  const [headers, setHeaders] = useState<Header[]>(props.headers || []);

  const save = useAsyncAction(
    async (opts: { checkIntervalMs: string; maxResponseTimeMs: string }) => {
      const result = await api.editSource({
        id: props.id,
        method: method,
        url: url,
        headers: headers,
        notifyResponseTimeGreaterThanMs: parseInt(opts.maxResponseTimeMs, 10),
        checkIntervalMs: parseInt(opts.checkIntervalMs, 10),
      });

      props.onUpdate(result);
      props.onDone();
    },
    [url, method, headers]
  );

  return (
    <EditMonitorCommon
      {...props}
      editorParts={[
        <BasicDetails
          url={url}
          method={method}
          onMethodChange={setMethod}
          onUrlChange={setUrl}
        />,
        <CustomHeaders headers={headers} onHeaderChanged={setHeaders} />,
      ]}
      saver={save}
      onSave={save.callback}
      onCancel={props.onDone}
      onRemove={props.onRemove}
    />
  );
}
