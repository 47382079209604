import React from "react";
import { Status } from "./StatusIndicator";
import { StatusIndicatorTooltipContent } from "./StatusIndicatorContent";
import { ColoredTooltip } from "./ColoredTooltip";

export function StatusIndicatorTooltip(props: {
  status: Status;
  children: React.ReactElement;
  onOpenDebug(): void;
}) {
  return (
    <ColoredTooltip
      status={props.status.value}
      label={
        <StatusIndicatorTooltipContent
          status={props.status}
          onOpenDebug={props.onOpenDebug}
        />
      }
    >
      {props.children}
    </ColoredTooltip>
  );
}
