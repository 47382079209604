import TableCell from "@mui/material/TableCell";
import { IconButton, MenuItem, Select } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextField from "@mui/material/TextField";
import { CheckIntervalSlider } from "../header/addMonitor/CheckIntervalSlider";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import TableRow from "@mui/material/TableRow";
import React from "react";
import green from "@mui/material/colors/green";
import { BulkAddMonitorsRow } from "../../api/Types";
import {css} from "@emotion/css";

const httpRegex = /(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const selectCss = css({
    "& fieldset": {
        border: "none",
    }
})

export function SetupHttpMonitorRow(props: {
  value: BulkAddMonitorsRow;
  onChange(newValue: BulkAddMonitorsRow): void;
  onRemove(): void;
}) {
  const looksValid = props.value.url.match(httpRegex) !== null;

  return (
    <TableRow>
      <TableCell padding="checkbox">
        <IconButton style={looksValid ? { color: green["600"] } : {}} size="large">
          <CheckCircleIcon color="inherit" />
        </IconButton>
      </TableCell>
      <TableCell padding="checkbox">
        <Select
          fullWidth
          disableUnderline
          size="small"
          className={selectCss}
          value={props.value.method}
          onChange={(e) =>
            props.onChange(
              Object.assign({}, props.value, {
                method: e.target.value,
              })
            )
          }
        >
          <MenuItem value="GET">GET</MenuItem>
          <MenuItem value="PUT">PUT</MenuItem>
          <MenuItem value="POST">POST</MenuItem>
        </Select>
      </TableCell>
      <TableCell padding="checkbox" style={{ width: "auto" }}>
        <TextField
          style={{ minWidth: 100 }}
          fullWidth
          variant="standard"
          value={props.value.url}
          InputProps={{ disableUnderline: true }}
          placeholder="https://api.mydomain.com/health"
          onChange={(e) =>
            props.onChange(
              Object.assign({}, props.value, {
                url: e.target.value,
              })
            )
          }
        />
      </TableCell>
      <TableCell padding="checkbox" style={{ width: 200 }}>
        <CheckIntervalSlider
          compact
          pollRateMs={props.value.checkIntervalMs}
          onChange={(newValue) =>
            props.onChange(
              Object.assign({}, props.value, {
                checkIntervalMs: newValue,
              })
            )
          }
        />
      </TableCell>
      <TableCell style={{ paddingTop: 0, paddingBottom: 0, width: 20 }}>
        <Button onClick={props.onRemove} startIcon={<DeleteIcon />}>
          Remove
        </Button>
      </TableCell>
    </TableRow>
  );
}
