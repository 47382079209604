import { Typography, Button } from "@mui/material";
import { Status } from "./StatusIndicator";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import React from "react";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fontSize: 26,
    },
    spacer: {
      height: theme.spacing(1),
    },
    table: {
      fontSize: 14,
      cellSpacing: 0,
      borderCollapse: "collapse",
      "& td:last-child": {
        paddingLeft: theme.spacing(2),
        textAlign: "right",
      },
      "& tr:not(:first-child) td": {
        paddingTop: theme.spacing(0.5),
      },
    },
    button: {
      color: "white",
    },
  })
);

export function StatusIndicatorTooltipContent(props: {
  status: Status;
  onOpenDebug(): void;
}) {
  const styles = useStyles();
  const { value, delimiter, success, dateStart, failure, slow } = props.status;

  if (value === "none") {
    return <Typography variant="body1">No data yet, it's coming</Typography>;
  }

  let greeting: string;
  let showLinkToDebug = false;

  switch (value) {
    case "error":
      greeting = "Houston, we have some problems!";
      showLinkToDebug = true;
      break;
    default:
    case "ok":
      greeting = "Everything's good!";
      break;
    case "warn":
      greeting = "Performance hasn't been great...";
      showLinkToDebug = true;
      break;
  }

  return (
    <React.Fragment>
      <Typography variant="body1">{greeting}</Typography>
      <Typography variant="body2">
        {moment(dateStart).format(
          delimiter === "day" ? "ddd, MMM Do" : "dddd [at] ha [(local)]"
        )}
      </Typography>
      <div className={styles.spacer} />
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>Successful checks</td>
            <td>{success}</td>
          </tr>
          <tr>
            <td>Slow responses</td>
            <td>{slow}</td>
          </tr>
          <tr>
            <td>Failures</td>
            <td>{failure}</td>
          </tr>
          <tr>
            <td>Total checks</td>
            <td>{success + slow + failure}</td>
          </tr>
        </tbody>
      </table>
      {showLinkToDebug && <div className={styles.spacer} />}
      {showLinkToDebug && (
        <Button onClick={props.onOpenDebug} className={styles.button}>
          Diagnose
        </Button>
      )}
    </React.Fragment>
  );
}
