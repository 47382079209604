import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import React from "react";
import { StatusIndicatorTooltip } from "./StatusIndicatorTooltip";
import { dangerRed, missingGrey, successGreen, warningYellow } from "./colors";
import { StatusLevel } from "./index";
import grey from "@mui/material/colors/grey";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      width: 5,
      height: 24,
      backgroundColor: missingGrey,
      borderRadius: 5,
      "&:not(:first-child)": {
        marginLeft: 4,
      },
    },
    wrapper: {
      display: "flex",
      flexDirection: "row",
    },
    ok: {
      backgroundColor: successGreen,
    },
    warn: {
      backgroundColor: warningYellow,
    },
    error: {
      backgroundColor: dangerRed,
    },
    none: {
      backgroundColor: grey["400"],
    },
  })
);

export interface Status {
  dateStart: string;
  delimiter: "hour" | "day";
  value: StatusLevel;
  success: number;
  slow: number;
  failure: number;
}

export function StatusIndicator(props: {
  list: Status[];
  onOpenDebug(): void;
}) {
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      {props.list
        .slice(0)
        .reverse()
        .map((status, index) => (
          <StatusIndicatorTooltip
            key={index}
            status={status}
            onOpenDebug={props.onOpenDebug}
          >
            <div className={styles.cell + " " + styles[status.value]} />
          </StatusIndicatorTooltip>
        ))}
    </div>
  );
}
