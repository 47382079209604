import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import React from "react";
import { Grid } from "@mui/material";
import { companyStatusIsValid, useCompanyStatus } from "../../core/auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: "relative",
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: "#031B4D",
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      color: "white",
    },
    title: {
      fontSize: 16,
    },
    rightSide: {
      fontSize: 13,
      fontWeight: "normal",
      "& a": {
        color: "white",
      },
    },
  })
);

export function DigitalOceanBar(props: {}) {
  const style = useStyles();
  const status = useCompanyStatus();
  if (!companyStatusIsValid(status)) return null;
  if (!status.isDigitalOcean) return null;

  const prefix = "https://cloud.digitalocean.com";

  return (
    <div className={style.wrapper}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <div className={style.title}>Digital Ocean</div>
        </Grid>
        <Grid item>
          <Grid container spacing={2} className={style.rightSide}>
            <Grid item>
              <a href={prefix + "/add-ons"}>Addons</a>
            </Grid>
            <Grid item>
              <a href={prefix + "/droplets"}>Droplets</a>
            </Grid>
            <Grid item>
              <a href={prefix + "/databases"}>databases</a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
