import Grid from "@mui/material/Grid";
import HttpIcon from "@mui/icons-material/Http";
import TableChartIcon from "@mui/icons-material/TableChart";
import {Tooltip, Typography } from "@mui/material";
import { StatusIndicator } from "./StatusIndicator";
import React from "react";
import { MonitorDetail } from "./index";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { dangerRed, missingGrey, successGreen, warningYellow } from "./colors";
import WarningIcon from "@mui/icons-material/Warning";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import grey from "@mui/material/colors/grey";
import NetworkPingIcon from '@mui/icons-material/NetworkPing';

const iconSize = 25;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerPadding: {
      padding: theme.spacing(2),
    },
    grey: {
      color: missingGrey,
    },
    statusIcon: {
      fontSize: iconSize,
      "& > div": {
        height: 24,
      },
    },
    typeIcon: {
      fontSize: 20,
      border: "1px solid " + grey["300"],
      borderRadius: 3,
      padding: 3,
      paddingTop: 0,
      paddingBottom: 0,
      height: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    },
    noWrap: {
      flexWrap: "nowrap",
    },
    textOverflow: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    rightText: {
      display: "block",
      textAlign: "right",
    },
    warningIcon: {
      color: warningYellow,
      fontSize: iconSize,
    },
    checkmarkIcon: {
      color: successGreen,
      fontSize: iconSize,
    },
    dangerIcon: {
      color: dangerRed,
      fontSize: iconSize,
    },
    cancelIcon: {
      color: missingGrey,
      fontSize: iconSize,
    },
    loader: {
      height: 12,
    },
  })
);

export function formatMonitorName(props: MonitorDetail) {

    let extra: string = "";
    if(props.grpcParams && props.grpcParams.serviceName) {
        extra = " (" + props.grpcParams.serviceName + ")";
    }

    return `${props.name}${extra}`
}

export function MonitorIcon(props: {
    kind: string;
}) {
    const styles = useStyles();

    return (
        <div className={styles.typeIcon}>
            {props.kind === "db" ? (
                <TableChartIcon fontSize="inherit"/>
            ) : props.kind === "grpc" ? (
                <span style={{fontSize: 12, fontWeight: 500}}>GRPC</span>
            ) : props.kind === "ping" ? (
                <span style={{fontSize: 12, fontWeight: 500}}>PING</span>
            ) : (
                <HttpIcon fontSize="inherit"/>
            )}
        </div>
    )
}

export function MonitorHeader(
    props: MonitorDetail & {
        onOpenDebug(): void;
        status: "error" | "warn" | "grey-warn" | "ok";
        iconTitle?: string;
    }
) {
    const styles = useStyles();
    let responseTime = (props.responseTimeMs + "ms") as any;
    if (props.loading) {
        responseTime = (
            <img src="/loader.gif" alt="loader" className={styles.loader}/>
        );
    }


    return (
        <Grid container spacing={2} alignItems="center" className={styles.noWrap}>
      <Grid item className={styles.statusIcon}>
        {props.status === "grey-warn" ? (
            <Tooltip title={props.iconTitle}>
              <div className={styles.cancelIcon}>
                <WarningIcon fontSize="inherit" color="inherit" />
              </div>
            </Tooltip>
        ) : props.status === "warn" ? (
            <Tooltip title={props.iconTitle}>
              <div className={styles.warningIcon}>
                <WarningIcon fontSize="inherit" color="inherit" />
              </div>
            </Tooltip>
        ) : props.status === "error" ? (
            <Tooltip title={props.iconTitle}>
              <div className={styles.dangerIcon}>
                <CancelIcon fontSize="inherit" color="inherit" />
              </div>
            </Tooltip>
        ) : (
          <div className={styles.checkmarkIcon}>
            <CheckCircleIcon fontSize="inherit" color="inherit" />
          </div>
        )}
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Typography
              className={styles.grey + " " + styles.textOverflow}
              variant="h5"
            >
              {formatMonitorName(props)}
            </Typography>
          </Grid>
          <Grid item>
              <MonitorIcon kind={props.kind} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs></Grid>
      <Grid item>
        <Typography className={styles.grey} variant="body1">
          Response Time: {responseTime}
        </Typography>
      </Grid>
      <Grid item>
        <StatusIndicator
          list={props.recentSummary || []}
          onOpenDebug={props.onOpenDebug}
        />
      </Grid>
    </Grid>
  );
}
