import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { ComparisonIcon } from "./TimingComparisonIcon";
import { blue, deepPurple, grey, indigo, pink } from "@mui/material/colors";
import { Title } from "./Title";
import { CheckResult } from "../../../api/Types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      borderCollapse: "collapse",
      width: "100%",
      "& td:not(:first-child)": {
        paddingLeft: theme.spacing(1),
      },
      "& td:last-child": {
        paddingLeft: theme.spacing(2),
      },
    },
    alignCenter: {
      textAlign: "center",
    },
    alignRight: {
      textAlign: "right",
    },
    bar: {
      height: 5,
      borderRadius: 2,
      backgroundColor: grey["200"],
      position: "relative",
      zIndex: 2,
    },
    barCell: {
      position: "relative",
      width: "100%",
      borderRight: "1px solid " + grey["200"],
    },
    eventNameCell: {
      whiteSpace: "nowrap",
    },
    newRequest: {
      position: "relative",

      "& p": {
        position: "relative",
        backgroundColor: "white",
        paddingLeft: 10,
        paddingRight: 10,
        zIndex: 3,
        display: "inline",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    newRequestInner: {
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      height: "50%",
      borderBottom: "1px solid " + grey["300"],
      zIndex: 1,
    },
    measurementLabelWrapper: {
      width: "100%",
      position: "relative",
      height: "20px",
    },
    headerRow: {
      "& td": {
        verticalAlign: "bottom",
        paddingTop: 7, // to compensate for DNS icon button pushing it down
      },
    },
    duration: {
      paddingLeft: theme.spacing(1),
    },
  })
);

export function MonitorTiming(props: CheckResult) {
  const styles = useStyles();

  const events = props.events || [];

  const totalWidth = events.reduce((acc, e) => acc + e.durationMs, 0);
  const middleTimeLabel = Math.round(totalWidth / 2 / 10) * 10;

  let offset = 0;
  const rows: JSX.Element[] = [];
  events.map((e, index) => {
    if (index > 0) {
      offset += events[index - 1].durationMs;
    }

    if (index > 0 && events[0].name === e.name) {
      rows.push(
        <tr key={index + "-new-request"}>
          <td
            colSpan={5}
            className={styles.alignCenter + " " + styles.newRequest}
          >
            <div className={styles.newRequestInner} />
            <Typography variant="body2" color="textSecondary">
              HTTP Redirect
            </Typography>
          </td>
        </tr>
      );
    }

    rows.push(
      <tr key={index}>
        <td className={styles.eventNameCell}>
          <Typography variant="body2" color="textSecondary">
            {e.name}
          </Typography>
        </td>
        <td className={styles.alignRight + " " + styles.duration}>
          <Typography variant="body2" color="textSecondary">
            {e.durationMs}ms
          </Typography>
        </td>
        <td className={styles.alignCenter}>
          <ComparisonIcon {...e} />
        </td>
        <td className={styles.barCell}>
          <Bar
            offset={offset}
            width={e.durationMs}
            graphWidth={totalWidth}
            index={index}
          />
          <MiddleVerticalLine
            offsetLeft={middleTimeLabel}
            graphWidth={totalWidth}
          />
          <MiddleVerticalLine isLeft offsetLeft={0} graphWidth={totalWidth} />
        </td>
      </tr>
    );
    return null;
  });

  return (
    <table className={styles.table}>
      <tbody>
        <tr className={styles.headerRow}>
          <td colSpan={3}>
            <Title>Timing</Title>
          </td>
          <td>
            <div className={styles.measurementLabelWrapper}>
              <MeasurementHeader
                offset={0}
                totalWidth={totalWidth}
                value={"0"}
              />
              <MeasurementHeader
                offset={middleTimeLabel}
                totalWidth={totalWidth}
                value={middleTimeLabel + "ms"}
              />
              <MeasurementHeader
                isLast
                offset={totalWidth}
                totalWidth={totalWidth}
                value={totalWidth + "ms"}
              />
            </div>
          </td>
        </tr>
        {rows}
      </tbody>
    </table>
  );
}

function MeasurementHeader(props: {
  offset: number;
  totalWidth: number;
  value: string;
  isLast?: boolean;
}) {
  const widthStyle = useMemo(() => {
    if (props.isLast)
      return {
        position: "absolute" as "absolute",
        right: "0",
        bottom: 0,
      };

    return {
      left: (props.offset / props.totalWidth) * 100 + "%",
      position: "absolute" as "absolute",
      bottom: 0,
    };
  }, [props.offset, props.totalWidth, props.isLast]);

  return (
    <Typography style={widthStyle} variant="caption" color="textSecondary">
      {props.value}
    </Typography>
  );
}

const colors = [blue["600"], deepPurple["600"], indigo["600"], pink["600"]];

function Bar(props: {
  offset: number;
  width: number;
  graphWidth: number;
  index: number;
}) {
  const styles = useStyles();

  const widthStyle = useMemo(
    () => ({
      width: clamp((props.width / props.graphWidth) * 100, 2) + "%",
      left: (props.offset / props.graphWidth) * 100 + "%",
      backgroundColor: colors[props.index % colors.length],
    }),
    [props.offset, props.index, props.width, props.graphWidth]
  );

  return <div className={styles.bar} style={widthStyle} />;
}

function clamp(n: number, min: number) {
  if (n <= min) return min;
  return n;
}

function MiddleVerticalLine(props: {
  isLeft?: boolean;
  graphWidth: number;
  offsetLeft: number;
}) {
  const style = useMemo(
    () => ({
      position: "absolute" as "absolute",
      left: props.isLeft
        ? "13px"
        : (props.offsetLeft / props.graphWidth) * 100 + "%",
      borderLeft: "1px solid " + grey["200"],
      height: "100%",
      top: 0,
    }),
    [props.graphWidth, props.offsetLeft, props.isLeft]
  );

  return <div style={style} />;
}
