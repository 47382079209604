import React, { useCallback, useEffect, useState } from "react";
import { useCompanyStatus2 } from "../../../core/auth";
import { api } from "../../../api";
import { useAsyncAction } from "../../../api/Async";
import { newMonitorEventName, newMonitorEvents } from "../../AddMonitor";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { BasicDetails } from "../../monitorList/editMonitor/BasicDetails";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { validateURL } from "../../monitorList/inlineAddMonitor/AddHttpMonitor";
import Chip from "@mui/material/Chip";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import Tooltip from "@mui/material/Tooltip";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import DnsIcon from "@mui/icons-material/Dns";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LockIcon from "@mui/icons-material/Lock";
import HttpIcon from "@mui/icons-material/Http";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import { CheckIntervalSlider } from "./CheckIntervalSlider";
import { autoFixURL } from "../../setup/HttpSetup";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      paddingTop: theme.spacing(1),
    },
    chipWrapper: {
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
      maxWidth: 475,
    },
  })
);

function autoFixHost(value: string) {
  return value.trim()
}

function validateHost(value: string) {
  
  try {
    const u = new URL("http://" + value)
    return u.host === value;
  } catch (e) {
    return false;
  }
}

export function AddMonitorPing(props: { onDone(): void }) {
  const [host, setHost] = useState("");
  const [pollRateMs, setPollRateMs] = useState<number>(0);
  const status = useCompanyStatus2();
  const styles = useStyles();

  const performanceMs = 30 * 1000;
  useEffect(() => {
    setPollRateMs((status?.maxPollRateInSeconds || 30) * 1000);
  }, [status]);

  const submit = useAsyncAction(async () => {
    const newMonitor = await api.addSource({
      kind: "ping",
      url: autoFixHost(host),
      notifyResponseTimeGreaterThanMs: performanceMs,
      checkIntervalMs: pollRateMs,
    });

    newMonitorEvents.emit(newMonitorEventName, newMonitor);
    props.onDone();
    setHost("");
  }, [host, performanceMs, pollRateMs]);

  const valid = validateHost(host);
  const formSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!valid) {
        console.warn("form not valid");
        return;
      }

      submit.callback(null);
    },
    [submit, valid]
  );

  if (submit.loading) {
    return submit.LoadingElement;
  }

  return (
    <form onSubmit={formSubmit}>
      <Grid
        container
        direction="column"
        spacing={2}
        alignItems="flex-end"
        className={styles.wrapper}
      >
        <Grid item style={{width: "100%"}}>
          <TextField
              label="Hostname or IP Address"
              value={host}
              className={undefined}
              onChange={e => setHost(e.target.value)}
              type="text"
              fullWidth
              placeholder="myserver01.domain.com"
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <CheckIntervalSlider
            pollRateMs={pollRateMs}
            onChange={setPollRateMs}
          />
        </Grid>
        <Grid item>
          <div className={styles.chipWrapper}>
            <Tooltip
              title={
                "Will notify for requests that take longer than " +
                performanceMs +
                "ms"
              }
            >
              <Chip
                variant="outlined"
                size="small"
                icon={<TrendingUpIcon />}
                label={performanceMs + "ms"}
              />
            </Tooltip>
          </div>
        </Grid>
        <Grid item>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item></Grid>
            <Grid item>
              <Grid container spacing={1}>
                {submit.error && (
                  <Grid item>
                    <Typography style={{ maxWidth: "300px" }} color="error">
                      {submit.error}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submit.callback}
                  >
                    Add
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={props.onDone} color="primary">
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
