import { PropsWithChildren } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import * as React from "react";
import { Section } from "./ManageStatusPage";
import { SectionSummary } from "./SectionSummary";

export function AccordionSection(
  props: PropsWithChildren<{
    disabled?: boolean;
    title: string;
    description: string;
    value: Section;
    selected: Section;
    onChange(section: Section): void;
  }>
) {
  const expanded = props.selected === props.value && !props.disabled;
  return (
    <Accordion
      expanded={expanded}
      onChange={() => props.onChange(props.value)}
      disabled={props.disabled}
    >
      <SectionSummary
        expanded={expanded}
        title={props.title}
        description={props.description}
      />
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
}
