import { useCallback, useContext, useEffect, useState } from "react";
import { CrushAnimationContext } from "../CrushAnimation";

export type MonitorLiveUpdate =
  | {
      endpoint: number;
      checkStarted: true;
    }
  | {
      endpoint: number;
      responseTimeMs: number;
      checkedAt: string;
      checkStarted: false;
      success: boolean;
      slow: boolean;
      failure: boolean;
      dnsChanged: boolean;
    };

let animationIsShowing: boolean = false;

function useControlFlow(props: {
  onUpdate: (change: MonitorLiveUpdate[]) => void;
}) {
  const [queue, setQueue] = useState<MonitorLiveUpdate[]>([]);
  const animation = useContext(CrushAnimationContext);
  animationIsShowing = animation.isShowing;

  const propsOnUpdate = props.onUpdate;
  const onUpdate = useCallback(
    (change: MonitorLiveUpdate[]) => {
      if (animationIsShowing) {
        setQueue((q) => [...q, ...change]);
        return;
      }

      if (queue.length > 0) {
        propsOnUpdate([...queue, ...change]);
        setQueue([]);
        return;
      }

      propsOnUpdate(change);
    },
    [queue, propsOnUpdate]
  );

  return {
    onUpdate: onUpdate,
  };
}

export function useLiveMonitorStats(
  onUpdate: (change: MonitorLiveUpdate[]) => void
) {
  const [retry, setRetry] = useState(0);
  const ctrlFlow = useControlFlow({ onUpdate });
  const ctrlFlowOnUpdate = ctrlFlow.onUpdate;

  useEffect(() => {
    const protocol = window.location.protocol === "http:" ? "ws:" : "wss:";
    const ws = new WebSocket(
      protocol + "//" + window.location.host + "/api/monitor/live-checks"
    );
    let purposefulClose = false;

    ws.addEventListener("close", () => {
      if (purposefulClose) return;
      console.log("closed, retrying in 5 seconds");

      setTimeout(() => {
        setRetry(retry + 1);
      }, 5 * 1000);
    });

    ws.addEventListener("message", (e) => {
      const received = JSON.parse(e.data);
      if (received === "ping") {
        ws.send(`"pong"`);
        return;
      }

      console.log("ws-message", e);
      ctrlFlowOnUpdate([received as MonitorLiveUpdate]);
    });

    ws.addEventListener("open", () => {
      ws.send(`"hello world"`);
      console.log("open ws");
    });

    return () => {
      purposefulClose = true;
      ws.close();
    };
  }, [retry, onUpdate, ctrlFlowOnUpdate]);
}
