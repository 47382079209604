import { InputLabel, Theme } from "@mui/material";
import { SlackStatus } from "../../api/Types";
import * as React from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { api } from "../../api";
import { useAsyncAction } from "../../api/Async";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { SetupConfirmation } from "./SetupConfirmation";
import {makeStyles, createStyles} from "@mui/styles"

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: "200px",
    },
  })
);

export function SlackDetails(
  props: SlackStatus & {
    didJustSetup: boolean;
    onReload(action: "setup" | "change" | "disable"): void;
  }
) {
  const style = useStyle();

  const setChannel = useAsyncAction(
    (event: React.ChangeEvent<{ value: any }>) =>
      api.setSlackChannel(event.target.value).then(() => {
        props.onReload(props.channel === null ? "setup" : "change");
      }),
    [props.channel]
  );

  const remove = useAsyncAction(
    () => api.removeSlack().then(props.onReload),
    []
  );

  if (setChannel.loading || remove.loading) {
    return <CircularProgress />;
  }

  if (!props.isConnected) {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="body1" color="textSecondary">
            You can get downtime and performance notifications right in your
            Slack channel. This is great when you have a couple people who need
            to be notified about an outage.
          </Typography>
        </Grid>
        <Grid item>
          <a href="https://slack.com/oauth/v2/authorize?client_id=1064864619862.1071598576050&scope=channels:join,channels:read,chat:write,groups:read">
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </a>
        </Grid>
      </Grid>
    );
  }

  console.log(props.channel);

  return (
    <Grid container direction="column" spacing={1}>
      <SetupHint show={!props.channel} />
      {props.didJustSetup && <SetupConfirmation />}
      <Grid item>
        <Grid container direction="row" spacing={2} alignItems="flex-end">
          <Grid item>
            <FormControl className={style.formControl}>
              <InputLabel>Notification Channel</InputLabel>
              <Select
                key={props.channel || "selector"}
                value={props.channel}
                onChange={setChannel.callback as any}
                displayEmpty
              >
                {props.channels.map((chan) => (
                  <MenuItem disabled={chan.private} value={chan.id} key={chan.id}>
                    {chan.name}{chan.private ? " (private)" : null}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {props.channel && (
            <Grid item key={"remove-integration"}>
              <Button color="secondary" onClick={remove.callback}>
                Remove Integration
              </Button>
            </Grid>
          )}
          <Grid item>
            {setChannel.error && (
              <Typography color="error">{setChannel.error}</Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function SetupHint(props: { show: boolean }) {
  if (!props.show) return null;
  return (
    <React.Fragment>
      <Grid item>
        <Typography variant="h6">Finish Setup</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          Choose the channel where you'd like to get notifications
        </Typography>
      </Grid>
    </React.Fragment>
  );
}
