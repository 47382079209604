import { MonitorDetail } from "../index";
import { CustomHeaders } from "./CustomHeaders";
import { useState } from "react";
import { api } from "../../../api";
import { useAsyncAction } from "../../../api/Async";
import { Header } from "./EditMonitor";
import * as React from "react";
import { EditMonitorCommon } from "./EditMonitorCommon";
import {FormControlLabel, Switch, TextField, Typography, Grid} from "@mui/material";
import Alert from "@mui/lab/Alert";
import {grey, red} from "@mui/material/colors";
import Button from "@mui/material/Button";
import {OAuthConfig} from "../../header/addMonitor/OAuthConfig";

function coalesce<T>(input: T|undefined, defaultValue: T) {
    if(input === undefined) return defaultValue;
    return input;
}

export function EditMonitorGRPC(
  props: MonitorDetail & {
    onDone(): void;
    onUpdate(input: MonitorDetail): void;
    onRemove(id: number): void;
  }
) {
  const [url, setUrl] = useState(props.url);
  const [serviceName, setServiceName] = useState(props.grpcParams?.serviceName || "");
  const [tlsEnabled, setTlsEnabled] = useState(coalesce(props.grpcParams?.tlsEnabled, true));
  const [tlsInsecure, setTlsInsecure] = useState(coalesce(props.grpcParams?.tlsInsecure, true));
  const [headers, setHeaders] = useState<Header[]>(props.headers || []);
    const [oauth, setOAuth] = useState<any>(props.grpcParams?.oAuth || null);

  const save = useAsyncAction(
    async (opts: {
        checkIntervalMs: string;
        maxResponseTimeMs: string;
        serviceName: string;
        tlsEnabled: boolean;
        tlsInsecure: boolean;
        oauth: any;
    }) => {
      const result = await api.editSource({
        id: props.id,
        url: url,
        headers: headers,
          grpcParams: {
              tlsEnabled: opts.tlsEnabled,
              tlsInsecure: opts.tlsInsecure,
              serviceName: opts.serviceName,
              oAuth: opts.oauth,
          },

        notifyResponseTimeGreaterThanMs: parseInt(opts.maxResponseTimeMs, 10),
        checkIntervalMs: parseInt(opts.checkIntervalMs, 10),
      });

      props.onUpdate(result);
      props.onDone();
    },
    [url, headers]
  );

  return (
    <EditMonitorCommon
      {...props}
      editorParts={[
              <TextField label="Host:Port"
                         value={url}
                         onChange={e => setUrl(e.target.value)}
                         InputLabelProps={{shrink: true}} placeholder="grpc.example.com:8080" fullWidth />,
              <TextField label="Service Name (optional)"
                         value={serviceName}
                         onChange={e => setServiceName(e.target.value)}
                         InputLabelProps={{shrink: true}} fullWidth />,

          (<Grid container spacing={1}>
                  {!oauth && <Grid item>
                      <Button size="small" color="primary" variant="outlined" onClick={() => {
                          setOAuth({});

                          // trigger fake resize event to get the popover to reposition correctly
                          // timeout is related to expected transition time
                          setTimeout(() => window.dispatchEvent(new Event("resize")), 300);
                      }}>Setup OAuth</Button>
                  </Grid>}
                  {!tlsEnabled && <Grid item>
                      <Button size="small" color="primary" variant="outlined" onClick={() => setTlsEnabled(true)}>Setup TLS</Button>
                  </Grid>}
              </Grid>),

          tlsEnabled &&
              <Grid container spacing={1} alignItems="center">
                  <Grid item xs>
                      <FormControlLabel
                          control={
                              <Switch checked={tlsInsecure} onChange={(e, checked) => setTlsInsecure(checked)}/>
                          }
                          label="Allow self-signed TLS certificates"
                      />
                  </Grid>
                  <Grid item>
                      <Button size="small" color="secondary" variant="outlined" onClick={() => setTlsEnabled(false)}>Remove
                          TLS</Button>
                  </Grid>
              </Grid>,
            oauth && <OAuthConfig value={oauth} onChange={setOAuth} />,
          <Alert severity="info">
              <Typography>
                  We'll be calling the built-in grpc health check endpoint <span style={{
                  padding: "2px 4px", borderRadius: 2,
                  backgroundColor: grey["200"],
                  color: red["900"]}}>/grpc.health.v1.Health/Check</span>
                  You can read more about health checks at <a href="https://github.com/grpc/grpc/blob/master/doc/health-checking.md" target="_blank">github.com/grpc/.../health-checking.md</a>
              </Typography>
            </Alert>,
         <CustomHeaders headers={headers} onHeaderChanged={setHeaders} />,
      ]}
      saver={save}
      onSave={input => save.callback(Object.assign({}, input, {
          serviceName,
          tlsEnabled,
          tlsInsecure,
          oauth,
      }))}
      onCancel={props.onDone}
      onRemove={props.onRemove}
    />
  );
}
