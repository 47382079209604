import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, {PropsWithChildren, useCallback, useContext, useRef, useState} from "react";
import { CompanyStatusContext } from "../../../core/auth";
import { api } from "../../../api";
import { useAsyncAction } from "../../../api/Async";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import { Grid, Typography, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { green, grey, red } from "@mui/material/colors";
import ListItemText from "@mui/material/ListItemText";
import { Loading } from "../../Loading";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { successGreen } from "../../monitorList/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      border: "2px solid #F5A623",
      backgroundColor: "rgba(245,166,35,0.18)",
      borderRadius: 5,
    },
    button: {
      backgroundColor: red["900"],
      color: "#fff",

      "&:hover": {
        backgroundColor: red["900"],
      },
    },
    paymentSeparator: {
      height: theme.spacing(2),
    },
    noTopPadding: {
      paddingTop: 0,
    },
    paymentSubTitle: {
      fontWeight: "normal",
      marginBottom: 4,
    },
    greenCard: {
      backgroundColor: successGreen,
      color: "white",
      padding: theme.spacing(2),
      minWidth: 300,
    },
    priceWrapper: {
      backgroundColor: grey["100"],
      marginTop: 8,
    },
    price: {
      width: "100%",
      textAlign: "center",
      fontSize: 18,
      color: grey["900"],
    },
    paymentList: {
      paddingBottom: 0,
    },
    herokuHowToImage: {
      borderTop: "1px solid " + grey["200"],
    },
  })
);

export function UpgradeModalStandard(props: { onDone(): void }) {
  const styles = useStyles();

  const stripe = useStripe();
  const elements = useElements();
  const [isComplete, setIsComplete] = useState(false);
  const [showCoupon, setShowCoupon] = useState(false);
  const [coupon, setCoupon] = useState("")
  const couponRef = useRef(coupon);
  couponRef.current = coupon;

  const statusCtx = useContext(CompanyStatusContext);

  const submit = useAsyncAction(
    async (e: Event) => {
      e.preventDefault();

      if (stripe === null) return;
      if (elements === null) return;

      const element = elements.getElement(CardElement);
      if (element === null) return;

      const result = await stripe.createToken(element);
      if (result.error) throw new Error(result.error.message);
      if (!result.token)
        throw new Error("Failed to register card, no token created");

      await api.createSubscription({
        stripeToken: result.token.id,
        coupon: couponRef.current,
      });

      setIsComplete(true);
    },
    [stripe, statusCtx]
  );

  const { loading, error } = submit;
  const done = useCallback(() => {
    window.location.reload();
  }, []);

  if (isComplete) {
    return (
      <React.Fragment>
        <CardContent className={styles.noTopPadding}>
          <Paper className={styles.greenCard}>
            <Typography variant="h5">
              Wohooo!{" "}
              <span role="img" aria-label="tada">
                🎉
              </span>
            </Typography>
            <Typography variant="body1">Your account ahs been upgraded</Typography>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button color="inherit" onClick={done}>
                  Done
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </CardContent>
      </React.Fragment>
    );
  }

  return (
    <CardContent className={styles.noTopPadding}>
      <form onSubmit={(e) => submit.callback(e as any)}>
          <Grid container direction="column">
              <Grid item>
                  <Typography className={styles.paymentSubTitle} variant="h6">
                      Payment Details
                  </Typography>
              </Grid>
              <Grid item>
                  <Paper style={{width: "320px", padding: "10px"}}>
                      <CardElement/>
                  </Paper>
              </Grid>
              <Grid item>
                  <div className={styles.paymentSeparator}/>
              </Grid>
              <Grid item>
                  {error != null && <Typography color="error">{error}</Typography>}
                  {loading && <Loading/>}
              </Grid>
              {showCoupon ? <Grid item>
                  <TextField label="Coupon" size="small"
                             value={coupon} onChange={e => setCoupon(e.target.value)}

                  />
              </Grid> : <Grid item>
                  <div style={{paddingLeft: 10}}>
                    <LinkButton onClick={() => setShowCoupon(true)}>Coupon?</LinkButton>
                  </div>
              </Grid>}
              <div style={{height: 10}}/>
              <Grid item>
                  <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      onClick={(e) => submit.callback(e as any)}
                  >
                      Submit & Upgrade
                  </Button>
              </Grid>
              <div style={{height: 30}}/>
              <Grid item>
                  <Typography variant="body2" align="center" color="textSecondary">
                      Questions? <a href="hello@statuslist.app">hello@statuslist.app</a>
                  </Typography>
              </Grid>
              <div className={styles.paymentSeparator}/>
              <Grid item>
                  <Typography variant="body2" color="textSecondary" align="center">
                      Payments are processed by{" "}
                      <a href="https://stripe.com">Stripe.com</a>
                  </Typography>
                  <Typography variant="body2" color="textSecondary" align="center">
                      We have a 7 day money back guarantee
                  </Typography>
              </Grid>
          </Grid>
      </form>
    </CardContent>
  );
}

export function LinkButton(props: PropsWithChildren<{
    onClick(): void;
}>) {
    return (
        <button style={{
            border: "none",
            background: "none",
            color: grey["700"],
            textDecoration: "underline",
            cursor: "pointer",
            padding: 0,
        }}
                onClick={props.onClick}>{props.children}</button>
    )
}