import React, { useCallback } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { Button } from "@mui/material";
import { ExpandMode } from "./Monitor";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: grey["200"],
      marginTop: theme.spacing(1),
      marginLeft: -16,
      marginRight: -16,
      paddingLeft: 16,
    },
    button: {},
    buttonSelected: {
      backgroundColor: grey["300"],
      color: grey["900"],
      borderRadius: 0,
      "&:hover": {
        backgroundColor: grey["300"],
        color: grey["900"],
      },
    },
    spacer: {
      display: "inline-block",
      width: 10,
    },
  })
);

export function MonitorExpandedHeader(props: {
  mode: ExpandMode;
  changeMode(mode: ExpandMode): void;
}) {
  const styles = useStyles();
  const showOverview = useCallback(() => props.changeMode("view"), [props]);
  const showDNS = useCallback(() => props.changeMode("dns"), [props]);
  const showIncidents = useCallback(() => props.changeMode("incident"), [
    props,
  ]);
  const showEdit = useCallback(() => props.changeMode("edit"), [props]);

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={showOverview}
        className={
          props.mode === "view" ? styles.buttonSelected : styles.button
        }
      >
        Debug
      </Button>
      <div className={styles.spacer} />
      <Button
        onClick={showDNS}
        className={props.mode === "dns" ? styles.buttonSelected : styles.button}
      >
        DNS History
      </Button>
      <div className={styles.spacer} />
      <Button
        onClick={showIncidents}
        className={
          props.mode === "incident" ? styles.buttonSelected : styles.button
        }
      >
        Incident Log
      </Button>
      <div className={styles.spacer} />
      <Button
        onClick={showEdit}
        className={
          props.mode === "edit" ? styles.buttonSelected : styles.button
        }
      >
        Configuration
      </Button>
    </div>
  );
}
