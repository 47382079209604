import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useContext,
} from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { Status } from "./StatusIndicator";
import { ExpandMode, Monitor } from "./Monitor";
import { api } from "../../api";
import { useAsync } from "../../api/Async";
import { CircularProgress, Button, Typography } from "@mui/material";
import { newMonitorEventName, newMonitorEvents } from "../AddMonitor";
import { MonitorLiveUpdate, useLiveMonitorStats } from "./LiveMonitorStats";
import { CrushAnimationContext } from "../CrushAnimation";
import { MongoDbParams, RedisParams, SqlParams } from "../../api/Types";
import { CompanyStatusContext } from "../../core/auth";
import { Card } from "../Card";
import { EventEmitter } from "../EventEmitter";
import red from "@mui/material/colors/red";
import Grid from "@mui/material/Grid";
import {Link, useHistory} from "react-router-dom";
import { AddMonitor } from "../header/AddMonitor";
import { PageTitle } from "../PageTitle";
import { AddStatusPage } from "./AddStatusPage";
import { Note } from "../Note";
import {FreeTrialModalContext} from "../header/freeTrial/FreeTrialModal";
import {ClickableLink} from "./CollapsedBodyHint";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      textAlign: "center",
    },
    outerPadding: {
      padding: theme.spacing(2),
    },
    grey: {
      color: "hsla(0,0%,0%,.64)",
    },
    typeIcon: {
      fontSize: 28,
    },
    spacer: {
      height: theme.spacing(1),
    },
    recordingRed: {
      backgroundColor: red["300"],
      width: 10,
      height: 10,
      borderRadius: "50%",
    },
  })
);

export type StatusLevel = "ok" | "warn" | "error" | "none";

interface MonitorDetailBase {
  id: number;
  name: string;
  responseTimeMs: number;
  recentSummary: Status[] | null;
  dnsChanged: boolean;
  dnsLoadBalanced: boolean;
  lastCheckedAt: string;
  checkIntervalInSeconds: number;
  kind: "http" | "db" | "grpc" | "ping";
  responseTimeRankingLevel: StatusLevel;
  responseTimeRanking: string;
  uptimeRankingLevel: StatusLevel;
  uptimeRanking: string;
  hasActiveIncident: boolean;
  method: string;
  url: string;
  paused: boolean;
  headers: {
    name: string;
    value: string;
    sensitive: boolean;
  }[];
  maxResponseTimeMs: number;
  checkIntervalMs: number;
  requestedCheckIntervalMs: number;
  loading?: boolean;
  grpcParams?: GRPCParams;
}

export type GRPCParams = {
  tlsEnabled: boolean;
  tlsInsecure: boolean;
  serviceName: string;

  oAuth?: OAuth;
}

export type OAuth = {
  tokenUrl: string
  clientId: string
  clientSecret: string
  audience: string
}

export type MonitorDetail = MonitorDetailBase &
  (
    | { dbType: "postgres" | "mysql"; dbParams: SqlParams; dbLabel: string }
    | { dbType: "mongo"; dbParams: MongoDbParams; dbLabel: string }
    | { dbType: "redis"; dbParams: RedisParams; dbLabel: string }
    | { dbType: "" }
  );

interface ExpandedDetail {
  id: number;
  mode: ExpandMode;
}

export const monitorListLoaded = new EventEmitter<MonitorDetail[]>();

export function MonitorList() {
  const styles = useStyles();
  const [monitors, setMonitors] = useState<MonitorDetail[]>([]);
  const { loading, reload, error } = useAsync(async () => {
    const list = await api.listMonitors();
    setMonitors(list);
  });

  useEffect(() => {
    // reload entire list every 10min
    const timer = setTimeout(() => reload(), 10 * 60 * 1000);
    return () => clearTimeout(timer);
  }, [reload]);

  const expandIdByDefault =
    monitors.length < 4 && monitors.length > 0 ? monitors[0].id : 0;
  const defaultState = useMemo(
    () => ({
      id: expandIdByDefault,
      mode: "dns" as ExpandMode,
    }),
    [expandIdByDefault]
  );

  const [expanded, setExpanded] = useState<ExpandedDetail | null>(defaultState);

  const toggleExpand = useCallback(
    (id: number, mode: ExpandMode) => {
      if (expanded !== null && expanded.id === id && expanded.mode === mode) {
        setExpanded(null);
        return;
      }

      setExpanded({
        id: id,
        mode: mode,
      });
    },
    [expanded]
  );

  const animation = useContext(CrushAnimationContext);
  const status = useContext(CompanyStatusContext);

  const onAdd = useCallback(
    (item) => {
      setMonitors([item, ...monitors]);
      toggleExpand(item.id, "view");
      animation.trigger();
      setTimeout(() => reload(), 10 * 1000);
      status.reload();
    },
    [monitors, toggleExpand, reload, animation, status]
  );

  const onRemove = useCallback(
    (id: number) => {
      setMonitors((old) => old.filter((m) => m.id !== id));
      status.reload();
    },
    [status]
  );

  useEffect(() => {
    const listener = (m: MonitorDetail) => onAdd(m);
    newMonitorEvents.addListener(newMonitorEventName, listener);
    return () => {
      newMonitorEvents.removeListener(newMonitorEventName, listener);
    };
  }, [onAdd]);

  const onUpdate = useCallback((input) => {
    setMonitors((old) =>
      old.map((m) => {
        if (m.id === input.id) return input;
        return m;
      })
    );
  }, []);

  const updateLive = useCallback((updates: MonitorLiveUpdate[]) => {
    setMonitors((monitors) =>
      monitors.map((m) => {
        const matches = updates.filter((u) => u.endpoint === m.id);
        if (matches.length === 0) return m;
        const update = matches[0];

        if (update.checkStarted) {
          return Object.assign({}, m, {
            loading: update.checkStarted,
          });
        } else {
          let recentSummary: Status[] = [];
          if (m.recentSummary && m.recentSummary.length > 0) {
            recentSummary.push(...m.recentSummary);
            const last = Object.assign({}, recentSummary[0]);
            recentSummary[0] = last;
            if (update.failure) last.failure++;
            if (update.slow) last.slow++;
            if (update.success) last.success++;
            computeStatus(last);
          }

          return Object.assign({}, m, {
            dnsChanged: update.dnsChanged,
            hasActiveIncident: update.failure || update.slow,
            recentSummary: recentSummary,
            responseTimeMs: update.responseTimeMs,
            lastCheckedAt: update.checkedAt,
            loading: false,
          });
        }
      })
    );
  }, []);

  const history = useHistory();
  useLiveMonitorStats(updateLive);

  useEffect(() => {
    monitorListLoaded.emit(monitors);
  }, [monitors, history]);

  const trialContext = useContext(FreeTrialModalContext);

  if (loading && monitors.length === 0) {
    return (
      <div className={styles.loading}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.loading}>
        <Typography color="error">{error}</Typography>
        <Button onClick={reload}>Retry</Button>
      </div>
    );
  }

  return (
    <div>
      <PageTitle
        title="Monitors"
        subtitle="Debug tools ready to go and monitor history"
        rightAction={<AddMonitor />}
      />

      {(monitors.length > 0 && status.status && status.status.frontendPlanName === "free") ? <Note
          variant="warn"
          text="There's a big delay between checks. Ugrade your account to get 30s check intervals"
          action={<ClickableLink onClick={trialContext.show}>
            <Typography variant="body2" color="textSecondary">More Details</Typography>
          </ClickableLink>}
      /> : null}
      {status.status && !status.status.onboarding.setupStatusPage ? <AddStatusPage /> : null}
      <Card slimBottom={monitors.length > 0}>
        {monitors.map((m, index) => (
          <React.Fragment key={m.id}>
            <Monitor
              {...m}
              onExpand={toggleExpand}
              expandMode={m.id === expanded?.id ? expanded.mode : "none"}
              onRemove={onRemove}
              onUpdate={onUpdate}
            />
          </React.Fragment>
        ))}
        {monitors.length === 0 && (
          <Typography
            style={{ textAlign: "center" }}
            variant="body1"
            color="textSecondary"
          >
            No monitors yet
          </Typography>
        )}
      </Card>
      <Spacer />
      {monitors.length > 0 && (
        <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
          <Grid item>
            <div className={styles.recordingRed}></div>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              Live status updates are enabled
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

function computeStatus(status: Status) {
  if (status.failure > 3 || (status.success < 1000 && status.failure > 0)) {
    status.value = "error";
  } else if (status.slow > 3 || (status.success < 1000 && status.slow > 0)) {
    status.value = "warn";
  } else if (status.success > 0) {
    status.value = "ok";
  } else {
    status.value = "none";
  }
}

export function Spacer() {
  const styles = useStyles();
  return <div className={styles.spacer} />;
}

export function useMonitorCount() {
  const [monitorCount, setMonitorCount] = useState(0);

  useEffect(() => {
    const sub = monitorListLoaded.subscribeAndFireLast((list) => {
      if (list.length !== monitorCount) {
        setMonitorCount(list.length);
      }
    });

    return () => sub.cancel();
  }, [monitorCount]);

  return monitorCount;
}

export function useActiveMonitorCount() {
  const [monitorCount, setMonitorCount] = useState(0);

  useEffect(() => {
    const sub = monitorListLoaded.subscribeAndFireLast((list) => {
      const len = list.filter((m) => !m.paused).length;
      if (len !== monitorCount) {
        setMonitorCount(len);
      }
    });

    return () => sub.cancel();
  }, [monitorCount]);

  return monitorCount;
}
