import {Button, Grid, TextField} from "@mui/material";
import React from "react";
import {OAuth} from "../../monitorList";

export function OAuthConfig(props: {
    value: OAuth | null;
    onChange(value: OAuth|null): void
}) {

    const update = (k: keyof OAuth, value: string) => props.onChange(Object.assign({}, props.value || {}, {
        [k]: value,
    }) as any);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField label="OAuth Token URL"
                           value={props.value?.tokenUrl || ""}
                           placeholder="https://myapp.auth0.com/oauth/token"
                           onChange={e => update("tokenUrl", e.target.value)}
                           InputLabelProps={{shrink: true}} fullWidth />
            </Grid><Grid item xs={12}>
                <TextField label="Client ID"
                           value={props.value?.clientId || ""}
                           placeholder="xxxxxxxxxx"
                           onChange={e => update("clientId", e.target.value)}
                           InputLabelProps={{shrink: true}} fullWidth />
            </Grid><Grid item xs={12}>
                <TextField label="Client Secret"
                           value={props.value?.clientSecret || ""}
                           placeholder="xxxxxxxxxxxx"
                           onChange={e => update("clientSecret", e.target.value)}
                           InputLabelProps={{shrink: true}} fullWidth
                           type="password"
                />
            </Grid><Grid item xs={12}>
                <TextField label="Audience (optional)"
                           value={props.value?.audience || ""}
                           placeholder="https://myapp.auth0.com/api/v2/"
                           onChange={e => update("audience", e.target.value)}
                           InputLabelProps={{shrink: true}} fullWidth />
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Button size="small" color="secondary" variant="outlined" onClick={() => props.onChange(null)}>Remove OAuth</Button>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    );
}