import {
  AddSourceProps,
  BulkAddMonitorsRow,
  CheckResult,
  CompanyStatus,
  DNSLog,
  EditSourceProps,
  Incident,
  NotificationBehaviour,
  Person,
  SlackStatus,
  StatusPageAdminDetails,
  StatusPageMessage,
  StatusPagePublicDetails,
  StatusPageSource,
} from "./Types";
import { MonitorDetail } from "../components/monitorList";
import {APIBase, notAuthorizedResponse} from "nate-react-api-helpers";
import moment from "moment";

export function fixPath(path: string): string {
  return path;

  if(path.startsWith("https:")) return path;
  return "https://app.statuslist.app" + path
}

class API extends APIBase {
  get fetch() {
    return this.fetcher;
  }

  constructor() {
    super({
      jwtRefreshEndpoint: fixPath("/api/auth/refresh"),
    });

    this.fetcher.defaultHeaders.set(
      "X-TimezoneOffsetMins",
      moment().utcOffset().toString()
    );
  }

  ping() {
    return this.fetch.get<CompanyStatus>(fixPath("/api/ping"));
  }

  addSource(params: AddSourceProps) {
    return this.fetch.post<MonitorDetail>(fixPath("/api/source"), params);
  }

  bulkAddMonitors(rows: BulkAddMonitorsRow[]) {
    return this.fetch.post<{}>(fixPath("/api/source/bulk-add"), rows);
  }

  editSource(params: EditSourceProps) {
    return this.fetch.put<MonitorDetail>(fixPath("/api/source"), params);
  }

  diagnosticCheck(input: { endpoint: number }) {
    return this.fetch.get<CheckResult>(
      fixPath("/api/monitor/run-diagnostic-check"),
      input
    );
  }

  listMonitors() {
    return this.fetch.get<MonitorDetail[]>(fixPath("/api/monitors"));
  }

  getDnsHistory(params: { endpoint: number }) {
    return this.fetch.get<DNSLog[]>(fixPath("/api/monitor/dns"), params);
  }

  archiveSource(id: number) {
    return this.fetch.post<{}>(fixPath("/api/source/remove"), id);
  }

  logout() {
    window.location.replace(fixPath("/api/auth/logout"));
  }

  cancelSubscription(input: { reasons: string[]; comments: string }) {
    return this.fetch.post<{}>(fixPath("/api/subscription/cancel"), input);
  }

  createSubscription(input: { stripeToken: string; coupon: string; }) {
    return this.fetch.post<{}>(fixPath("/api/subscription"), input);
  }

  login(input: { username: string; password: string }) {
    return this.fetch.post<{}>(fixPath("/api/auth/login"), input);
  }

  register(input: { email: string; password: string }) {
    return this.fetch.post<{}>(fixPath("/api/auth/register"), input);
  }

  getRecentIncidents(props: { endpoint: number }) {
    return this.fetch.get<Incident[]>(fixPath("/api/incidents/recent"), props);
  }

  downloadTranscriptForIncident(id: number) {
    this.fetch.download("GET", fixPath("/api/incidents/download-transcript"), {
      id: id,
    });
  }

  getTranscriptForIncident(id: number) {
    return this.fetch.get<string>(fixPath("/api/incidents/get-transcript"), {
      id: id,
    });
  }

  getSlackStatus() {
    return this.fetch.get<SlackStatus>(fixPath("/api/slack/status"));
  }

  setSlackChannel(channel: string) {
    return this.fetch.post<any>(
      fixPath("/api/slack/choose-channel?channel=") + channel,
      {}
    );
  }

  removeSlack() {
    return this.fetch.post<any>(fixPath("/api/slack/remove"), {});
  }

  answerOnboardingSurvey(input: { question: string; answers: string[] }) {
    return this.fetch.post<{}>(fixPath("/api/onboarding/answer-survey"), input);
  }

  statusPageAdminDetails() {
    return this.fetch.get<StatusPageAdminDetails[]>(fixPath("/api/status-pages"));
  }

  statusPagePublicDetails(id?: string) {
    return this.fetch.get<StatusPagePublicDetails>(fixPath("/api/status-page/public"), {
      id: id,
    });
  }

  updateStatusPageDetails(body: StatusPageAdminDetails) {
    return this.fetch.post<StatusPageAdminDetails>(fixPath("/api/status-page/company-details"), body);
  }

  updateStatusPageSources(body: {
    page: number;
    sources: StatusPageSource[];
  }) {
    return this.fetch.post<{}>(fixPath("/api/status-page/sources"), body);
  }

  postStatusPageMessage(body: StatusPageMessage) {
    return this.fetch.post<{}>(fixPath("/api/status-page/message"), body);
  }

  removeStatusPageMessage(body: { id: number }) {
    return this.fetch.post<{}>(fixPath("/api/status-page/message/remove"), body);
  }

  listPeople() {
    return this.fetch.get<Person[]>(fixPath("/api/people"));
  }

  addPerson(input: { firstName: string; lastName: string; email: string, phone: string }) {
    return this.fetch.post<Person[]>(fixPath("/api/person/create"), input);
  }

  updatePerson(input: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    archived: boolean;
  }) {
    return this.fetch.post<Person[]>(fixPath("/api/person/update"), input);
  }

  sendSetupEmail(input: { id: number }) {
    return this.fetch.post<void>(fixPath("/api/person/send-welcome-email"), input);
  }

  setPersonPassword(input: { id: number; password: string }) {
    return this.fetch.post<void>(fixPath("/api/person/set-password"), input);
  }

  resetPassword(input: { code: string; password: string }) {
    return this.fetch.post<void>(fixPath("/api/person/reset-password"), input);
  }

  getNotificationSettings() {
    return this.fetch.get<{ downBehaviour: NotificationBehaviour }>(
      fixPath("/api/notification-settings")
    );
  }

  updateNotificationSettings(input: { downBehaviour: NotificationBehaviour }) {
    return this.fetch.post<{}>(fixPath("/api/notification-settings"), input);
  }

  setPaused(params: { id: number; paused: boolean }) {
    return this.fetch.put<MonitorDetail>(fixPath("/api/source/set-paused"), params);
  }

  sendSupportMessage(input: {
    message: string
  }) {
    return this.fetch.post<{}>(fixPath("/api/support/message"), input);
  }

  startPasswordReset(input: {
    email: string
  }) {
    return this.fetch.post<{}>(fixPath("/api/person/reset-password/start"), input);
  }

  resetPassword2(input: {
    newPassword: string;
    token: string;
  }) {
    return this.fetch.post<{}>(fixPath("/api/person/reset-password/do"), input);
  }

  getStripeManageLink() {
    return this.fetch.get<{ url: string }>(fixPath("/api/subscription/stripe-manage"));
  }
}

export const api = new API();
