import React, { useCallback, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { api } from "../../api";
import { useAsync, useAsyncAction } from "../../api/Async";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { StatusPageAdminDetails, StatusPageSource } from "../../api/Types";

interface StatusSource {
  id: number;
  endpointId: number | null;
  publicName: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sourceRowHeight: {
      height: 73,
    },
    title: {
      marginBottom: theme.spacing(1),
    },
  })
);

export function StatusSources(props: {
  details: StatusPageAdminDetails;
  onReload(): void;
}) {
  const [sources, setSources] = useState<StatusSource[]>([]);
  const styles = useStyles();

  const listMonitors = useAsync(async () => await api.listMonitors());

  const newSource = useCallback(() => {
    setSources((srcs) => [
      ...srcs,
      { id: 0, endpointId: null, publicName: "" },
    ]);
  }, []);

  useEffect(() => {
    setSources(props.details.sources);
    if (props.details.sources.length === 0) {
      newSource();
    }
  }, [props, newSource]);

  const changeValue = useCallback(
    (s: StatusSource, value: Partial<StatusSource>) => {
      setSources((old) =>
        old.map((o) => (o === s ? Object.assign({}, s, value) : o))
      );
    },
    []
  );

  const save = useAsyncAction(
    async (src: StatusPageSource[]) => {
      await api.updateStatusPageSources({
          page: props.details.id,
          sources: src
      });

      props.onReload();
    },
    [props]
  );

  const usedEndpointIds = sources.map((s) => s.endpointId);

  return (
    <Grid container direction="column">
      {listMonitors.LoadingElement && (
        <Grid item>{listMonitors.LoadingElement}</Grid>
      )}
      {sources.map((s, index) => (
        <Grid item key={index}>
          <Grid container spacing={1} className={styles.sourceRowHeight}>
            <Grid item xs={6}>
              <FormControl fullWidth variant="filled">
                <InputLabel shrink>Monitor</InputLabel>
                <Select
                  variant="filled"
                  fullWidth
                  value={s.endpointId === null ? "" : s.endpointId}
                  onChange={(e) => {
                    changeValue(s, {
                      endpointId: parseInt(e.target.value as string),
                    });
                  }}
                >
                  <MenuItem key="none" value="">
                    <em>None</em>
                  </MenuItem>
                  {listMonitors.result &&
                    listMonitors.result
                      .filter(
                        (m) =>
                          usedEndpointIds.indexOf(m.id) === -1 ||
                          m.id === s.endpointId
                      )
                      .map((r) => (
                        <MenuItem key={r.id} value={r.id}>
                          {r.kind === "http" ? r.url : r.kind === "grpc" ? r.name + " (" + r.grpcParams?.serviceName + ")" : r.name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs>
              <TextField
                label="Public Name"
                variant="filled"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={s.publicName}
                onChange={(e) => {
                  changeValue(s, {
                    publicName: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item>
              <IconButton
                onClick={() =>
                  setSources((srcs) => srcs.filter((src) => src !== s))
                }
                size="large">
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item key="actions">
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button color="primary" onClick={newSource}>
              Add
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                save.callback(
                  sources
                    .filter((s) => s.endpointId !== null)
                    .map((s) => ({
                      id: s.id,
                      endpointId: (s.endpointId || 0) as number,
                      publicName: s.publicName,
                      statusPageId: props.details.id,
                    }))
                );
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
