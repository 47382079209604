import React, { useState } from "react";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import { SetPassword } from "./SetPassword";

export function TeamMemberSubMenu(props: {
  id: number;
  sendSetupEmail(): void;
  remove(): void;
  canRemove: boolean;
  hasEmail: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>();
  return (
    <React.Fragment>
      <IconButton
        ref={setAnchorEl}
        size="small"
        color="inherit"
        onClick={() => setOpen(true)}
      >
        <MoreVertIcon color="inherit" />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        open={open}
        onClose={() => setOpen(false)}
      >
          {props.hasEmail && <MenuItem
          onClick={() => {
            setOpen(false);
            props.sendSetupEmail();
          }}
        >
          Resend Setup Email
        </MenuItem>}
          {props.hasEmail && <SetPassword id={props.id} onCloseParentMenu={() => setOpen(false)} />}
        <MenuItem
          disabled={!props.canRemove}
          onClick={() => {
            setOpen(false);
            props.remove();
          }}
        >
          Remove
        </MenuItem>
      </Popover>
    </React.Fragment>
  );
}
