import React from "react";
import { Theme } from "@mui/material";
import {makeStyles, createStyles} from '@mui/styles';
import Grid from "@mui/material/Grid";
import { Title } from "./Title";
import { CheckResult } from "../../../api/Types";
import { TranscriptDisplay } from "../TranscriptDisplay";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      width: "100%",
    },
  })
);

export function MonitorHttpResponse(props: CheckResult) {
  const styles = useStyle();

  return (
    <Grid container direction="column">
      <Grid item className={styles.gridItem}>
        <Title>Response</Title>
      </Grid>
      <Grid item className={styles.gridItem}>
        <TranscriptDisplay content={props.transcript} />
      </Grid>
    </Grid>
  );
}
