import React, { useState } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import grey from "@mui/material/colors/grey";
import { formatTimestamp, MessageText } from "../statusPage/Messages";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { StatusPageAdminDetails, StatusPageMessage } from "../../api/Types";
import { useAsyncAction } from "../../api/Async";
import { api } from "../../api";
import { useTimeBasedRefresher } from "../TimeRefresher";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderBottom: "1px solid " + grey["200"],
    },
  })
);

export function Messages(props: {
  details: StatusPageAdminDetails;
  onReload(): void;
}) {
  const styles = useStyles();
  const [compose, setCompose] = useState("");
  const messages = props.details.messages || [];
  useTimeBasedRefresher();

  const postMessage = useAsyncAction(
    async (message: StatusPageMessage) => {
      await api.postStatusPageMessage(message);
      setCompose("");
      props.onReload();
    },
    [props]
  );

  const deleteMessage = useAsyncAction(
    async (id: number) => {
      await api.removeStatusPageMessage({ id: id });
      props.onReload();
    },
    [props]
  );

  const setDeleteAfter = useAsyncAction(
    async (value: string) => {
      await api.updateStatusPageDetails(
        Object.assign({}, props.details, {
          deleteMessagesAfter: value,
        })
      );
      props.onReload();
    },
    [props]
  );

  if (!props.details.isPublic) {
    return (
      <Typography variant="body2" color="textSecondary">
        Will be available after your Status Page is public
      </Typography>
    );
  }

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item xs>
            {messages.length === 0 && (
              <div key="new" className={styles.row}>
                <Grid
                  container
                  spacing={1}
                  wrap="nowrap"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <Typography variant="body1" color="textSecondary">
                      This is where public message go. Write a message below and
                      it will show on the public status page. Messages will be
                      automatically deleted after{" "}
                      {props.details.deleteMessagesAfter}. You can also remove
                      messages manually.
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            )}
            {messages.map((m) => (
              <div key={m.id} className={styles.row}>
                <Grid
                  container
                  spacing={1}
                  wrap="nowrap"
                  alignItems="flex-start"
                >
                  <Grid item xs>
                    <MessageText text={m.content} />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {formatTimestamp(m.timestamp)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => deleteMessage.callback(m.id)} size="large">
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            ))}
          </Grid>
          <Grid item>
            <FormControl style={{ width: 200 }}>
              <InputLabel shrink>Auto delete messages after</InputLabel>
              <Select
                fullWidth
                value={props.details.deleteMessagesAfter}
                onChange={(e) => {
                  setDeleteAfter.callback(e.target.value as string);
                }}
              >
                <MenuItem value="1 day">1 Days</MenuItem>
                <MenuItem value="2 days">2 Days</MenuItem>
                <MenuItem value="7 days">7 Days</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <TextField
              label="Compose"
              variant="filled"
              multiline
              rows={2}
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={compose}
              onChange={(e) => setCompose(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={postMessage.loading}
              variant="text"
              color="primary"
              onClick={() => {
                postMessage.callback({
                  content: compose,
                  id: 0,
                  statusPageId: props.details.id,
                  timestamp: (new Date() as any) as string,
                });
              }}
            >
              Post
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
